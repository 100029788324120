import {
  Button,
  Icon,
  Stack,
  Toolbar,
  Typography,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import NavigationBar from "../../components/navigation/navigationBar";
import SideBar from "../../components/navigation/sideBar";
import { CENTER, Colors } from "../../utils/colors";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { ImagePicker } from "../../components/utils/imagePicker";
import { HTTPGetArticleCategories } from "../../apis/articleCategories";
import { HTTPAddArticle, HTTPEditArticle } from "../../apis/article";

const AboutArticleFormScreen = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const ArticleRedux = useSelector(
    (state: RootState) => state.article_temp_data.data
  );
  const token = secureLocalStorage.getItem("token") as string;

  const [init, setInit] = React.useState(false);
  const [Title, setTitle] = React.useState("");
  const [Content, setContent] = React.useState("");
  const [ImageResult, setImageResult] = React.useState("");
  const [Category, setCategory] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [CategoryData, setCategoryData] = React.useState([]);
  //   const imageHandler = () => {
  //     // Create an input element of type 'file'
  //     const input = document.createElement("input");
  //     input.setAttribute("type", "file");
  //     input.setAttribute("accept", "image/*");
  //     input.click();

  //     // When a file is selected
  //     input.onchange = () => {
  //       const file = input.files[0];
  //       const reader = new FileReader();

  //       // Read the selected file as a data URL
  //       reader.onload = () => {
  //         const imageUrl = reader.result;
  //         const quillEditor = quill.current.getEditor();

  //         // Get the current selection range and insert the image at that index
  //         const range = quillEditor.getSelection(true);
  //         quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
  //       };

  //       reader.readAsDataURL(file);
  //     };
  //   };

  const GetCategories = async () => {
    try {
      const result = await HTTPGetArticleCategories({ token, page: 1 });
      setCategoryData(result.data.data);
    } catch (error) {}
  };

  const ActionCustomer = async () => {
    setLoading(true);
    try {
      if (type === "edit") {
        await HTTPEditArticle({
          article_id: ArticleRedux.id,
          category_id: Category,
          content_en: Content,
          content_id: Content,
          thumbnail: ImageResult,
          title_en: Title,
          title_id: Title,
          token,
        });
        setLoading(false);
        toast.success("Berhasil mengubah Artikel");
        navigate(-1);
      } else {
        await HTTPAddArticle({
          category_id: Category,
          content_en: Content,
          content_id: Content,
          thumbnail: ImageResult,
          title_en: Title,
          title_id: Title,
          token,
        });
        setLoading(false);
        toast.success("Berhasil menambahkan Artikel");
        navigate(-1);
      }
    } catch (error) {
      toast.error("Terjadi Kesalahan menyimpan Data");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    async function Initial() {
      await GetCategories();
      if (type === "edit") {
        setTitle(ArticleRedux.title_id);
        setImageResult(ArticleRedux.thumbnail);
        setContent(ArticleRedux.content_id);
        setCategory(ArticleRedux.category.id);
      }
    }

    Initial().then().catch();
  }, [init]);

  const renderCategory = (values: any) => {
    if (values.length === 0) {
      return <span style={{ color: "#a7a5a6" }}>Pilih Kategori</span>;
    } else {
      const result: any = CategoryData.filter(
        (value: any) => value.id === Category
      );
      return <span style={{ color: "#000" }}>{result[0].name_id}</span>;
    }
  };

  return (
    <div style={{ width: "100%", backgroundColor: "#f2f5f7" }}>
      <NavigationBar />
      <div style={{ width: "100%", ...CENTER }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{
            width: "95%",
            position: "absolute",
            top: isMobile ? "70px" : "120px",
          }}
        >
          {isMobile ? null : (
            <div style={{ width: "20%" }}>
              <SideBar nodeId="50" />
            </div>
          )}
          <Stack
            direction={"column"}
            sx={{ width: isMobile ? "100%" : "78%", paddingTop: 2 }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              marginBottom={isMobile ? 3 : 0}
              paddingLeft={2}
            >
              <h2 style={{ margin: 0, color: "#fff", fontWeight: "500" }}>
                Tambah Artikel
              </h2>
              {isMobile ? null : (
                <h4
                  style={{ margin: 0, color: "#ffffff80", fontWeight: "400" }}
                >
                  Tentang Angelo / Artikel
                </h4>
              )}
            </Stack>
            {isMobile ? null : <Toolbar></Toolbar>}
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                padding: 20,
                backgroundColor: "#fff",
              }}
            >
              <Stack
                direction={"column"}
                gap={4}
                alignItems={"flex-start"}
                width={"100%"}
              >
                <Stack
                  direction={isMobile ? "column" : "row"}
                  gap={2}
                  alignItems={isMobile ? "flex-start" : "center"}
                >
                  <Button
                    variant={"outlined"}
                    sx={{ borderColor: Colors.secondary }}
                    color={"secondary"}
                    onClick={() => navigate(-1)}
                    startIcon={
                      <Icon
                        fontSize={"small"}
                        sx={{ color: Colors.secondaryDark }}
                      >
                        arrow_back
                      </Icon>
                    }
                  >
                    <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                  </Button>
                  <Typography
                    variant="body1"
                    fontWeight={"600"}
                    color={Colors.secondaryDark}
                  >
                    {type === "add"
                      ? "FORM TAMBAH ARTIKEL"
                      : "FORM UBAH ARTIKEL"}
                  </Typography>
                </Stack>
                <Typography variant="body1" fontWeight={"600"} color={"#000"}>
                  Pengaturan Artikel
                </Typography>
                <Stack direction={"column"} gap={2} width={"100%"}>
                  <Typography variant="body1" color={Colors.secondaryDark}>
                    Thumbnail Artikel:
                  </Typography>
                  <div style={{ width: "70%" }}>
                    <ImagePicker
                      onResult={(value: any) => setImageResult(value)}
                      initValue={ImageResult}
                    />
                  </div>
                </Stack>
                <Stack direction={"column"} gap={2} width={"100%"}>
                  <Typography variant="body1" color={Colors.secondaryDark}>
                    Kategori Artikel:
                  </Typography>
                  <Select
                    displayEmpty
                    size="small"
                    MenuProps={{
                      style: { maxHeight: 500 },
                    }}
                    sx={{ bgcolor: "white", width: "40%" }}
                    inputProps={{ sx: { borderRadius: 1 } }}
                    value={Category}
                    onChange={(e) => setCategory(e.target.value)}
                    renderValue={renderCategory}
                  >
                    {CategoryData.map((item: any, index: number) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name_id}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack direction={"column"} gap={2} width={"100%"}>
                  <Typography variant="body1" color={Colors.secondaryDark}>
                    Judul Artikel:
                  </Typography>
                  <TextField
                    type="text"
                    placeholder="Judul Artikel"
                    size="small"
                    sx={{ bgcolor: "white", width: "100%" }}
                    InputProps={{
                      sx: { borderRadius: 1 },
                      endAdornment: (
                        <Typography
                          variant={"body2"}
                          color={Colors.secondaryDark}
                        >
                          {Title.length}/255
                        </Typography>
                      ),
                    }}
                    inputProps={{ maxLength: 255 }}
                    value={Title}
                    onChange={(text) => setTitle(text.target.value)}
                  />
                </Stack>
                <Stack direction={"column"} gap={2} width={"100%"}>
                  <Typography variant="body1" color={Colors.secondaryDark}>
                    Konten Artikel:
                  </Typography>
                  <ReactQuill
                    value={Content}
                    theme={"snow"}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "clean", "image", "video"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "video",
                      "image",
                    ]}
                    onChange={(value) => setContent(value)}
                    style={{ height: isMobile ? undefined : "18em" }}
                  />
                </Stack>
                <br />
                <div style={{ width: "100%", ...CENTER }}>
                  <Button
                    onClick={ActionCustomer}
                    color={"secondary"}
                    variant={"contained"}
                    disabled={isLoading}
                    sx={{
                      backgroundColor: Colors.primary,
                      width: isMobile ? "70%" : "30%",
                    }}
                    startIcon={
                      isLoading ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        <Icon fontSize={"small"}>save</Icon>
                      )
                    }
                  >
                    Simpan
                  </Button>
                </div>
              </Stack>
            </div>
            <Toolbar />
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default AboutArticleFormScreen;
