import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { HTTPUploadFile } from '../../apis/utils';
import { HTTPAddCategory, HTTPDeleteCategory, HTTPEditCategory } from '../../apis/categories';
import { ImagePicker } from '../utils/imagePicker';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CategoryForm = (param: { isOpen: boolean, onClose: any, type: string, getData: any, item: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const [ImageResult, setImageResult] = React.useState('')
    const [CategoryName, setCategoryName] = React.useState('')

    const onClose = () => {
        setImageResult('')
        setCategoryName('')
        param.onClose()
    }

    const ActionCategory = async () => {
        setLoading(true)
        try {
            if (param.type === 'add') {
                await HTTPAddCategory({
                    image: ImageResult,
                    name: CategoryName
                })
                toast.success('Berhasil Menambah Kategori!')
            } else {
                await HTTPEditCategory({
                    category_id: param.item.id,
                    image: ImageResult,
                    name: CategoryName
                })
                toast.success('Berhasil Mengubah Kategori!')
            }
            setLoading(false)
            onClose()
            param.getData()
        } catch (error) {
            toast.error('Terjadi Kesalahan')
            setLoading(false)
             
        }
    }

    React.useEffect(() => {
        if (param.isOpen === true && param.type === 'edit') {
            setCategoryName(param.item.name)
            setImageResult(param.item.image)
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>{param.type === 'add' ? 'Tambah Kategori' : 'Edit Kategori'}</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} gap={3} marginY={5}>
                    <Typography variant="body1" color={Colors.secondary}>Gambar Kategori:</Typography>
                    <div style={{ width: '70%' }}>
                        <ImagePicker onResult={(img: any) => setImageResult(img)} initValue={ImageResult} />
                    </div>
                </Stack>
                <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} gap={3} marginBottom={5}>
                    <Typography variant="body1" color={Colors.secondary}>Nama Kategori:</Typography>
                    <TextField
                        type="text"
                        placeholder="Nama Kategori"
                        size="small"
                        sx={{ bgcolor: "white", width: '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={CategoryName}
                        onChange={(text) => setCategoryName(text.target.value)}
                        onKeyDown={(e) => { if (e.keyCode === 13) ActionCategory() }}
                    />
                </Stack>
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        color={"secondary"}
                        variant={'contained'}
                        sx={{ backgroundColor: Colors.primary, width: '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                        onClick={ActionCategory}
                    >Simpan</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const CategoryDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const DeleteCategory = async () => {
        setLoading(true)
        try {
            await HTTPDeleteCategory({ category_id: param.item.id })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2">Anda yakin untuk menghapus data ini?</Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            sx={{ backgroundColor: Colors.primary }}
                            onClick={DeleteCategory}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    'Yakin'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}