import { AxiosNormal } from "../utils/interceptors";
const url = "customer";

export function HTTPGetCustomers(param: {
  token: string;
  page: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/get?page=${param.page}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCustomerByID(param: {
  token: string;
  customer_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/show/${param.customer_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddCustomer(param: {
  token: string;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
        name: param.name,
        email: param.email.toLowerCase(),
        password: param.password,
        password_confirmation: param.password_confirmation,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateCustomer(param: {
  token: string;
  customer_id: string;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/${param.customer_id}`, {
        token: param.token,
        name: param.name,
        email: param.email,
        password: param.password,
        password_confirmation: param.password_confirmation,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPBanCustomer(param: {
  token: string;
  customer_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/ban/${param.customer_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUnBanCustomer(param: {
  token: string;
  customer_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/unban/${param.customer_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteCustomer(param: {
  token: string;
  customer_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/delete/${param.customer_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
