import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const VoucherTempSlice = createSlice({
  name: "Voucher_Temp_Data",
  initialState: {
    data: {
      code: "",
      description: "",
      discount_type: "",
      discount_value: 0,
      end_period: "",
      id: "",
      is_active: "",
      is_highlighted: "",
      max_discount: 0,
      max_usage: 0,
      min_transaction: 0,
      name: "",
      remaining_quota: 0,
      start_period: "",
      total_quota: 0,
      type: "",
      used: 0,
    },
  },
  reducers: {
    setVoucherTempData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setVoucherTempData } = VoucherTempSlice.actions;
export const VoucherTempData = VoucherTempSlice.reducer;
