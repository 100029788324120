import * as React from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { DeleteContent } from '../utils/deleteContent';
import { toast } from 'react-toastify';
import { HTTPDeleteVoucher } from '../../apis/voucher';
import secureLocalStorage from 'react-secure-storage';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const VoucherDelete = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteVoucher = async () => {
        setLoading(true)
        try {
            await HTTPDeleteVoucher({ token, voucher_id: param.item.id })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <DeleteContent
                    isLoading={isLoading}
                    onCancel={param.onClose}
                    onConfirm={DeleteVoucher}
                    text={'voucher ' + param.item.name}
                />
            </DialogContent>
        </Dialog>
    )
}