import { Button, Icon, Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField, Select, MenuItem, Tabs, Tab, Typography, Switch, Modal, Dialog, DialogContent, Tooltip } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors, Truncate } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { HTTPAddBestSellers, HTTPGetProducts } from '../../apis/products';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { ProductDelete, ProductVariantModal } from '../../components/products/productModal';
import { toast } from 'react-toastify';
import { Images } from '../../assets/images';
import { HTTPSyncShopeeProducts } from '../../apis/utils';
import { ThreeDots } from 'react-loader-spinner';
import { ShopeeSync } from '../../components/products/syncModal';

const ProductMeScreen = () => {
    const navigate = useNavigate()
    const [init, setInit] = React.useState(false)
    const [menuActive, setMenuActive] = React.useState(0)
    const [ProductData, setProductData] = React.useState([])
    const [isLoading, setLoading] = React.useState(false)
    const [VariantDetail, setVariantDetail] = React.useState(false)
    const [SelectedItem, setSelectedItem] = React.useState([])
    const [SelectedProduct, setSelectedProduct] = React.useState<any>({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)
    const [isSyncShopee, setSyncShopee] = React.useState(false)
    const [syncModal, setSyncModal] = React.useState(false)
    const [Search, setSearch] = React.useState("")
    const [isProductDelete, setProductDelete] = React.useState(false)

    const ViewVariant = (item: any) => {
        setSelectedItem(item)
        setVariantDetail(true)
    }

    const GetProducts = async (page: number, keywords: string) => {
        setLoading(true)
        try {
            const result = await HTTPGetProducts({ page, keywords: keywords.length === 0 ? undefined : keywords })
            setProductData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
        }
    }

    const onSyncShopee = async () => {
        setSyncModal(false)
        setSyncShopee(true)
        try {
            await HTTPSyncShopeeProducts()
            await GetProducts(1, "")
            setSyncShopee(false)
            toast.success('Berhasil Sinkronisasi Produk dengan Shopee!')
        } catch (error) {
            toast.error('Gagal Sinkronisasi Produk dengan Shopee! Coba beberapa saat lagi')
            setSyncShopee(false)
             
        }
    }

    const onBestSeller = async (status: any, id: string) => {
        try {
            await HTTPAddBestSellers({ is_best_seller: status === 1 ? 0 : 1, product_id: id })
            const result = await HTTPGetProducts({ page: CurrentPage })
            setProductData(result.data.data)
        } catch (error) {
            toast.error('Terjadi Kesalahan! Tidak Bisa mengubah Best Seller!')
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetProducts(value, Search)
    }

    const onDelete = (value: any) => {
        setSelectedProduct({
            id: value.id,
            name: value.name_id
        })
        setProductDelete(true)
    }

    React.useEffect(() => {
        async function Initial() {
            await GetProducts(CurrentPage, Search)
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="20" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>PRODUK SAYA</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Produk / Produk Saya</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <Stack direction={"column"} gap={3}>
                            <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                <Stack direction={'column'} alignItems={'flex-start'} gap={2}>
                                    <Stack direction={"row"} alignItems={'center'} justifyContent={'space-between'} width={'100%'} gap={1}>
                                        <Button
                                            sx={{ backgroundColor: Colors.primary }}
                                            color={'secondary'}
                                            startIcon={<Icon fontSize={'small'}>add</Icon>}
                                            variant={'contained'}
                                            onClick={() => navigate('/product/add')}
                                        >
                                            <span style={{ color: '#fff' }}>Tambah Produk</span>
                                        </Button>
                                        <Button
                                            onClick={() => setSyncModal(true)}
                                            sx={{ backgroundColor: Colors.primary }}
                                            color={'secondary'}
                                            startIcon={<Icon fontSize={'small'}>sync</Icon>}
                                            variant={'contained'}
                                        >
                                            <span style={{ color: '#fff' }}>Sync Shopee</span>
                                        </Button>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={'center'} justifyContent={'flex-end'} width={'100%'} gap={1}>
                                        <TextField
                                            type="text"
                                            placeholder="Cari Produk"
                                            size="small"
                                            sx={{ bgcolor: "white" }}
                                            value={Search}
                                            onChange={(text) => setSearch(text.target.value)}
                                            onKeyDown={(e) => { if (e.keyCode === 13) GetProducts(1, Search) }}
                                            InputProps={{
                                                sx: { borderRadius: 1, },
                                                endAdornment: (
                                                    Search.length > 0 ?
                                                        <Icon
                                                            fontSize={'medium'}
                                                            sx={{ cursor: 'pointer', color: '#adb0b3' }}
                                                            onClick={() => { GetProducts(1, ""); setSearch("") }}
                                                        >close</Icon>
                                                        : <div></div>
                                                )
                                            }}
                                        />
                                        <Button
                                            sx={{ backgroundColor: Colors.primary }}
                                            color={'secondary'}
                                            variant={'contained'}
                                            onClick={() => GetProducts(1, Search)}
                                        >Cari Produk</Button>
                                    </Stack>
                                </Stack>
                            </div>
                            <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Tabs
                                        textColor={'secondary'}
                                        indicatorColor={'secondary'}
                                        value={menuActive}
                                        onChange={(e, value) => setMenuActive(value)}
                                    >
                                        <Tab label="Semua" />
                                        <Tab label="Produk Habis (0)" />
                                    </Tabs>
                                    <Stack direction={"row"} gap={1} alignItems="center">
                                        <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                        <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                    {
                                        isLoading ?
                                            <SkeletonListLoader />
                                            :
                                            <>
                                                {
                                                    ProductData.length === 0 ?
                                                        <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                            <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                            <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Produk terlebih dahulu</Typography>
                                                        </Stack>
                                                        :
                                                        <TableContainer>
                                                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ width: '10%' }} align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '20%' }} align="left">Nama Produk</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Harga</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Variasi</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">BESTSELLERS</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {ProductData.map((item: any, index: number) => (
                                                                        <TableRow
                                                                            key={index}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                            <TableCell align="left">
                                                                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                                                    <img
                                                                                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                                                                                        src={item.galleries.length === 0 ? Images.noImage : item.galleries[0].image_path}
                                                                                        alt=""
                                                                                        onError={({ currentTarget }) => {
                                                                                            currentTarget.onerror = null;
                                                                                            currentTarget.src = Images.noImage;
                                                                                        }}
                                                                                    />
                                                                                    <Typography variant="body2" color="#000" sx={{ ...Truncate, WebkitLineClamp: 3 }}>{item.name_id}</Typography>
                                                                                    {
                                                                                        item.is_shopee === 1 ?
                                                                                            <Tooltip
                                                                                                title="Termasuk Produk Shopee"
                                                                                                PopperProps={{ sx: { maxWidth: 200, textAlign: 'center' } }}
                                                                                                arrow
                                                                                            >
                                                                                                <img src={Images.shopeeLogo} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                                                                                            </Tooltip>
                                                                                            : null
                                                                                    }
                                                                                </Stack>
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                <Typography variant="body2" color="#000">{item.display_price}</Typography>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Button variant={'text'} color={'primary'} onClick={() => ViewVariant(item.variant_prices)}>Lihat Variasi</Button>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Switch
                                                                                    checked={item.is_best_seller === 1}
                                                                                    onChange={() => onBestSeller(item.is_best_seller, item.id)}
                                                                                    color={'secondary'}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Stack direction={'row'} gap={0} alignItems={'center'} justifyContent={'center'}>
                                                                                    <IconButton onClick={() => navigate(`/product/edit?pid=${item.id}`)}>
                                                                                        <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                    </IconButton>
                                                                                    <IconButton onClick={() => onDelete(item)}>
                                                                                        <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                }
                                            </>
                                    }
                                    <div style={{ width: '100%', ...CENTER }}>
                                        <Pagination
                                            page={CurrentPage}
                                            count={TotalPage}
                                            onChange={onChangePage}
                                        />
                                    </div>
                                </Stack>
                            </div>
                        </Stack>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div >
            <ProductVariantModal
                isOpen={VariantDetail}
                onClose={() => setVariantDetail(false)}
                item={SelectedItem}
            />
            <Dialog open={isSyncShopee} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={3} alignItems={'center'}>
                        <img src={Images.shopeeLogo} style={{ width: '8vw', objectFit: 'contain', aspectRatio: 1 }} alt="" />
                        <Typography variant="h5" color={'#fff'} textAlign={'center'} fontWeight={"600"}>Sinkronisasi Produk Shopee</Typography>
                        <ThreeDots
                            height="100"
                            width="100"
                            radius="9"
                            color={'#e34d1d'}
                            ariaLabel="three-dots-loading"
                            visible={true}
                        />
                    </Stack>
                </div>
            </Dialog>
            <ShopeeSync
                isOpen={syncModal}
                onClose={() => setSyncModal(false)}
                onConfirm={onSyncShopee}
            />
            <ProductDelete
                isOpen={isProductDelete}
                onClose={() => setProductDelete(false)}
                item={SelectedProduct}
                getData={() => GetProducts(CurrentPage, Search)}
            />
        </div >
    )
}

export default ProductMeScreen;