import * as React from 'react';
import { Button, Checkbox, FormControlLabel, Icon, Stack, TextField, IconButton, Typography, CircularProgress } from '@mui/material'
import { Images } from '../../assets/images';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { HTTPLogin, HTTPProfile, HTTPRefreshToken } from '../../apis/auth';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from "react-redux";
import { setProfileData } from '../../stores/data/profile';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner'

const LoginScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [init, setInit] = React.useState(false)
    const [isPasswordShow, setPasswordShow] = React.useState(true)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [isLoginError, setLoginError] = React.useState(false)
    const [LoginErrorText, setLoginErrorText] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    const [isStartup, setStartup] = React.useState(true)

    const onSignin = async () => {
        setLoginError(false)
        if (email.length === 0 || password.length === 0) {
            setLoginError(true)
            setLoginErrorText('Harap masukkan email & password pada kolom diatas!')
        } else {
            setLoading(true)
            try {
                const result = await HTTPLogin({ email, password })
                secureLocalStorage.setItem('token', result.data.data.token)
                secureLocalStorage.setItem('refresh_token', result.data.data.refresh_token)
                localStorage.setItem('isLogin', 'true')
                navigate('/product/brand')
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setLoginError(true)
                setLoginErrorText('Terjadi Kesalahan! Periksa kembali form di atas!')
                 
            }
        }
    }

    const Auth = async () => {
        setStartup(true)
        const isLogin = localStorage.getItem('isLogin')
        if (isLogin === null || isLogin === undefined) {
            setStartup(false)
            localStorage.setItem('isLogin', 'false')
        } else {
            if (isLogin === 'true') {
                try {
                    const token = secureLocalStorage.getItem("refresh_token") as string
                    const refresh = await HTTPRefreshToken({ token })
                    const result = await HTTPProfile({ token: refresh.data.data.token })
                    dispatch(setProfileData({ data: result.data.data }))
                    secureLocalStorage.setItem('token', refresh.data.data.token)
                    secureLocalStorage.setItem('refresh_token', refresh.data.data.refresh_token)
                    localStorage.setItem('isLogin', 'true')
                    setTimeout(() => {
                        navigate('/product/brand')
                    }, 2000)
                } catch (error) {
                    setStartup(false)
                    localStorage.setItem('isLogin', 'false')
                }
            } else {
                setStartup(false)
                localStorage.setItem('isLogin', 'false')
            }
        }
    }

    React.useEffect(() => {
        async function CheckAuth() {
            try {
                Auth()
            } catch (error) {
                 
            }
        }
        CheckAuth().catch().then()
    }, [init])

    return (
        <div style={{ width: '100%', minHeight: '100vh', backgroundColor: isMobile ? '#fff' : undefined }}>
            {
                isStartup ?
                    <div style={{ ...CENTER, height: '100vh', width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff' }}>
                        <Stack direction={'column'} gap={0} alignItems={'center'} justifyContent={'center'}>
                            <img src={Images.mainlogoColor} style={{ width: '15vw', height: 'auto', objectFit: 'contain' }} alt="" />
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color={Colors.primary}
                                ariaLabel="three-dots-loading"
                                visible={true}
                            />
                        </Stack>
                    </div>
                    :
                    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ padding: isMobile ? 0 : '5% 0', width: '100%' }} gap={5}>
                        <div style={{ backgroundColor: '#fff', borderRadius: isMobile ? 0 : 10, width: isMobile ? '100%' : '30%' }}>
                            <div style={{ position: 'relative' }}>
                                <div style={{
                                    backgroundImage: `url(${Images.loginBg})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                    borderRadius: isMobile ? '0 0 50% 50%' : '10px 10px 50% 50%',
                                    padding: '20% 0',
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    background: `linear-gradient(to left, #861ea6, #713684)`,
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: isMobile ? '0 0 50% 50%' : '10px 10px 50% 50%',
                                    opacity: 0.8,
                                    ...CENTER
                                }}>
                                    <Stack direction={"column"} gap={1} alignItems={'center'}>
                                        <h2 style={{ color: '#fff', fontWeight: '500', margin: 0 }}>Welcome Back!</h2>
                                        <span style={{ color: '#ffffff80' }}>Sign in to continue to Angelo</span>
                                    </Stack>
                                </div>
                                <div style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: '-20%',
                                    margin: '0px auto',
                                    width: '74px',
                                    height: '74px',
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    backgroundColor: '#fff',
                                    ...CENTER
                                }}>
                                    <img src={Images.logo} style={{ width: '50%', height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                </div>
                            </div>
                            <Stack direction={'column'} gap={3} sx={{ padding: 5 }}>
                                <Stack direction={'column'} gap={1}>
                                    <span style={{ color: Colors.secondary, fontWeight: '600' }}>Email</span>
                                    <TextField
                                        type="text"
                                        placeholder="Email"
                                        size="small"
                                        sx={{ bgcolor: "white", width: "100%" }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={email}
                                        onChange={(text) => setEmail(text.target.value)}
                                    />
                                </Stack>
                                <Stack direction={'column'} gap={1}>
                                    <span style={{ color: Colors.secondary, fontWeight: '600' }}>Password</span>
                                    <TextField
                                        type={isPasswordShow ? 'password' : 'text'}
                                        placeholder="Password"
                                        size="small"
                                        sx={{ bgcolor: "white", width: "100%" }}
                                        value={password}
                                        onChange={(text) => setPassword(text.target.value)}
                                        onKeyDown={(e) => { if (e.keyCode === 13) onSignin() }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={() => setPasswordShow(!isPasswordShow)}>
                                                    <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isPasswordShow ? 'visibility' : 'visibility_off'}</Icon>
                                                </IconButton>
                                            ),
                                            sx: { borderRadius: 1 }
                                        }}
                                    />
                                    {
                                        isLoginError ?
                                            <Typography variant={"subtitle2"} color={Colors.error}>{LoginErrorText}</Typography>
                                            :
                                            null
                                    }
                                </Stack>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
                                <Button onClick={onSignin} color={"secondary"} sx={{ backgroundColor: Colors.primary }} variant='contained' disabled={isLoading}>
                                    {
                                        isLoading ?
                                            <CircularProgress color="secondary" size={20} />
                                            :
                                            'LOG IN'
                                    }
                                </Button>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                    <Icon fontSize={"small"} sx={{ color: Colors.secondary }}>lock</Icon>
                                    <small style={{ color: Colors.secondary }}>Forgot your Password?</small>
                                </Stack>
                            </Stack>
                        </div>
                        <Stack direction={'column'} gap={2} alignItems={'center'}>
                            <span style={{ color: Colors.secondary }}>
                                Don't have an account?
                                <span style={{ color: Colors.primary }}>{' Signup now'}</span>
                            </span>
                            <span style={{ color: Colors.secondary }}>
                                ©️ {moment().format('YYYY')}, Angeloskin.
                            </span>
                        </Stack>
                    </Stack >
            }
        </div >
    )
}

export default LoginScreen;