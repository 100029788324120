import React from 'react'
import { Icon, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Switch, Typography } from '@mui/material';
import { CENTER, Colors } from '../../utils/colors';
import { AddressDelete, AddressForm } from './addressModal';
import { SkeletonListLoader } from '../loader/skeleton';
import { toast } from 'react-toastify';
import { HTTPUpdateAddress } from '../../apis/address';
import secureLocalStorage from 'react-secure-storage';

const AddressTable = (param: { data: any, getData: any, isLoading: boolean, loadData: any }) => {
    const [AddressModal, setAddressModal] = React.useState(false)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [isDelete, setDelete] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string

    const onUpdate = (value: any) => {
        setSelectedItem(value)
        setAddressModal(true)
    }

    const onDelete = (value: any) => {
        setSelectedItem(value)
        setDelete(true)
    }

    const onAddressMain = async (value: any) => {
        try {
            await HTTPUpdateAddress({
                token,
                province_id: value.province.id,
                city_id: value.city.id,
                subdistrict_id: value.subdistrict.id,
                postal_code: value.postal_code,
                address: value.address,
                address_detail: value.address_detail,
                is_main: value.is_main === 0 ? 1 : 0,
                address_id: value.id
            })
            param.getData()
        } catch (error) {
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                {
                    param.isLoading ?
                        <SkeletonListLoader />
                        :
                        <>
                            {
                                param.data.length === 0 ?
                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah data Alamat terlebih dahulu</Typography>
                                    </Stack>
                                    :
                                    <TableContainer>
                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: '10%' }} align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '40%' }} align="left">Alamat</TableCell>
                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Alamat Utama</TableCell>
                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {param.data.map((item: any, index: number) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                        <TableCell align="left">
                                                            {item.address + ', ' + item.subdistrict.name + ', ' + item.city.type + ' ' + item.city.name + ', ' + item.province.name + ', ' + item.postal_code}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Switch
                                                                checked={item.is_main === 1}
                                                                onChange={() => onAddressMain(item)}
                                                                color={'secondary'}
                                                            /></TableCell>
                                                        <TableCell align="center">
                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                <IconButton onClick={() => onUpdate(item)}>
                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                </IconButton>
                                                                <IconButton onClick={() => onDelete(item)}>
                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                </IconButton>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            }
                        </>
                }
                <div style={{ width: '100%', ...CENTER }}>
                    <Pagination count={5} />
                </div>
            </Stack>
            <AddressForm
                isOpen={AddressModal}
                onClose={() => setAddressModal(false)}
                type={'edit'}
                getData={param.loadData}
                item={SelectedItem}
            />
            <AddressDelete
                isOpen={isDelete}
                onClose={() => setDelete(false)}
                getData={param.loadData}
                item={SelectedItem}
            />
        </div>
    )
}

export default AddressTable;