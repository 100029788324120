import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress, Toolbar } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { ImagePicker } from '../utils/imagePicker';
import secureLocalStorage from 'react-secure-storage';
import { HTTPUpdateLogoConfig } from '../../apis/settings';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const LogoModal = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const [ImageResult, setImageResult] = React.useState('')

    const onClose = () => {
        setImageResult('')
        param.onClose()
    }

    const ActionLogo = async () => {
        setLoading(true)
        try {
            await HTTPUpdateLogoConfig({ app_logo: ImageResult, token })
            toast.success('Berhasil mengubah Logo Website!')
            setLoading(false)
            onClose()
            param.getData()
        } catch (error) {
            toast.error('Terjadi Kesalahan')
            setLoading(false)
             
        }
    }

    React.useEffect(() => {
        if (param.isOpen === true) {
            setImageResult(param.item.logo)
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>Ubah Logo Website</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Toolbar />
                <Stack
                    direction={isMobile ? 'column' : "row"}
                    justifyContent={isMobile ? 'flex-start' : 'space-between'}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    gap={isMobile ? 1 : 3}
                    marginBottom={5}
                >
                    <Typography variant="body1" color={Colors.secondary}>Gambar Sertifikat:</Typography>
                    <div style={{ width: isMobile ? '100%' : '70%' }}>
                        <ImagePicker onResult={(img: any) => setImageResult(img)} initValue={ImageResult} />
                    </div>
                </Stack>
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        color={"secondary"}
                        variant={'contained'}
                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                        onClick={ActionLogo}
                    >Simpan</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}