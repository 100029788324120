import { Button, ButtonProps, Icon, IconButton, Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { BrandDelete, BrandForm } from '../../components/products/brandModal';
import { toast } from 'react-toastify';
import { HTTPGetBrands } from '../../apis/brands';
import { SkeletonListLoader } from '../../components/loader/skeleton';

const PrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#fff',
    backgroundColor: Colors.primary,
    padding: '10px 20px',
    '&:hover': {
        backgroundColor: '#9c27b0',
    },
}));

const ProductBrandScreen = () => {
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [Brands, setBrands] = React.useState([])
    const [isBrandDelete, setBrandDelete] = React.useState(false)
    const [isBrandModal, setBrandModal] = React.useState(false)
    const [ModalType, setModalType] = React.useState('add')
    const [selectedItem, setSelectedItem] = React.useState({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const OpenBrandModal = (value: string, item: any) => {
        setModalType(value)
        setSelectedItem(item)
        setBrandModal(true)
    }

    const onDelete = (item: any) => {
        setSelectedItem(item)
        setBrandDelete(true)
    }

    const GetBrands = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetBrands({ page })
            setBrands(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetBrands(value)
    }

    React.useEffect(() => {
        async function APIList() {
            await GetBrands(1)
        }

        APIList().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="20" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>BRAND</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Produk / Brand</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4}>
                                <Stack direction={'row'} alignItems={'center'} gap={isMobile ? 1 : 2} justifyContent={'space-between'} width={"100%"}>
                                    <PrimaryButton onClick={() => OpenBrandModal('add', {})} startIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>add</Icon>}>
                                        <span style={{ color: '#fff' }}>{isMobile ? 'Tambah' : 'Tambah Brand'}</span>
                                    </PrimaryButton>
                                    <Stack direction={"row"} gap={isMobile ? 1 : 2} alignItems="center">
                                        <Stack direction={"row"} gap={1} alignItems="center">
                                            <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                            <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                        </Stack>
                                        <IconButton>
                                            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.secondaryDark }}>delete</Icon>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                Brands.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Brand terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ width: '10%' }} align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                    <TableCell sx={{ width: '75%', fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Nama Brand</TableCell>
                                                                    <TableCell sx={{ width: '15%', fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Brands.map((item: { id: string, name: string, products_total: number }) => (
                                                                    <TableRow
                                                                        key={item.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                                <IconButton onClick={() => OpenBrandModal('edit', item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onDelete(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination
                                        page={CurrentPage}
                                        count={TotalPage}
                                        onChange={onChangePage}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <BrandForm
                isOpen={isBrandModal}
                onClose={() => setBrandModal(false)}
                type={ModalType}
                getData={() => GetBrands(CurrentPage)}
                item={selectedItem}
            />
            <BrandDelete
                isOpen={isBrandDelete}
                onClose={() => setBrandDelete(false)}
                getData={() => GetBrands(CurrentPage)}
                item={selectedItem}
            />
        </div>
    )
}

export default ProductBrandScreen;