import { Button, Icon, Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { VoucherDelete } from '../../components/promotions/voucherModal';
import { toast } from 'react-toastify';
import { HTTPGetVouchers } from '../../apis/voucher';
import secureLocalStorage from 'react-secure-storage';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setVoucherTempData } from '../../stores/data/voucher';

const PromotionVoucherScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [isVoucherDelete, setVoucherDelete] = React.useState(false)
    const [VoucherList, setVoucherList] = React.useState([])
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const GetVouchers = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetVouchers({ token, page })
            setVoucherList(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan')
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetVouchers(value)
    }

    React.useEffect(() => {
        async function Initial() {
            await GetVouchers(1)
        }

        Initial().then().catch()
    }, [init])

    const onUpdate = (value: any) => {
        dispatch(setVoucherTempData({ data: value }))
        navigate('/promotion/voucher/edit')
    }

    const onDelete = (value: any) => {
        setSelectedItem(value)
        setVoucherDelete(true)
    }


    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="30" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Voucher</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pusat Promosi / Voucher</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Button onClick={() => navigate('/promotion/voucher/add')} variant={'contained'} color={'secondary'} sx={{ backgroundColor: Colors.primary }} startIcon={<Icon sx={{ color: '#fff' }} fontSize={'small'}>add</Icon>}>
                                        <span style={{ color: '#fff' }}>Tambah Voucher</span>
                                    </Button>
                                    <Stack direction={"row"} gap={1} alignItems="center">
                                        <Typography variant={'h6'}>Total</Typography>
                                        <Typography variant={'h6'} color={Colors.primary}>{TotalData}</Typography>
                                    </Stack>
                                </Stack>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                VoucherList.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'} height={"100%"}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Admin terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Nama Voucher</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Kode Voucher</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Periode Pemakaian</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {VoucherList.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center"><b>{item.code}</b></TableCell>
                                                                        <TableCell align="center">{moment(item.start_period).format('DD/MM/YYYY')} - {moment(item.end_period).format('DD/MM/YYYY')}</TableCell>
                                                                        <TableCell align="center">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                                <IconButton onClick={() => onUpdate(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onDelete(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination
                                        page={CurrentPage}
                                        count={TotalPage}
                                        onChange={onChangePage}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <VoucherDelete
                isOpen={isVoucherDelete}
                onClose={() => setVoucherDelete(false)}
                item={SelectedItem}
                getData={() => GetVouchers(CurrentPage)}
            />
        </div>
    )
}

export default PromotionVoucherScreen;