import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress, Grid, Select, MenuItem } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPAddBrand, HTTPDeleteBrand, HTTPEditBrand } from '../../apis/brands';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PasswordForm = (param: { isOpen: boolean, onClose: any, onSuccess: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    // const [Password, setPassword] = React.useState('')
    // const [ConfirmPass, setConfirmPass] = React.useState('')
    const [isPasswordShow, setPasswordShow] = React.useState(true)
    const [isConfirmPassShow, setConfirmPassShow] = React.useState(true)

    const onClose = () => {
        // setBrandName('')
        param.onClose()
    }

    // const ActionBrand = async () => {
    //     setLoading(true)
    //     try {
    //         if (param.type === 'add') {
    //             await HTTPAddBrand({ name: BrandName })
    //         } else {
    //             await HTTPEditBrand({ brand_id: param.item.id, name: BrandName })
    //         }
    //         setLoading(false)
    //         onClose()
    //         param.getData()
    //     } catch (error) {
    //         setLoading(false)
    //         toast.error('Terjadi Kesalahan')
    //     }
    // }

    // React.useEffect(() => {
    //     if (param.isOpen === true && param.type === 'edit') {
    //         setBrandName(param.item.name)
    //     }
    // }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogContent>
                <Stack direction={'column'} gap={3} width={'100%'}>
                    <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} gap={isMobile ? 1 : 3}>
                        <Typography variant="body1" color={Colors.secondary} width={'30%'}>Password:</Typography>
                        <TextField
                            type={isPasswordShow ? 'password' : "text"}
                            placeholder="Masukkan Password"
                            size="small"
                            sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                            InputProps={{
                                sx: { borderRadius: 1 },
                                endAdornment: (
                                    <IconButton onClick={() => setPasswordShow(!isPasswordShow)}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isPasswordShow ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                ),
                            }}
                        // value={BrandName}
                        // onChange={(text) => setBrandName(text.target.value)}
                        // onKeyDown={(e) => { if (e.keyCode === 13) ActionBrand() }}
                        />
                    </Stack>
                    <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} gap={isMobile ? 1 : 3}>
                        <Typography variant="body1" color={Colors.secondary} width={'30%'}>Konfimasi Password:</Typography>
                        <TextField
                            type={isConfirmPassShow ? 'password' : "text"}
                            placeholder="Masukkan Konfirmasi Password"
                            size="small"
                            sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                            InputProps={{
                                sx: { borderRadius: 1 },
                                endAdornment: (
                                    <IconButton onClick={() => setConfirmPassShow(!isConfirmPassShow)}>
                                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isConfirmPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                    </IconButton>
                                ),
                            }}
                        // value={BrandName}
                        // onChange={(text) => setBrandName(text.target.value)}
                        // onKeyDown={(e) => { if (e.keyCode === 13) ActionBrand() }}
                        />
                    </Stack>
                    <div style={{ width: '100%', ...CENTER }}>
                        <Button
                            onClick={param.onSuccess}
                            color={"secondary"}
                            variant={'contained'}
                            disabled={isLoading}
                            sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                            startIcon={isLoading ?
                                <CircularProgress color="secondary" size={20} />
                                : <Icon fontSize={'small'}>save</Icon>}
                        >SIMPAN</Button>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
