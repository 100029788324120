import axios from "axios";
import { AxiosNormal } from "../utils/interceptors";
const url = "vouchers";

export function HTTPGetVouchers(param: {
  token: string;
  page: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/list?page=${param.page}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetVoucherByID(param: {
  voucher_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/detail/${param.voucher_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddVoucher(param: {
  token: string;
  name: string;
  code: string;
  start_period: string;
  end_period: string;
  description: string;
  type: string;
  discount_type: string;
  discount_value: number;
  max_discount: number;
  max_usage: number;
  min_transaction: number;
  total_quota: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
        name: param.name,
        code: param.code,
        start_period: param.start_period,
        end_period: param.end_period,
        description: param.description,
        type: param.type,
        discount_type: param.discount_type,
        discount_value: param.discount_value,
        max_discount: param.max_discount,
        max_usage: param.max_usage,
        min_transaction: param.min_transaction,
        total_quota: param.total_quota,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateVoucher(param: {
  token: string;
  name: string;
  code: string;
  start_period: string;
  end_period: string;
  description: string;
  type: string;
  discount_type: string;
  discount_value: number;
  max_discount: number;
  max_usage: number;
  min_transaction: number;
  total_quota: number;
  voucher_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/update/${param.voucher_id}`,
        {
          token: param.token,
          name: param.name,
          code: param.code,
          start_period: param.start_period,
          end_period: param.end_period,
          description: param.description,
          type: param.type,
          discount_type: param.discount_type,
          discount_value: param.discount_value,
          max_discount: param.max_discount,
          max_usage: param.max_usage,
          min_transaction: param.min_transaction,
          total_quota: param.total_quota,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteVoucher(param: {
  voucher_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/delete/${param.voucher_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
