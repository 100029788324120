import { Button, ButtonProps, Icon, Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { UserAdminDelete } from '../../components/users/userModal';
import { HTTPGetUsers } from '../../apis/users';
import secureLocalStorage from 'react-secure-storage';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { useDispatch } from 'react-redux';
import { setAdminTempData } from '../../stores/data/admin';

const PrimaryButton = styled(Button)<ButtonProps>(() => ({
    color: '#fff',
    backgroundColor: Colors.primary,
    padding: '10px 20px',
    '&:hover': {
        backgroundColor: '#9c27b0',
    },
}));

const UserAdminScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [init, setInit] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string
    const [isUserDelete, setUserDelete] = React.useState(false)
    const [AdminList, setAdminList] = React.useState([])
    const [isLoading, setLoading] = React.useState(true)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const GetAdmins = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetUsers({ token, page })
            setAdminList(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetAdmins(value)
    }

    React.useEffect(() => {
        async function APIList() {
            await GetAdmins(1)
        }

        APIList().then().catch()
    }, [init])

    const onUpdate = (value: any) => {
        dispatch(setAdminTempData({ data: value }))
        navigate('/user/admin/edit')
    }

    const onDelete = (value: any) => {
        setSelectedItem(value)
        setUserDelete(true)
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="40" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Akun Admin</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pengguna / Admin</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff', minHeight: '50vh' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'} height={'100%'}>
                                <PrimaryButton onClick={() => navigate('/user/admin/add')} startIcon={<Icon fontSize={'small'}>add</Icon>}>
                                    <span style={{ color: '#fff' }}>Tambah Akun Admin</span>
                                </PrimaryButton>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                AdminList.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'} height={"100%"}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Admin terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Nama Admin</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Email</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Role</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {AdminList.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center">{item.email}</TableCell>
                                                                        <TableCell align="center">{item.role.name}</TableCell>
                                                                        <TableCell align="center">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                                <IconButton onClick={() => onUpdate(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onDelete(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                {
                                    AdminList.length === 0 ?
                                        null
                                        :
                                        <div style={{ width: '100%', ...CENTER }}>
                                            <Pagination
                                                page={CurrentPage}
                                                count={TotalPage}
                                                onChange={onChangePage}
                                            />
                                        </div>
                                }
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <UserAdminDelete
                isOpen={isUserDelete}
                onClose={() => setUserDelete(false)}
                item={SelectedItem}
                getData={() => GetAdmins(CurrentPage)}
            />
        </div>
    )
}

export default UserAdminScreen;