import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress, Switch } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { HTTPAddArticleCategory, HTTPDeleteArticleCategory, HTTPEditArticleCategory } from '../../apis/articleCategories';
import secureLocalStorage from 'react-secure-storage';
import { HTTPDeleteArticle } from '../../apis/article';
import { DeleteContent } from '../utils/deleteContent';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            left: 12,
        },
        '&:after': {
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export const CategoryArticleForm = (param: {
    isOpen: boolean,
    onClose: any,
    type: string,
    getData: any,
    item: any
}) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const [isMultiLang, setMultiLang] = React.useState(false)
    const [CategoryArticleName, setCategoryArticleName] = React.useState('')
    const [CategoryArticleNameEn, setCategoryArticleNameEn] = React.useState('')

    const onClose = () => {
        setCategoryArticleName('')
        setCategoryArticleNameEn('')
        setMultiLang(false)
        param.onClose()
    }

    const ActionCategoryArticle = async () => {
        setLoading(true)
        try {
            if (param.type === 'add') {
                await HTTPAddArticleCategory({
                    name_id: CategoryArticleName,
                    name_en: isMultiLang ? CategoryArticleNameEn : CategoryArticleName,
                    token
                })
            } else {
                await HTTPEditArticleCategory({
                    name_id: CategoryArticleName,
                    name_en: isMultiLang ? CategoryArticleNameEn : CategoryArticleName,
                    token,
                    category_id: param.item.id,
                })
            }
            setLoading(false)
            onClose()
            param.getData()
            toast.success('Berhasil menambahkan Kategori Baru!')
        } catch (error) {
            toast.error('Terjadi Kesalahan')
            setLoading(false) 
        }
    }

    React.useEffect(() => {
        if (param.isOpen === true && param.type === 'edit') {
            if (param.item.name_id === param.item.name_en) {
                setMultiLang(false)
                setCategoryArticleName(param.item.name_id)
                setCategoryArticleNameEn('')
            } else {
                setMultiLang(true)
                setCategoryArticleName(param.item.name_id)
                setCategoryArticleNameEn(param.item.name_en)
            }
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>{param.type === 'add' ? 'Tambah Kategori Artikel' : 'Edit Kategori Artikel'}</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction={'column'} gap={isMobile ? 3 : 3}>
                    <Divider></Divider>
                    <Stack direction={"row"} alignItems={'center'} gap={2}>
                        <Typography variant="body1" color={Colors.secondary}>Multi Bahasa:</Typography>
                        <Switch
                            color={'secondary'}
                            checked={isMultiLang}
                            onChange={(val) => setMultiLang(val.target.checked)}
                        />
                    </Stack>
                    <Stack direction={isMobile ? 'column' : "row"} justifyContent={'space-between'} alignItems={isMobile ? 'flex-start' : 'center'} gap={3}>
                        <Typography variant="body1" color={Colors.secondary}>Nama Kategori:</Typography>
                        <TextField
                            type="text"
                            placeholder="Nama Kategori"
                            size="small"
                            sx={{ bgcolor: "white", width: '70%' }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                            value={CategoryArticleName}
                            onChange={(text) => setCategoryArticleName(text.target.value)}
                        />
                    </Stack>
                    {
                        !isMultiLang ? null :
                            <Stack direction={isMobile ? 'column' : "row"} justifyContent={'space-between'} alignItems={isMobile ? 'flex-start' : 'center'} gap={3}>
                                <Typography variant="body1" color={Colors.secondary}>Category Name:</Typography>
                                <TextField
                                    type="text"
                                    placeholder="Nama Kategori (Bahasa Inggris)"
                                    size="small"
                                    sx={{ bgcolor: "white", width: '70%' }}
                                    InputProps={{ sx: { borderRadius: 1 } }}
                                    value={CategoryArticleNameEn}
                                    onChange={(text) => setCategoryArticleNameEn(text.target.value)}
                                />
                            </Stack>
                    }
                    <div style={{ width: '100%', ...CENTER }}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            disabled={isLoading}
                            sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                            startIcon={isLoading ?
                                <CircularProgress color="secondary" size={20} />
                                : <Icon fontSize={'small'}>save</Icon>}
                            onClick={ActionCategoryArticle}
                        >Simpan</Button>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const ArticleDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteArticle = async () => {
        setLoading(true)
        try {
            await HTTPDeleteArticle({ article_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false) 
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <DeleteContent
                    isLoading={isLoading}
                    onCancel={param.onClose}
                    onConfirm={DeleteArticle}
                    text={'artikel ' + param.item.title_id}
                />
            </DialogContent>
        </Dialog>
    )
}

export const ArticleCategoryDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteCategoryArticle = async () => {
        setLoading(true)
        try {
            await HTTPDeleteArticleCategory({ token, category_id: param.item.id })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false) 
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <DeleteContent
                    isLoading={isLoading}
                    onCancel={param.onClose}
                    onConfirm={DeleteCategoryArticle}
                    text={'kategori ' + param.item.name_id}
                />
            </DialogContent>
        </Dialog>
    )
}