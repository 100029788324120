import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const FAQTempSlice = createSlice({
  name: "FAQ_Temp_Data",
  initialState: {
    data: {
      id: "",
      answer_id: "",
      answer_en: "",
      question_id: "",
      question_en: "",
    } as any,
  },
  reducers: {
    setFAQTempData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setFAQTempData } = FAQTempSlice.actions;
export const FAQTempData = FAQTempSlice.reducer;
