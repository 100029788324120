import * as React from 'react';
import ReactApexChart from 'react-apexcharts';

export const BarChart = () => {
    const [series, setSeries] = React.useState([
        {
            data: [3, 6, 4, 7, 9, 4]
        }
    ])

    const [options, setOption] = React.useState<any>({
        chart: {
            toolbar: {
                show: !1
            },
        },
        plotOptions: {
            bar: {
                horizontal: !0, barHeight: '24%', endingShape: 'rounded',
            }
        },
        dataLabels: {
            enabled: !1
        },
        colors: ['#556ee6'],
        xaxis: {
            categories: ['Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun'],
            title: {
                text: 'thousands'
            },
        },
    })

    return (
        <div>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="250"
                className="apex-charts"
            />
        </div>
    )
}

export const BarLineChart = () => {
    const [series, setSeries] = React.useState([
        {
            name: "Complete Tasks",
            type: "column",
            data: [23, 11, 22, 27, 13, 22, 52, 21, 44, 22, 30],
        },
        {
            name: "All Tasks",
            type: "line",
            data: [23, 11, 34, 27, 17, 22, 62, 32, 44, 22, 39],
        },
    ])

    const [options, setOptions] = React.useState<any>({
        chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
        stroke: { width: [0, 2, 5], curve: "smooth" },
        plotOptions: { bar: { columnWidth: "20%", endingShape: "rounded" } },
        colors: ["#556ee6", "#34c38f"],
        fill: {
            gradient: {
                inverseColors: !1,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
        ],
        markers: { size: 0 },
        yaxis: { min: 0 },
    })
    return (
        <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={280}
            className="apex-charts"
        />
    )
}