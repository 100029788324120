import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { Images } from '../../assets/images';
import { CENTER, Colors, Truncate } from '../../utils/colors';
import { FormatCurrency } from '../../utils/utils';

export const OrderPayment = (param: { data: any }) => {
    return (
        <Stack direction={'column'} gap={3} alignItems={'flex-start'} width={'100%'}>
            <Typography variant="body1" fontWeight={'600'} color={'#000'}>Informasi Pembayaran:</Typography>
            {
                param.data.details.length === 0 ?
                    <div style={{ width: '100%', ...CENTER }}>
                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                    </div>
                    :
                    <TableContainer>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '10%' }} align="center">No.</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '25%' }} align="left">Nama Produk</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Harga Satuan</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Jumlah</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Subtotal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {param.data.details.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="left">
                                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                <img
                                                    src={
                                                        item.product === null ?
                                                            Images.noImage
                                                            :
                                                            item.product.galleries[0].image_path
                                                    }
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = Images.noImage;
                                                    }}
                                                    style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 10 }}
                                                    alt=""
                                                />
                                                {
                                                    item.product === null ?
                                                        <Typography variant="body2" color="#6a6a6a"><i>Tanpa Nama</i></Typography>
                                                        :
                                                        <Typography variant="body2" color="#6a6a6a" sx={{ ...Truncate, WebkitLineClamp: 3 }}>{item.product.name_id}</Typography>
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="center">{item.price}</TableCell>
                                        <TableCell align="center">{item.quantity}</TableCell>
                                        <TableCell align="center">Rp{FormatCurrency(item.total_price)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
            <Stack direction={'row'} gap={10} width={'100%'} justifyContent={"flex-end"} alignItems={'flex-start'}>
                <Stack direction={'column'} gap={1}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Subtotal Pesanan</Typography>
                    <Typography variant="body1" color={Colors.secondaryDark}>Subtotal Ongkos Kirim</Typography>
                    <Typography variant="body1" color={Colors.secondaryDark}>Diskon Voucher</Typography>
                    <Typography variant="body1" color={Colors.secondaryDark}><b>Total Pesanan</b></Typography>
                </Stack>
                <div></div>
                <Stack direction={'column'} gap={1} alignItems={'flex-end'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>{param.data.subtotal_text}</Typography>
                    <Typography variant="body1" color={Colors.secondaryDark}>{param.data.shipping_info.shipping_package.cost[0].value}</Typography>
                    <Typography variant="body1" color={Colors.error}>-{param.data.discount_text}</Typography>
                    <Typography variant="body1" color={Colors.secondaryDark}><b>{param.data.total_text}</b></Typography>
                </Stack>
            </Stack>
        </Stack >
    )
}