import { Button, Icon, Stack, Toolbar, Typography, TextField, CircularProgress, IconButton } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { HTTPAddCustomer, HTTPUpdateCustomer } from '../../apis/customers';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';

const UserCustomerFormScreen = () => {
    const { type } = useParams()
    const navigate = useNavigate()
    const CustomerRedux = useSelector((state: RootState) => state.customer_temp_data.data)
    const token = secureLocalStorage.getItem('token') as string

    const [init, setInit] = React.useState(false)
    const [Name, setName] = React.useState('')
    const [Email, setEmail] = React.useState('')
    const [Password, setPassword] = React.useState('')
    const [ConfirmPassword, setConfirmPassword] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    const [isPasswordErr, setPasswordErr] = React.useState(false)
    const [isPassShow, setPassShow] = React.useState(true)
    const [isConfirmPassShow, setConfirmPassShow] = React.useState(true)

    const ActionCustomer = async () => {
        setLoading(true)
        setPasswordErr(false)
        try {
            if (type === 'edit') {
                await HTTPUpdateCustomer({
                    name: Name,
                    email: Email,
                    password: Password,
                    password_confirmation: ConfirmPassword,
                    token,
                    customer_id: CustomerRedux.id
                })
                setLoading(false)
                toast.success('Berhasil mengubah Customer')
                navigate(-1)
            } else {
                if (Password !== ConfirmPassword) {
                    setPasswordErr(true)
                    setLoading(false)
                    toast.error('Konfirmasi Password tidak sesuai')
                } else {
                    await HTTPAddCustomer({
                        name: Name,
                        email: Email,
                        password: Password,
                        password_confirmation: ConfirmPassword,
                        token
                    })
                    setLoading(false)
                    toast.success('Berhasil menambahkan Customer')
                    navigate(-1)
                }
            }
        } catch (error) {
            toast.error('Terjadi Kesalahan menyimpan Data')
            setLoading(false)
             
        }
    }

    React.useEffect(() => {
        function Initial() {
            if (type === 'edit') {
                setName(CustomerRedux.name)
                setEmail(CustomerRedux.email)
            }
        }
        Initial()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="40" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Akun Pelanggan</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pengguna / Pelanggan</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile ? '100%' : '70%'}>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} marginBottom={3}>
                                    <Button
                                        variant={'outlined'}
                                        sx={{ borderColor: Colors.secondary }}
                                        color={'secondary'}
                                        onClick={() => navigate(-1)}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: Colors.secondaryDark }}>arrow_back</Icon>}
                                    >
                                        <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                                    </Button>
                                    <Typography variant="body1" fontWeight={'600'} color={Colors.secondaryDark}>{type === 'add' ? 'FORM TAMBAH PENGGUNA' : 'FORM UBAH PENGGUNA'}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Nama:</Typography>
                                    <TextField
                                        type="text"
                                        placeholder="Nama Anda"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={Name}
                                        onChange={(text) => setName(text.target.value)}
                                    />
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Email:</Typography>
                                    <TextField
                                        type="text"
                                        placeholder="Email Anda"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={Email}
                                        onChange={(text) => setEmail(text.target.value)}
                                    />
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Password:</Typography>
                                    <TextField
                                        type={isPassShow ? 'Password' : 'text'}
                                        placeholder="Password Anda"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        value={Password}
                                        onChange={(text) => setPassword(text.target.value)}
                                        error={isPasswordErr}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={() => setPassShow(!isPassShow)}>
                                                    <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                                </IconButton>
                                            ),
                                            sx: { borderRadius: 1 }
                                        }}
                                    />
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Konfimasi Password:</Typography>
                                    <TextField
                                        type={isConfirmPassShow ? 'Password' : 'text'}
                                        error={isPasswordErr}
                                        placeholder="Konfirmasi Password"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        value={ConfirmPassword}
                                        onChange={(text) => setConfirmPassword(text.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={() => setConfirmPassShow(!isConfirmPassShow)}>
                                                    <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isConfirmPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                                </IconButton>
                                            ),
                                            sx: { borderRadius: 1 }
                                        }}
                                    />
                                </Stack>
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Button
                                        onClick={ActionCustomer}
                                        color={"secondary"}
                                        variant={'contained'}
                                        disabled={isLoading}
                                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                                        startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                                    >Simpan</Button>
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default UserCustomerFormScreen;