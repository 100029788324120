import { AxiosNormal } from "../utils/interceptors";
const url = "configuration";

export function HTTPGetLogoConfig(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}/app_logo`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateLogoConfig(param: {
  token: string;
  app_logo: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/app_logo/update`, {
        token: param.token,
        app_logo: param.app_logo,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetFinanceConfig(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}/finance_setting`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateFinanceConfig(param: {
  token: string;
  service_fee_type: string;
  service_fee_value: number;
  tax_type: string;
  tax_value: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/finance_setting/update`,
        {
          token: param.token,
          service_fee_type: param.service_fee_type,
          service_fee_value: param.service_fee_value,
          tax_type: param.tax_type,
          tax_value: param.tax_value,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
