import { Button, Icon, Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField, Select, MenuItem, Tabs, Tab, Typography, Switch, Badge } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors, Truncate } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { ReceiptForm } from '../../components/order/receiptModal';
import { toast } from 'react-toastify';
import { HTTPGetOrders } from '../../apis/orders';
import secureLocalStorage from 'react-secure-storage';
import { Images } from '../../assets/images';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { useDispatch } from 'react-redux';
import { setOrderIDData } from '../../stores/data/order';
import { StatusList } from './data';

const OrderMeScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [menuActive, setMenuActive] = React.useState(0)
    const [dateFrom, setDateFrom] = React.useState<any>(moment())
    const [dateTo, setDateTo] = React.useState<any>(moment().add('1', 'day'))
    const [ReceiptModal, setReceiptModal] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [OrderData, setOrderData] = React.useState([])
    const [SelectedItem, setSelectedItem] = React.useState('')
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const onReceipt = (value: any) => {
        setSelectedItem(value)
        setReceiptModal(true)
    }

    const GetOrders = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetOrders({ token, page })
            setOrderData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)

            toast.error('Terjadi Kesalahan!')
        }
    }

    const DetailOrder = (value: string) => {
        dispatch(setOrderIDData({ id: value }))
        navigate('/order/detail')
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetOrders(value)
    }

    React.useEffect(() => {
        async function Initial() {
            await GetOrders(1)
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="70" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>PESANAN SAYA</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pesanan / Pesanan Saya</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <Stack direction={"column"} gap={3}>
                            <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                <Stack direction={'column'} alignItems={'flex-start'} gap={2}>
                                    <Stack direction={"row"} alignItems={'center'} justifyContent={'flex-end'} width={'100%'} gap={1}>
                                        <Typography variant="body1" color="#000">Waktu Pesanan Dibuat:</Typography>
                                        <DatePicker
                                            value={dateFrom}
                                            onChange={(date) => setDateFrom(date)}
                                            sx={{
                                                bgcolor: "white",
                                                borderRadius: 1,
                                            }}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                        <div style={{ width: 30, height: 1, backgroundColor: '#000' }}></div>
                                        <DatePicker
                                            value={dateTo}
                                            onChange={(date) => setDateTo(date)}
                                            sx={{
                                                bgcolor: "white",
                                                borderRadius: 1,
                                            }}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                        <Button
                                            sx={{ borderColor: Colors.primary }}
                                            color={'secondary'}
                                            variant={'outlined'}
                                        // onClick={() => navigate('/product/add')}
                                        >Cari Produk</Button>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={'center'} justifyContent={'flex-end'} width={'100%'} gap={1}>
                                        <TextField
                                            type="text"
                                            placeholder="Cari Data Pesanan"
                                            size="small"
                                            sx={{ bgcolor: "white" }}
                                            InputProps={{ sx: { borderRadius: 1 } }}
                                        />
                                        <Button
                                            sx={{ backgroundColor: Colors.primary }}
                                            color={'secondary'}
                                            variant={'contained'}
                                        >Cari Produk</Button>
                                    </Stack>
                                </Stack>
                            </div>
                            <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Tabs
                                        textColor={'secondary'}
                                        indicatorColor={'secondary'}
                                        value={menuActive}
                                        onChange={(e, value) => setMenuActive(value)}
                                    >
                                        <Tab label="Semua" />
                                        <Tab label="Belum Dibayar (0)" />
                                        <Tab label="Perlu Dikirim (0)" />
                                        <Tab label="Dikirim (0)" />
                                        <Tab label="Selesai (0)" />
                                        <Tab label="Pengembalian (0)" />
                                    </Tabs>
                                    <Stack direction={"row"} gap={1} alignItems="center">
                                        <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                        <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                    </Stack>
                                </Stack>
                                <br />
                                {
                                    isLoading ?
                                        <SkeletonListLoader></SkeletonListLoader>
                                        :
                                        <>
                                            {
                                                OrderData.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Tunggu Pelanggan Membuat Pesanan Terlebih Dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                                        <TableContainer>
                                                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ width: '10%' }} align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '20%' }} align="left">Nama Produk</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Total Harga</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '25%' }} align="center">Status Produk</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Tipe Pengiriman</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {OrderData.map((item: any, index: number) => (
                                                                        <TableRow
                                                                            key={index}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                            <TableCell align="left">
                                                                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                                                    {item.details.length === 0 ?
                                                                                        <img
                                                                                            src={Images.noImage}
                                                                                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                                                                                            alt=""
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            src={
                                                                                                item.details[0].product === null ?
                                                                                                    Images.noImage
                                                                                                    :
                                                                                                    item.details[0].product.galleries.length === 0 ?
                                                                                                        Images.noImage
                                                                                                        : item.details[0].product.galleries[0].image_path
                                                                                            }
                                                                                            onError={({ currentTarget }) => {
                                                                                                currentTarget.onerror = null;
                                                                                                currentTarget.src = Images.noImage;
                                                                                            }}
                                                                                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                                                                                            alt=""
                                                                                        />
                                                                                    }
                                                                                    {item.details.length === 0 || item.details[0].product === null ?
                                                                                        <Typography variant="body2" color="#6a6a6a" sx={{ ...Truncate, WebkitLineClamp: 3 }}><i>Tanpa Nama</i></Typography>
                                                                                        :
                                                                                        <Typography variant="body2" color="#6a6a6a" sx={{ ...Truncate, WebkitLineClamp: 3 }}>{item.details[0].product.name_id}</Typography>
                                                                                    }
                                                                                </Stack>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Stack direction={'column'} gap={0} alignItems={'center'}>
                                                                                    <Typography variant="body1" color="#000" fontWeight={'600'}>{item.total_text}</Typography>
                                                                                    <Typography variant="body2" color="#6a6a6a" sx={{ textTransform: 'uppercase' }}>Bank {item.payment_method}</Typography>
                                                                                </Stack>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Stack direction={'column'} gap={0} alignItems={'center'}>
                                                                                    <Typography variant="body1" color={StatusList.find((val) => val.status === item.status)?.color} fontWeight={'600'}>{StatusList.find((val) => val.status === item.status)?.label}</Typography>
                                                                                    <Typography variant="body2" color="#6a6a6a">{StatusList.find((val) => val.status === item.status)?.desc}</Typography>
                                                                                </Stack>
                                                                            </TableCell>
                                                                            <TableCell align="center"><b>{item.shipping_info.shipping_package.service} {`(${item.shipping_info.courier_code})`}</b></TableCell>
                                                                            <TableCell align="center">
                                                                                {
                                                                                    item.status === 'WAITING_DELIVERY' ?
                                                                                        <Button onClick={() => onReceipt(item.id)} variant='text'>Isi Resi</Button>
                                                                                        :
                                                                                        <Button onClick={() => DetailOrder(item.id)} variant='text'>Lihat Rincian</Button>
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <div style={{ width: '100%', ...CENTER }}>
                                                            <Pagination
                                                                page={CurrentPage}
                                                                count={TotalPage}
                                                                onChange={onChangePage}
                                                            />
                                                        </div>
                                                    </Stack>
                                            }
                                        </>
                                }
                            </div>
                        </Stack>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <ReceiptForm
                isOpen={ReceiptModal}
                onClose={() => setReceiptModal(false)}
                getData={() => GetOrders(CurrentPage)}
                item={SelectedItem}
            />
        </div>
    )
}

export default OrderMeScreen;