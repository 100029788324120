export const StatusList = [
  {
    status: "DRAFT",
    color: '#0068ff',
    label: "Belum Diproses",
    desc: "Menunggu Checkout produk dari Pembeli.",
  },
  {
    status: "PENDING",
    color: '#0068ff',
    label: "Belum Dibayar",
    desc: "Menunggu Pembayaran dari Pembeli.",
  },
  {
    status: "WAITING_DELIVERY",
    color: '#000',
    label: "Produk Dikemas",
    desc: "Mohon produk segera dikirim untuk menghindari keterlambatan pengiriman.",
  },
  {
    status: "DONE",
    color: '#24bb33',
    label: "Produk Diterima",
    desc: "Produk telah sampai tujuan.",
  },
  {
    status: "DELIVERING",
    color: '#ffc009',
    label: "Perlu Dikirim",
    desc: "Mohon konfirmasi pengajuan produk tersebut.",
  },
  {
    status: "DELIVERED",
    color: '#cb42d4',
    label: "Produk Dikirim",
    desc: "Menunggu konfirmasi pembeli menerima produk.",
  },
  {
    status: "EXPIRED",
    color: '#000000',
    label: "Pembayaran Kedaluwarsa",
    desc: "Pembayaran tidak dapat lagi dilakukan.",
  },
  {
    status: "CANCELED",
    color: '#bb2023',
    label: "Pembelian Dibatalkan",
    desc: "Pembeli membatalkan pengiriman produk.",
  },
  {
    status: "RETURN_REQUESTED",
    color: '#8e54a0',
    label: "Pengajuan Pengembalian",
    desc: "Mohon konfirmasi pengajuan pengembalian produk tersebut.",
  },
  {
    status: "RETURN_REJECTED",
    color: '#8e54a0',
    label: "Pengembalian Ditolak",
    desc: "Pengajuan pengembalian produk tersebut telah dibatalkan.",
  },
  {
    status: "RETURN_APPROVED",
    color: '#8e54a0',
    label: "Pengembalian Diterima",
    desc: "Pengajuan pengembalian produk tersebut telah diterima.",
  },
];
