import { Stack, Typography, Button, Icon, TextField, Grid, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { toast } from 'react-toastify';
import { HTTPUploadFile } from '../../../apis/utils';
import { CENTER, Colors } from '../../../utils/colors';

export const ProductVariant = (param: { initialData: any; finalData: any; type: string }) => {
    const [init, setInit] = React.useState(false)
    const [isShopee, setShopee] = React.useState(false)
    const [SelectedImage, setSelectedImage] = React.useState<any>(null)
    const [GlobalPrice, setGlobalPrice] = React.useState('')
    const [GlobalQuantity, setGlobalQuantity] = React.useState('')
    const [VariantList, setVariantList] = React.useState<any[]>([])
    const [isWholesale, setWholesale] = React.useState(false)
    const [WholesaleList, setWholesaleList] = React.useState<any[]>([])
    const [TableVariant, setTableVariant] = React.useState<any[]>([])
    const [TableSubVariant, setTableSubVariant] = React.useState<any[]>([])
    const [WholeSubVariant, setWholeSubVariant] = React.useState({ price: '', quantity: '' })
    const [LatestUpdate, setLatestUpdate] = React.useState('')

    const AddVariant = () => {
        if (VariantList.length === 0) {
            const randomID = Math.floor(Math.random() * 1000) + 100
            setVariantList([{
                id: 1,
                label: 'Variasi 1',
                subvariants: [
                    {
                        id: randomID,
                        name: '',
                        image: ''
                    }
                ]
            }])
            setTableVariant([{
                id: randomID,
                variant_index: [0],
                price: '',
                quantity: '',
            }])
        } else {
            const randomID = Math.floor(Math.random() * 1000) + 100
            setVariantList([...VariantList, {
                id: 2,
                label: `Variasi 2`,
                subvariants: [
                    {
                        id: randomID,
                        name: '',
                    }
                ]
            }])
            const newValue = TableVariant.map((item) => {
                return {
                    ...item,
                    price: '',
                    quantity: '',
                }
            })
            setTableVariant(newValue)
            setTableSubVariant([{
                variant_index: [0, 0],
                price: '',
                quantity: '',
            }])
        }
        setLatestUpdate(`AddVariant ${moment().format('x')}`)
    }

    const AddSubVariant = (id: number, index1: number, index2: number) => {
        const randomID = Math.floor(Math.random() * 1000) + 100
        const newValue = VariantList.map((item: any) => (
            item.id === id ? {
                ...item,
                subvariants: [
                    ...item.subvariants,
                    {
                        id: randomID,
                        name: '',
                        image: '',
                    }
                ]
            } : item
        ))
        setVariantList(newValue)
        if (VariantList.length < 2) {
            setTableVariant([...TableVariant, {
                id: randomID,
                variant_index: [index2 + 1],
                price: '',
                quantity: '',
            }])
        }
        setLatestUpdate(`AddSubVariant ${moment().format('x')}`)
    }

    const DeleteSubVariant = (id: number, subId: number, index: number) => {
        if (VariantList.length > 1) {
            if (VariantList[1].subvariants.length === 1 && subId === VariantList[1].subvariants[0].id) {
                const newValue = VariantList.shift()
                setVariantList([newValue])
                setTableSubVariant([])
            } else {
                const newValue = VariantList.map((item: any) => {
                    const result = item.subvariants.filter((item: any) => item.id !== subId)
                    return item.id === id ? { ...item, subvariants: result } : item
                })
                setVariantList(newValue)
                const subValue = TableSubVariant.filter((item) => item.variant_index[1] !== index)
                setTableSubVariant(subValue)
            }
        } else {
            if (VariantList[0].subvariants.length === 1 && subId === VariantList[0].subvariants[0].id) {
                setVariantList([])
            } else {
                const newValue = VariantList.map((item: any) => {
                    const result = item.subvariants.filter((item: any) => item.id !== subId)
                    return item.id === id ? { ...item, subvariants: result } : item
                })
                const newValue2 = TableVariant.filter((item: any) => item.id !== subId)
                setVariantList(newValue)
                setTableVariant(newValue2)
            }
        }
        setLatestUpdate(`DeleteSubVariant ${moment().format('x')}`)
    }

    const InputSubVariant = (event: any, id: number, subId: number) => {
        const text = event.target.value
        const newValue = VariantList.map((item: any) => {
            if (item.id === id) {
                const newItem = item.subvariants.map((value: any) => {
                    return value.id === subId ? { ...value, id: subId, name: text } : value
                })
                return ({
                    ...item,
                    subvariants: newItem
                })
            } else {
                return item
            }
        })
        setVariantList(newValue)
        setLatestUpdate(`InputSubVariant ${moment().format('x')}`)
    }

    const InputTableVariant = (event: any, index: number, type: string) => {
        const text = event.target.value
        const newValue = TableVariant.map((item: any) => {
            return item.variant_index[0] === index ? { ...item, [type]: text } : item
        })
        setTableVariant(newValue)
        setLatestUpdate(`InputTableVariant ${moment().format('x')}`)
    }

    const InputTableSubVariant = (event: any, index1: number, index2: number, type: string) => {
        const text = event.target.value
        if (TableSubVariant.length === 0) {
            setTableSubVariant([{
                variant_index: [index1, index2],
                price: '',
                quantity: '',
                [type]: text
            }])
        } else {
            const checkValue = TableSubVariant.find((item) => item.variant_index[0] === index1 && item.variant_index[1] === index2)
            if (checkValue === undefined) {
                setTableSubVariant([...TableSubVariant, {
                    variant_index: [index1, index2],
                    price: '',
                    quantity: '',
                    [type]: text
                }])
            } else {
                const newValue = TableSubVariant.map((item) => {
                    if (item.variant_index[0] === index1 && item.variant_index[1] === index2) {
                        return ({ ...item, [type]: text })
                    } else {
                        return (item)
                    }
                })
                setTableSubVariant(newValue)
            }
        }
        setLatestUpdate(`InputTableSubVariant ${moment().format('x')}`)
    }

    const ApplyWholeSubVariant = () => {
        if (VariantList.length < 2) {
            const newValue = TableVariant.map((item) => {
                return ({
                    ...item,
                    price: WholeSubVariant.price,
                    quantity: WholeSubVariant.quantity,
                })
            })
            setTableVariant(newValue)
        } else {
            const count = VariantList[0].subvariants.length * VariantList[1].subvariants.length
            let newValue: any[] = []
            for (let j = 0; j < count / VariantList[1].subvariants.length; j++) {
                for (let i = 0; i < count / VariantList[0].subvariants.length; i++) {
                    newValue.push({
                        variant_index: [j, i],
                        price: WholeSubVariant.price,
                        quantity: WholeSubVariant.quantity,
                    })
                }
            }
            setTableSubVariant(newValue)
        }
        setLatestUpdate(`ApplyWholeSubVariant ${moment().format('x')}`)
    }

    const CheckPrice = () => {
        const finalValue = new Set(TableSubVariant.map((item) => item.price))
        return finalValue.size !== TableSubVariant.length
    }

    const AddWholesale = () => {
        if (WholesaleList.length === 0) {
            setWholesaleList([{
                id: Math.floor(Math.random() * 1000) + 100,
                min_quantity: '',
                max_quantity: '',
                price: ''
            }])
        } else {
            const latestValue = WholesaleList[WholesaleList.length - 1].max_quantity
            setWholesaleList([...WholesaleList, {
                id: Math.floor(Math.random() * 1000) + 100,
                min_quantity: (parseInt(latestValue) + 1).toString(),
                max_quantity: '',
                price: ''
            }])
        }
        setWholesale(true)
        setLatestUpdate(`AddWholesale ${moment().format('x')}`)
    }

    const DeleteWholesale = (id: number) => {
        if (WholesaleList.length === 1) {
            const newValue = WholesaleList.filter((item: any) => item.id !== id)
            setWholesaleList(newValue)
            setWholesale(false)
        } else {
            const newValue = WholesaleList.filter((item: any) => item.id !== id)
            setWholesaleList(newValue)
        }
        setLatestUpdate(`DeleteWholesale ${moment().format('x')}`)
    }

    const InputWholeSale = (e: any, id: number, type: string, index: number) => {
        const text = e.target.value
        if (type === 'max_quantity') {
            const newValue = WholesaleList.map((item) => item.id === id ? { ...item, [type]: text } : item)
            const finalValue = newValue.map((item, idx) => idx === index + 1 ? { ...item, min_quantity: (parseInt(text) + 1).toString() } : item)
            setWholesaleList(finalValue)
        } else {
            const newValue = WholesaleList.map((item) => item.id === id ? { ...item, [type]: text } : item)
            setWholesaleList(newValue)
        }
        setLatestUpdate(`InputWholeSale ${moment().format('x')}`)
    }

    const AddImages = async (file: any, id: number) => {
        if (file !== undefined) {
            try {
                const Forms: any = new FormData()
                Forms.append('file', file)
                Forms.append('type', 'variant_pic')
                const result = await HTTPUploadFile({ form: Forms })
                const newValue = VariantList.map((item: any, index: number) => {
                    if (index === 0) {
                        const newItem = item.subvariants.map((value: any) => {
                            return value.id === id ? { ...value, image: result.data.data } : value
                        })
                        return ({
                            ...item,
                            subvariants: newItem
                        })
                    } else {
                        return item
                    }
                })
                setVariantList(newValue)
            } catch (error) {
                toast.error('Format yang di terima adalah JPEG / PNG dan Ukuran Maks. 3mb')
                 
            }
        }
        setLatestUpdate(`AddImage ${moment().format('x')}`)
    }

    const RemoveImage = (id: number) => {
        const newValue = VariantList.map((item: any, index: number) => {
            if (index === 0) {
                const newItem = item.subvariants.map((value: any) => {
                    return value.id === id ? { ...value, image: '' } : value
                })
                return ({
                    ...item,
                    subvariants: newItem
                })
            } else {
                return item
            }
        })
        setVariantList(newValue)
        setSelectedImage(null)
    }

    const InputUpdates = () => {
        const data = {
            price: GlobalPrice,
            quantity: GlobalQuantity,
            variants: {
                variant_1: VariantList[0] === undefined ? [] : VariantList[0].subvariants,
                variant_2: VariantList[1] === undefined ? [] : VariantList[1].subvariants,
                price_and_quantity: VariantList.length === 1 ? TableVariant : TableSubVariant
            },
            wholesales: WholesaleList,
        }
        param.finalData(data)
    }

    const InitialData = () => {
        const data = param.initialData
        setGlobalPrice(data.price)
        setGlobalQuantity(data.quantity)
        setShopee(data.isShopee)
        setVariantList(data.variants)
        if (data.variantType === 'Variant1') {
            setTableVariant(data.subvariants)
        } else {
            setTableSubVariant(data.subvariants)
        }
        setWholesale(data.isWholesales)
        setWholesaleList(data.wholesales)
        setLatestUpdate(`Intial Data ${moment().format('x')}`)
    }

    React.useEffect(() => {
        function Initial() {
            if (param.type === 'edit') {
                InitialData()
            }
        }

        Initial()
    }, [init])

    React.useEffect(() => {
        setTimeout(() => {
            InputUpdates()
        }, 1000)
    }, [LatestUpdate])

    return (
        <Stack direction={'column'} gap={3} alignItems={'flex-start'} width={'100%'}>
            <Typography variant="body1" fontWeight={'600'} color={'#000'}>Informasi Penjualan:</Typography>
            {
                VariantList.map((item: any, index: number) => (
                    <Stack direction={'row'} key={index} gap={2} width={'100%'} alignItems={'flex-start'}>
                        <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>{`Variasi ${item.id}:`}</Typography>
                        <Stack direction={'row'} alignItems={'flex-start'} gap={1} width={"80%"}>
                            <Typography width={'10%'} variant="body1" color={Colors.secondaryDark}>Pilihan:</Typography>
                            <Grid container spacing={2}>
                                {
                                    item.subvariants.map((value: any, idx: number) => (
                                        <Grid item md={6} key={idx}>
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                <TextField
                                                    type="text"
                                                    placeholder="Contoh: Merah, Kuning, Dll"
                                                    size="small"
                                                    sx={{ bgcolor: isShopee ? '#e6e6e6' : "white", width: '100%' }}
                                                    value={value.name}
                                                    disabled={isShopee}
                                                    onChange={(e) => InputSubVariant(e, item.id, value.id)}
                                                    InputProps={{
                                                        sx: { borderRadius: 1 },
                                                        endAdornment: (
                                                            isShopee ? null :
                                                                <>
                                                                    {
                                                                        item.subvariants.length <= 1 && VariantList.length === 2 && item === VariantList[0] ? null :
                                                                            <IconButton disabled={isShopee} onClick={() => DeleteSubVariant(item.id, value.id, idx)}>
                                                                                <Icon fontSize={'medium'} sx={{ color: Colors.secondary }}>delete</Icon>
                                                                            </IconButton>
                                                                    }
                                                                </>
                                                        )
                                                    }}
                                                />
                                                {
                                                    item.subvariants.length > 3 || isShopee ?
                                                        null :
                                                        <>
                                                            {
                                                                idx === item.subvariants.length - 1 && idx <= 3 ?
                                                                    <Button onClick={() => AddSubVariant(item.id, index, idx)} variant={'outlined'} color={'secondary'}>
                                                                        <Icon fontSize={'medium'}>add</Icon>
                                                                    </Button>
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                }
                                            </Stack>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Stack>
                    </Stack>
                ))
            }
            {
                VariantList.length === 2 || isShopee ? null :
                    <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                        <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>{VariantList.length === 0 ? 'Variasi:' : `Variasi ${VariantList.length + 1}:`}</Typography>
                        <Button
                            onClick={AddVariant}
                            variant="outlined"
                            color={"secondary"}
                            startIcon={<Icon fontSize={"medium"} sx={{ color: Colors.primary }}>add</Icon>}
                            sx={{ borderColor: Colors.primary }}
                        >Aktifkan Variasi</Button>
                    </Stack>
            }
            {
                VariantList.length > 0 ?
                    <Stack direction={'row'} gap={2} width={'100%'} alignItems={'flex-start'}>
                        <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Daftar Variasi:</Typography>
                        <Stack direction={'column'} gap={2} width={'80%'} alignItems={'flex-start'}>
                            <Stack direction={'row'} width={'100%'} alignItems={'center'} gap={1} justifyContent={'space-between'}>
                                <TextField
                                    type="number"
                                    placeholder="Masukkan Harga"
                                    size="small"
                                    sx={{ bgcolor: "white", width: '33%' }}
                                    value={WholeSubVariant.price}
                                    onChange={(e) => setWholeSubVariant({ ...WholeSubVariant, price: e.target.value })}
                                    InputProps={{
                                        sx: { borderRadius: 1 },
                                        startAdornment: (
                                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                                <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                                <div></div>
                                            </Stack>
                                        )
                                    }}
                                />
                                <TextField
                                    type="number"
                                    placeholder="Stok"
                                    size="small"
                                    value={WholeSubVariant.quantity}
                                    onChange={(e) => setWholeSubVariant({ ...WholeSubVariant, quantity: e.target.value })}
                                    sx={{ bgcolor: "white", width: '33%' }}
                                    InputProps={{ sx: { borderRadius: 1 } }}
                                />
                                <Button
                                    onClick={ApplyWholeSubVariant}
                                    variant="contained"
                                    color={"secondary"}
                                    disabled={WholeSubVariant.price.length === 0 || WholeSubVariant.quantity.length === 0}
                                    sx={{ backgroundColor: Colors.primary, width: '33%' }}
                                >Terapkan ke Semua</Button>
                            </Stack>
                            <TableContainer>
                                <Table sx={{ width: '100%', borderLeft: '1px solid #8e8e8e50', borderRight: '1px solid #8e8e8e50' }}>
                                    <TableHead sx={{ backgroundColor: '#e6e6e6' }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '25%' }} align="center">Variasi 1</TableCell>
                                            {
                                                VariantList.length === 1 ?
                                                    null
                                                    :
                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '25%' }} align="center">Variasi 2</TableCell>
                                            }
                                            <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">*Harga</TableCell>
                                            <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">*Stok</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {
                                        VariantList.length < 2 ?
                                            <TableBody>
                                                {
                                                    VariantList[0].subvariants.map((item: { id: number, name: string, image: string }, index: number) => (
                                                        <TableRow key={index}>
                                                            <TableCell align="center" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                <Stack direction={'column'} alignItems={'center'} gap={1}>
                                                                    <Typography variant="body1" color="#000">
                                                                        {item.name.length === 0 ? <small><i style={{ color: Colors.secondary }}>Nama Variasi</i></small> : item.name}
                                                                    </Typography>
                                                                    {
                                                                        item.image.length === 0 ?
                                                                            <Button
                                                                                variant={'outlined'}
                                                                                color={'secondary'}
                                                                                component="label"
                                                                            >
                                                                                <Icon fontSize={'large'}>add_photo_alternate</Icon>
                                                                                <input
                                                                                    type="file"
                                                                                    hidden
                                                                                    accept='image/*'
                                                                                    onChange={({ target }: any) => AddImages(target.files[0], item.id)}
                                                                                />
                                                                            </Button>
                                                                            :
                                                                            <div
                                                                                style={{ position: 'relative', width: 80, height: 80 }}
                                                                                onMouseEnter={() => setSelectedImage(item.id)}
                                                                                onMouseLeave={() => setSelectedImage(null)}
                                                                            >
                                                                                <img src={item.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                                {
                                                                                    SelectedImage === item.id ?
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                                right: 0,
                                                                                                bottom: 0,
                                                                                                backgroundColor: '#00000050',
                                                                                                ...CENTER,
                                                                                                zIndex: 1,
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            onClick={() => RemoveImage(item.id)}
                                                                                        >
                                                                                            <Icon fontSize={'large'} sx={{ color: '#fff' }}>delete</Icon>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                    }
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="0"
                                                                    size="small"
                                                                    sx={{ bgcolor: "white", width: '100%' }}
                                                                    value={TableVariant[index].price}
                                                                    onChange={(e) => InputTableVariant(e, index, 'price')}
                                                                    InputProps={{
                                                                        sx: { borderRadius: 1 },
                                                                        startAdornment: (
                                                                            <Typography variant={'body1'} color={Colors.secondary} marginRight={1}>Rp</Typography>
                                                                        )
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="0"
                                                                    size="small"
                                                                    value={TableVariant[index].quantity}
                                                                    onChange={(e) => InputTableVariant(e, index, 'quantity')}
                                                                    sx={{ bgcolor: "white", width: '100%' }}
                                                                    InputProps={{ sx: { borderRadius: 1 } }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                            :
                                            <>
                                                {
                                                    VariantList[0].subvariants.map((item: { id: number, name: string, image: string }, index1: number) => (
                                                        <TableBody key={index1}>
                                                            <TableRow>
                                                                <TableCell align="center" rowSpan={VariantList[1].subvariants.length + 1} sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                    <Stack direction={'column'} alignItems={'center'} gap={1}>
                                                                        <Typography variant="body1" color="#000">
                                                                            {item.name.length === 0 ? <small><i style={{ color: Colors.secondary }}>Nama Variasi</i></small> : item.name}
                                                                        </Typography>
                                                                        {
                                                                            item.image.length === 0 ?
                                                                                <Button
                                                                                    variant={'outlined'}
                                                                                    color={'secondary'}
                                                                                    component="label"
                                                                                >
                                                                                    <Icon fontSize={'large'}>add_photo_alternate</Icon>
                                                                                    <input
                                                                                        type="file"
                                                                                        hidden
                                                                                        accept='image/*'
                                                                                        onChange={({ target }: any) => AddImages(target.files[0], item.id)}
                                                                                    />
                                                                                </Button>
                                                                                :
                                                                                <div
                                                                                    style={{ position: 'relative', width: 80, height: 80 }}
                                                                                    onMouseEnter={() => setSelectedImage(item.id)}
                                                                                    onMouseLeave={() => setSelectedImage(null)}
                                                                                >
                                                                                    <img src={item.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                                    {
                                                                                        SelectedImage === item.id ?
                                                                                            <div
                                                                                                style={{
                                                                                                    position: 'absolute',
                                                                                                    top: 0,
                                                                                                    left: 0,
                                                                                                    right: 0,
                                                                                                    bottom: 0,
                                                                                                    backgroundColor: '#00000050',
                                                                                                    ...CENTER,
                                                                                                    zIndex: 1,
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={() => RemoveImage(item.id)}
                                                                                            >
                                                                                                <Icon fontSize={'large'} sx={{ color: '#fff' }}>delete</Icon>
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                VariantList.length < 2 ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        {
                                                                            VariantList[1].subvariants.map((value: { id: number, name: string }, index2: number) => (
                                                                                <TableRow key={index2}>
                                                                                    <TableCell align="center" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                                        <Typography variant="body1" color="#000">
                                                                                            {value.name.length === 0 ? <small><i style={{ color: Colors.secondary }}>Nama Sub Variasi</i></small> : value.name}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                                        <TextField
                                                                                            type="number"
                                                                                            placeholder="0"
                                                                                            size="small"
                                                                                            value={
                                                                                                TableSubVariant.find((item) => item.variant_index[0] === index1 && item.variant_index[1] === index2) === undefined ? ''
                                                                                                    :
                                                                                                    TableSubVariant.find((item) => item.variant_index[0] === index1 && item.variant_index[1] === index2).price
                                                                                            }
                                                                                            onChange={(e) => InputTableSubVariant(e, index1, index2, 'price')}
                                                                                            sx={{ bgcolor: "white", width: '100%' }}
                                                                                            InputProps={{
                                                                                                sx: { borderRadius: 1 },
                                                                                                startAdornment: (
                                                                                                    <Typography variant={'body1'} color={Colors.secondary} marginRight={1}>Rp</Typography>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                                        <TextField
                                                                                            type="number"
                                                                                            placeholder="0"
                                                                                            size="small"
                                                                                            value={
                                                                                                TableSubVariant.find((item) => item.variant_index[0] === index1 && item.variant_index[1] === index2) === undefined ? ''
                                                                                                    :
                                                                                                    TableSubVariant.find((item) => item.variant_index[0] === index1 && item.variant_index[1] === index2).quantity
                                                                                            }
                                                                                            onChange={(e) => InputTableSubVariant(e, index1, index2, 'quantity')}
                                                                                            sx={{ bgcolor: "white", width: '100%' }}
                                                                                            InputProps={{ sx: { borderRadius: 1 } }}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        }
                                                                    </>
                                                            }
                                                        </TableBody>
                                                    ))
                                                }
                                            </>
                                    }
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Stack>
                    : null
            }
            {
                VariantList.length > 0 ? null :
                    <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                        <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>*Harga:</Typography>
                        <TextField
                            type="number"
                            placeholder="Masukkan Harga"
                            size="small"
                            sx={{ bgcolor: "white", width: '50%' }}
                            value={GlobalPrice}
                            onChange={(text) => {
                                setGlobalPrice(text.target.value)
                                setLatestUpdate(`InputPrice ${moment().format('x')}`)
                            }}
                            InputProps={{
                                sx: { borderRadius: 1 },
                                startAdornment: (
                                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                                        <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                        <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                        <div></div>
                                    </Stack>
                                )
                            }}
                        />
                    </Stack>
            }
            {
                VariantList.length > 0 ? null :
                    <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                        <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>*Stok:</Typography>
                        <TextField
                            value={GlobalQuantity}
                            onChange={(text) => {
                                setGlobalQuantity(text.target.value)
                                setLatestUpdate(`InputQuantity ${moment().format('x')}`)
                            }}
                            type="number"
                            placeholder="Stok"
                            size="small"
                            sx={{ bgcolor: "white", width: '50%' }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                        />
                    </Stack>
            }

            <Stack direction={'row'} gap={2} width={'100%'} alignItems={isWholesale ? 'flex-start' : 'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Harga Grosir:</Typography>
                {
                    VariantList.length < 2 || VariantList[1].subvariants.length === 1 || CheckPrice() ?
                        <>
                            {
                                !isWholesale ?
                                    <Button
                                        variant="outlined"
                                        color={"secondary"}
                                        startIcon={<Icon fontSize={"medium"} sx={{ color: Colors.primary }}>add</Icon>}
                                        sx={{ borderColor: Colors.primary }}
                                        onClick={AddWholesale}
                                    >Tambah Harga Grosir</Button>
                                    :
                                    <div style={{ width: '80%' }}>
                                        <TableContainer>
                                            <Table sx={{ width: '100%', borderLeft: '1px solid #8e8e8e50', borderRight: '1px solid #8e8e8e50' }}>
                                                <TableHead sx={{ backgroundColor: '#e6e6e6' }}>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '20%' }} align="left">No.</TableCell>
                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Jumlah Min.</TableCell>
                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Jumlah Max.</TableCell>
                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Harga Satuan</TableCell>
                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Aksi</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        WholesaleList.map((item: { id: number, min_quantity: string, max_quantity: string, price: string }, index: number) => (
                                                            <TableRow key={index}>
                                                                <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                    <Typography variant="body1" color="#000">Harga Grosir {index + 1}</Typography>
                                                                </TableCell>
                                                                <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        placeholder="0"
                                                                        size="small"
                                                                        disabled={index !== 0}
                                                                        value={item.min_quantity}
                                                                        onChange={(e) => InputWholeSale(e, item.id, 'min_quantity', index)}
                                                                        sx={{ bgcolor: index === 0 ? "white" : '#e6e6e6', width: '100%' }}
                                                                        InputProps={{
                                                                            sx: { borderRadius: 1 },
                                                                            endAdornment: (
                                                                                index === 0 ? <Tooltip
                                                                                    PopperProps={{ sx: { maxWidth: 200, textAlign: 'center' } }}
                                                                                    title={`Jumlah minimal untuk grosir harus lebih dari 1`}
                                                                                    arrow
                                                                                >
                                                                                    <Icon fontSize={'small'} sx={{ color: Colors.secondary, cursor: 'pointer' }}>info</Icon>
                                                                                </Tooltip>
                                                                                    : null
                                                                            )
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        placeholder="0"
                                                                        size="small"
                                                                        value={item.max_quantity}
                                                                        onChange={(e) => InputWholeSale(e, item.id, 'max_quantity', index)}
                                                                        sx={{ bgcolor: "white", width: '100%' }}
                                                                        InputProps={{
                                                                            sx: { borderRadius: 1 },
                                                                            endAdornment: (
                                                                                <Tooltip
                                                                                    PopperProps={{ sx: { maxWidth: 200, textAlign: 'center' } }}
                                                                                    title={`Jumlah produk maksimal harus kurang dari jumlah produk minimal`}
                                                                                    arrow
                                                                                >
                                                                                    <Icon fontSize={'small'} sx={{ color: Colors.secondary, cursor: 'pointer' }}>info</Icon>
                                                                                </Tooltip>
                                                                            )
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        placeholder="0"
                                                                        size="small"
                                                                        value={item.price}
                                                                        onChange={(e) => InputWholeSale(e, item.id, 'price', index)}
                                                                        sx={{ bgcolor: "white", width: '100%' }}
                                                                        InputProps={{
                                                                            sx: { borderRadius: 1 },
                                                                            startAdornment: (
                                                                                <Typography variant={'body1'} color={Colors.secondary} marginRight={1}>Rp</Typography>
                                                                            ),
                                                                            endAdornment: (
                                                                                <Tooltip
                                                                                    PopperProps={{ sx: { maxWidth: 200, textAlign: 'center' } }}
                                                                                    title={`Harga satuan harus lebih rendah dari harga awal`}
                                                                                    arrow
                                                                                >
                                                                                    <Icon fontSize={'small'} sx={{ color: Colors.secondary, cursor: 'pointer' }}>info</Icon>
                                                                                </Tooltip>
                                                                            )
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align={'center'}>
                                                                    <IconButton onClick={() => DeleteWholesale(item.id)}>
                                                                        <Icon fontSize={'medium'} sx={{ color: Colors.secondary }}>delete</Icon>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    <TableRow>
                                                        <TableCell align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                            <Typography variant="body1" color="#000">Harga Grosir {WholesaleList.length + 1}</Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={3} align="left" sx={{ borderRight: '1px solid #8e8e8e50' }}>
                                                            <Button
                                                                variant="outlined"
                                                                color={"secondary"}
                                                                startIcon={<Icon fontSize={"medium"} sx={{ color: Colors.primary }}>add</Icon>}
                                                                sx={{ borderColor: Colors.primary }}
                                                                onClick={AddWholesale}
                                                            >Tambah Harga Grosir</Button>
                                                        </TableCell>
                                                        <TableCell align={'center'}>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                            }
                        </>
                        :
                        <Typography color={Colors.secondary} variant={'body1'}>Harga grosir hanya tersedia untuk semua variasi yang memiliki harga yang sama.</Typography>
                }
            </Stack>
        </Stack >
    )
}