import { Avatar, Box, Icon, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react'
import { CENTER, Colors } from '../../utils/colors';
import { styled, useTheme } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { ListData } from './data';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';
import { Images } from '../../assets/images';

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelIcon: any;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps: any = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingY: 1.5,
                        paddingX: 0.5,
                        pr: 0,
                    }}
                >
                    {
                        LabelIcon.length === 0 ?
                            null
                            :
                            <Icon fontSize={'medium'} sx={{ mr: 1, color: 'inherit' }}>{LabelIcon}</Icon>
                    }
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

const SideBar = (param: { nodeId: string }) => {
    const navigate = useNavigate()
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)

    const Routing = (link: string) => {
        if (link !== '') {
            navigate(link)
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 10, position: 'sticky', top: '5%', marginBottom: 50 }}>
            <Stack sx={{ width: '100%', padding: '10% 0' }} direction={'column'} gap={0.5} alignItems={'center'}>
                <div style={{ border: `3px dashed ${Colors.primary}`, borderRadius: '50%', padding: 5, width: '30%', marginBottom: 20 }}>
                    <Avatar sx={{ width: '100%', height: 'auto' }} src={Images.noImageProfile}></Avatar>
                </div>
                <h3 style={{ color: '#000', fontWeight: '500', margin: 0 }}>{ProfileRedux.name}</h3>
                <small style={{ color: Colors.secondary }}>{ProfileRedux.role.name}</small>
            </Stack>
            <TreeView
                aria-label="gmail"
                defaultCollapseIcon={<Icon sx={{ color: Colors.secondary }} fontSize={'small'}>arrow_drop_down</Icon>}
                defaultExpandIcon={<Icon sx={{ color: Colors.secondary }} fontSize={'small'}>arrow_right</Icon>}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                defaultExpanded={[param.nodeId]}
            >
                {
                    ListData.map((item) => (
                        <StyledTreeItem
                            key={item.node}
                            nodeId={item.node}
                            labelText={item.label}
                            labelIcon={item.icon}
                            onClick={() => Routing(item.link)}
                        >
                            {
                                item.child.length !== 0 ?
                                    <>
                                        {
                                            item.child.map((item) => (
                                                <StyledTreeItem
                                                    key={item.node}
                                                    nodeId={item.node}
                                                    labelText={item.label}
                                                    labelIcon={''}
                                                    onClick={() => Routing(item.link)}
                                                />
                                            ))
                                        }
                                    </>
                                    :
                                    null
                            }
                        </StyledTreeItem>
                    ))
                }
            </TreeView>
            <Toolbar sx={{ backgroundColor: '#fff', ...CENTER, borderRadius: '10px' }}>
                <span style={{ color: Colors.secondaryDark }}>©️ {moment().format('YYYY')}, Angeloskin.</span>
            </Toolbar>
        </div>
    )
}

export default SideBar;