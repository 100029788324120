import * as React from 'react';
import { Dialog, DialogContent, Slide } from "@mui/material"
import secureLocalStorage from "react-secure-storage"
import { toast } from "react-toastify"
import { HTTPDeleteFAQ } from "../../apis/faq"
import { TransitionProps } from '@mui/material/transitions';
import { DeleteContent } from '../utils/deleteContent';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FAQDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteArticle = async () => {
        setLoading(true)
        try {
            await HTTPDeleteFAQ({ faq_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <DeleteContent
                    isLoading={isLoading}
                    onCancel={param.onClose}
                    onConfirm={DeleteArticle}
                    text={'pertanyaan ' + param.item.question_id}
                />
            </DialogContent>
        </Dialog>
    )
}
