import { AxiosNormal } from "../utils/interceptors";
const url = "footer/resellers";

export function HTTPGetReseller(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateReseller(param: {
  token: string;
  button_name: string;
  whatsapp_link: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/update`, {
        token: param.token,
        button_name: param.button_name,
        whatsapp_link: param.whatsapp_link,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
