import axios from "axios";
import { AxiosNormal } from "../utils/interceptors";
const url = "users";

export function HTTPGetUsers(param: { token: string, page: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/get?page=${param.page}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetUserByID(param: {
  user_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/show/${param.user_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddUser(param: {
  token: string;
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  role_id: string;
  permissions: string[];
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
        name: param.name,
        email: param.email,
        password: param.password,
        confirm_password: param.confirm_password,
        role_id: param.role_id,
        permissions: param.permissions,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateUser(param: {
  token: string;
  name: string;
  email: string;
  role_id: string;
  permissions: string[];
  user_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().put(`${url}/${param.user_id}`, {
        token: param.token,
        name: param.name,
        email: param.email,
        role_id: param.role_id,
        permissions: param.permissions,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteUser(param: {
  user_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/delete/${param.user_id}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
