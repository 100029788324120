import { Button, Icon, Stack, Toolbar, Typography, TextField, CircularProgress, IconButton } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ProductInfo } from '../../components/products/product/productInfo';
import { ProductSpec } from '../../components/products/product/productSpec';
import { ProductVariant } from '../../components/products/product/productVariant';
import { ProductShipping } from '../../components/products/product/productShipping';
import { toast } from 'react-toastify';
import { HTTPAddProducts, HTTPGetProductByID, HTTPUpdateProducts } from '../../apis/products';
import { ThreeDots } from 'react-loader-spinner';
import { Images } from '../../assets/images';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductMeFormScreen = () => {
    const navigate = useNavigate()
    const { type } = useParams()
    const query = useQuery()
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [EditLoading, setEditLoading] = React.useState(false)
    const [InfoData, setInfoData] = React.useState<any>({})
    const [SpecData, setSpecData] = React.useState<any>({})
    const [VariantData, setVariantData] = React.useState<any>({})
    const [ShippingData, setShippingData] = React.useState<any>({})

    const [InfoEditData, setInfoEditData] = React.useState<any>({})
    const [SpecEditData, setSpecEditData] = React.useState<any>({})
    const [VariantEditData, setVariantEditData] = React.useState<any>({})
    const [ShippingEditData, setShippingEditData] = React.useState<any>({})

    const onSubmit = async (data: any) => {
        setLoading(true)
        try {
            const FinalData = {
                product_category_id: data.product_category_id,
                product_brand_id: data.product_brand_id,
                sku: data.sku,
                name_id: data.name_id,
                name_en: data.name_en,
                description_id: data.description_id,
                description_en: data.description_en,
                short_desc_id: data.short_desc_id,
                short_desc_en: data.short_desc_en,
                is_new: data.is_new,
                is_pre_order: data.is_pre_order,
                pre_order_days: data.pre_order_days,
                price: data.price,
                quantity: data.quantity,
                weight: data.weight,
                dimension_width: data.dimension_width,
                dimension_height: data.dimension_height,
                dimension_length: data.dimension_length,
                variants: data.variants,
                wholesales: data.wholesales,
                images: data.images
            }
            if (type === 'add') {
                await HTTPAddProducts(FinalData)
                toast.success('Berhasil Menambahkan Data Produk!')
            } else {
                await HTTPUpdateProducts({ ...FinalData, product_id: query.get('pid') as string })
                toast.success('Berhasil Mengubah Data Produk!')
            }
            navigate(-1)
            setLoading(false)
        } catch (error: any) {
            setLoading(false)

            toast.error('Terjadi Kesalahan!')
            if (error.data.code === 400) {
                let keys = Object.keys(error.data.stack)
                for (let index = 0; index < keys.length; index++) {
                    const item = keys[index];
                    toast.error(error.data.stack[item][0])
                }
            }
        }
    }

    const onManipulations = async () => {
        let currentData = { ...InfoData, ...SpecData, ...ShippingData, ...VariantData }
        // ===================================================================== //
        let phase1 = {
            ...currentData,
            pre_order_days: isNaN(parseInt(currentData.pre_order_days)) || parseInt(currentData.pre_order_days) < 2 ? 2 : parseInt(currentData.pre_order_days),
            price: isNaN(parseInt(currentData.price)) ? 0 : parseInt(currentData.price),
            quantity: isNaN(parseInt(currentData.quantity)) ? 0 : parseInt(currentData.quantity),
            weight: isNaN(parseInt(currentData.weight)) ? 0 : parseInt(currentData.weight),
            dimension_width: isNaN(parseInt(currentData.dimension_width)) ? 0 : parseInt(currentData.dimension_width),
            dimension_height: isNaN(parseInt(currentData.dimension_height)) ? 0 : parseInt(currentData.dimension_height),
            dimension_length: isNaN(parseInt(currentData.dimension_length)) ? 0 : parseInt(currentData.dimension_length),
            is_new: currentData.is_new === 'baru',
            is_pre_order: currentData.is_pre_order === 'yes',
        }
        // ===================================================================== //
        let phase2: any = { ...phase1 }
        const Variants = phase1.variants.variant_1
        const SubVariants = phase1.variants.variant_2
        if (Variants.length > 0) {
            const newVariant1 = Variants.map((item: any) => {
                return {
                    id: item.id.toString(),
                    image: item.image,
                    name_id: item.name,
                    name_en: item.name
                }
            })
            const newVariant2 = Variants.length === 1 ? [] : SubVariants.map((item: any) => {
                return {
                    id: item.id.toString(),
                    name_id: item.name,
                    name_en: item.name
                }
            })
            phase2 = {
                ...phase1,
                variants: {
                    ...phase1.variants,
                    variant_1: newVariant1,
                    variant_2: newVariant2
                }
            }
        }
        // ===================================================================== //
        let phase3: any = { ...phase2 }
        const Pricing = phase2.variants.price_and_quantity
        if (Pricing.length === 0) {
            const newPricing = Pricing.map((item: any) => {
                return {
                    price: isNaN(parseInt(item.price)) ? 0 : parseInt(item.price),
                    quantity: isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity),
                    variant_index: item.variant_index,
                }
            })
            phase3 = {
                ...phase2,
                variants: {
                    ...phase2.variants,
                    price_and_quantity: newPricing
                }
            }
        }
        // ===================================================================== //
        let phase4: any = { ...phase3 }
        if (phase3.wholesales.length === 0) {
            const newWholeSale = phase3.wholesales.map((item: any) => {
                delete item.id
                return {
                    price: isNaN(parseInt(item.price)) ? 0 : parseInt(item.price),
                    max_quantity: isNaN(parseInt(item.max_quantity)) ? 0 : parseInt(item.max_quantity),
                    min_quantity: isNaN(parseInt(item.min_quantity)) ? 0 : parseInt(item.min_quantity),
                }
            })
            phase4 = {
                ...phase3,
                wholesales: newWholeSale
            }
        }
        // ===================================================================== //
        await onSubmit(phase4)
    }

    const GetProductDetail = async () => {
        setEditLoading(true)
        try {
            const result = await HTTPGetProductByID({ product_id: query.get('pid') as string })
            const data = result.data.data
            const Info = {
                images: data.galleries.length === 0 ? [] : data.galleries.filter((item: any) => item.type !== 'vid').map((item: any) => (item.image_path)),
                videos: data.galleries.find((item: any) => item.type === 'vid') ? data.galleries.find((item: any) => item.type === 'vid').image_path : '',
                name: data.name_id,
                category: data.category === null ? '' : data.category.id,
                description: data.description_id
            }
            setInfoEditData(Info)

            const Spec = {
                brand: data.brand === null ? '' : data.brand.id
            }
            setSpecEditData(Spec)

            const variant1 = {
                id: 1,
                label: 'Variant 1',
                subvariants: data.variants.length === 0 ? [] : data.variants.map((item: any) => ({
                    id: item.id,
                    name: item.name_id,
                    image: item.image === null ? '' : item.image
                }))
            }

            const variant2 = {
                id: 2,
                label: 'Variant 2',
                subvariants: data.sub_variants.length === 0 ? [] : data.sub_variants.map((item: any) => ({
                    id: item.id,
                    name: item.name_id
                }))
            }

            let variantType = ''
            let subvariants = []
            if (data.sub_variants.length === 0) {
                const result = data.variant_prices.length === 0 ? [] : data.variant_prices.map((item: any, index: number) => {
                    return ({
                        id: item.id,
                        variant_index: [index],
                        price: item.price.toString(),
                        quantity: item.quantity.toString()
                    })
                })
                subvariants = result
                variantType = 'Variant1'
            } else {
                let result: any[] = []
                let count = 0
                for (let j = 0; j < data.sub_variants.length; j++) {
                    for (let i = 0; i < data.variants.length; i++) {
                        const item = data.variant_prices[count];
                        result.push({
                            variant_index: [i, j],
                            price: item.price.toString(),
                            quantity: item.quantity.toString()
                        })
                        count += 1
                    }
                }
                subvariants = result
                variantType = 'Variant2'
            }

            const wholesales = data.wholesales.length === 0 ? [] : data.wholesales.map((item: any) => ({
                id: Math.floor(Math.random() * 1000) + 100,
                min_quantity: item.quantity.min,
                max_quantity: item.quantity.max,
                price: item.price
            }))

            const Variant = {
                quantity: data.quantity.toString(),
                price: data.price.toString(),
                variants: variant1.subvariants.length === 0 ? []
                    : variant2.subvariants.length === 0 ? [variant1]
                        : [variant1, variant2],
                subvariants: subvariants,
                variantType: variantType,
                isShopee: data.is_shopee === 1,
                wholesales: wholesales,
                isWholesales: wholesales.length > 0
            }
            setVariantEditData(Variant)

            const Shipping = {
                weight: data.weight.toString(),
                length: data.dimension['length'].toString(),
                width: data.dimension.width.toString(),
                height: data.dimension.height.toString(),
                isPreorder: data.is_pre_order === 1 ? 'yes' : 'no',
                condition: data.is_new === 1 ? 'baru' : 'second',
                sku: data.sku.toString(),
                preOrderDays: data.pre_order_days.toString()
            }
            setShippingEditData(Shipping)
            setEditLoading(false)
        } catch (error) {
            setEditLoading(false)

            toast.error('Terjadi Kesalahan! Ulangi Beberapa Saat Lagi')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            if (type === 'edit') {
                await GetProductDetail()
            }
        }
        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="50" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>{type === 'add' ? 'Tambah Produk Saya' : 'Edit Produk Saya'}</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tentang Angelo / Produk</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        {
                            EditLoading ?
                                <div style={{ width: '100%', overflow: isMobile ? 'auto' : 'none', backgroundColor: '#fff', padding: '10% 0', ...CENTER }}>
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color={Colors.primary}
                                        ariaLabel="three-dots-loading"
                                        visible={true}
                                    />
                                </div>
                                :
                                <Stack direction={'column'} gap={3}>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'}>
                                            <Button
                                                variant={'outlined'}
                                                sx={{ borderColor: Colors.secondary }}
                                                color={'secondary'}
                                                onClick={() => navigate(-1)}
                                                startIcon={<Icon fontSize={'small'} sx={{ color: Colors.secondaryDark }}>arrow_back</Icon>}
                                            >
                                                <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                                            </Button>
                                            <Typography variant="body1" fontWeight={'600'} color={Colors.secondaryDark}>{type === 'add' ? 'FORM TAMBAH PRODUK' : 'FORM UBAH PRODUK'}</Typography>
                                        </Stack>
                                        <Toolbar />
                                        <ProductInfo
                                            initialData={InfoEditData}
                                            type={type as string}
                                            finalData={(data: any) => setInfoData(data)}
                                        />
                                    </div>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <ProductSpec
                                            initialData={SpecEditData}
                                            type={type as string}
                                            finalData={(data: any) => setSpecData(data)}
                                        />
                                    </div>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <ProductVariant
                                            initialData={VariantEditData}
                                            type={type as string}
                                            finalData={(data: any) => setVariantData(data)}
                                        />
                                    </div>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <ProductShipping
                                            initialData={ShippingEditData}
                                            type={type as string}
                                            finalData={(data: any) => setShippingData(data)}
                                        />
                                        <Toolbar />
                                        <div style={{ width: '100%', ...CENTER }}>
                                            <Button
                                                onClick={onManipulations}
                                                color={"secondary"}
                                                variant={'contained'}
                                                disabled={isLoading}
                                                sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                                                startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                                            >Simpan</Button>
                                        </div>
                                    </div>
                                </Stack>
                        }
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default ProductMeFormScreen;