export const ListData = [
  // {
  //   icon: "airplay",
  //   node: "10",
  //   label: "Dashboard",
  //   link: "/dashboard",
  //   child: [],
  // },
  {
    icon: "inventory",
    node: "20",
    label: "Produk",
    link: "",
    child: [
      {
        node: "21",
        label: "Brand",
        link: "/product/brand",
      },
      {
        node: "22",
        label: "Kategori",
        link: "/product/category",
      },
      {
        node: "23",
        label: "Produk Saya",
        link: "/product/list",
      },
    ],
  },
  {
    icon: "receipt",
    node: "70",
    label: "Pesanan",
    link: "",
    child: [
      {
        node: "71",
        label: "Pesanan Saya",
        link: "/order/list",
      },
      {
        node: "72",
        label: "Pengaturan Pengiriman",
        link: "/order/setting",
      },
      {
        node: "73",
        label: "Ongkir & Pembayaran",
        link: "/order/shipping",
      },
    ],
  },
  {
    icon: "discount",
    node: "30",
    label: "Pusat Promosi",
    link: "",
    child: [
      {
        node: "31",
        label: "Voucher Toko",
        link: "/promotion/voucher",
      },
      {
        node: "32",
        label: "Banner Promosi",
        link: "/promotion/banner",
      },
      // {
      //     node: '9',
      //     label: 'Kategori Pilihan',
      //     link: '/promotion/category',
      // },
    ],
  },
  {
    icon: "person",
    node: "40",
    label: "Pengguna",
    link: "",
    child: [
      {
        node: "41",
        label: "Admin",
        link: "/user/admin",
      },
      {
        node: "42",
        label: "Pelanggan",
        link: "/user/customer",
      },
    ],
  },
  {
    icon: "description",
    node: "50",
    label: "Tentang Angelo",
    link: "",
    child: [
      {
        node: "51",
        label: "Tentang Kami",
        link: "/about/us",
      },
      {
        node: "52",
        label: "Sertifikat",
        link: "/about/certificate",
      },
      {
        node: "53",
        label: "FAQ",
        link: "/about/faq",
      },
      {
        node: "54",
        label: "Kebijakan Privasi",
        link: "/about/privacy-policy",
      },
      {
        node: "55",
        label: "Artikel",
        link: "/about/article",
      },
    ],
  },
  {
    icon: "view_list",
    node: "60",
    label: "Footer",
    link: "/footer",
    child: [],
  },
  {
    icon: "settings",
    node: "80",
    label: "Pengaturan",
    link: "/settings",
    child: [],
  },
];
