import { AxiosNormal } from "../utils/interceptors";
const url = "products";

export function HTTPGetProducts(param: {
  page: number;
  keywords?: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}`, {
        params: {
          page: param.page,
          keyword: param.keywords,
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetProductByID(param: {
  product_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}/${param.product_id}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddBestSellers(param: {
  product_id: string;
  is_best_seller: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/set_best_seller`, {
        product_id: param.product_id,
        is_best_seller: param.is_best_seller,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddProducts(param: {
  product_category_id: string;
  product_brand_id: string;
  sku: string;
  name_id: string;
  name_en: string;
  description_id: string;
  description_en: string;
  short_desc_id: string;
  short_desc_en: string;
  is_new: boolean;
  is_pre_order: boolean;
  pre_order_days: number;
  price: number;
  quantity: number;
  weight: number;
  dimension_width: number;
  dimension_height: number;
  dimension_length: number;
  variants: any;
  wholesales: any[];
  images: any[];
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        product_category_id: param.product_category_id,
        product_brand_id: param.product_brand_id,
        sku: param.sku,
        name_id: param.name_id,
        name_en: param.name_en,
        description_id: param.description_id,
        description_en: param.description_en,
        short_desc_id: param.short_desc_id,
        short_desc_en: param.short_desc_en,
        is_new: param.is_new,
        is_pre_order: param.is_pre_order,
        pre_order_days: param.pre_order_days,
        price: param.price,
        quantity: param.quantity,
        weight: param.weight,
        dimension_width: param.dimension_width,
        dimension_height: param.dimension_height,
        dimension_length: param.dimension_length,
        variants: param.variants,
        wholesales: param.wholesales,
        images: param.images,
        is_best_seller: false,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateProducts(param: {
  product_id: string;
  product_category_id: string;
  product_brand_id: string;
  sku: string;
  name_id: string;
  name_en: string;
  description_id: string;
  description_en: string;
  short_desc_id: string;
  short_desc_en: string;
  is_new: boolean;
  is_pre_order: boolean;
  pre_order_days: number;
  price: number;
  quantity: number;
  weight: number;
  dimension_width: number;
  dimension_height: number;
  dimension_length: number;
  variants: any;
  wholesales: any[];
  images: any[];
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().put(`${url}/${param.product_id}`, {
        product_category_id: param.product_category_id,
        product_brand_id: param.product_brand_id,
        sku: param.sku,
        name_id: param.name_id,
        name_en: param.name_en,
        description_id: param.description_id,
        description_en: param.description_en,
        short_desc_id: param.short_desc_id,
        short_desc_en: param.short_desc_en,
        is_new: param.is_new,
        is_pre_order: param.is_pre_order,
        pre_order_days: param.pre_order_days,
        price: param.price,
        quantity: param.quantity,
        weight: param.weight,
        dimension_width: param.dimension_width,
        dimension_height: param.dimension_height,
        dimension_length: param.dimension_length,
        variants: param.variants,
        wholesales: param.wholesales,
        images: param.images,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteProduct(param: { product_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().delete(
        `${url}/${param.product_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
