import { Stack, Toolbar } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER } from '../../utils/colors';
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import { isMobile } from 'react-device-detect';

const CalendarScreen = (props: any) => {
    // const [modal, setModal] = React.useState(false)
    // const [deleteModal, setDeleteModal] = React.useState(false)
    // const [modalcategory, setModalcategory] = React.useState(false)
    // const [event, setEvent] = React.useState({})
    // const [selectedDay, setSelectedDay] = React.useState(0)
    // const [isEdit, setIsEdit] = React.useState(false)
    // React.useEffect(() => {
    //     onGetCategories()
    //     onGetEvents()
    //     new Draggable((document.getElementById("external-events") as any), {
    //         itemSelector: ".external-event",
    //     })
    // }, [onGetCategories, onGetEvents]);
    // const toggle = () => {
    //     setModal(!modal)
    //     if (!modal && !!isEdit) {
    //         setTimeout(() => {
    //             setEvent({})
    //             setIsEdit(false)
    //         }, 500)
    //     }
    // }
    // const toggleCategory = () => {
    //     setModalcategory(!modalcategory)
    // }
    // const handleDateClick = (arg: any) => {
    //     setSelectedDay(arg)
    //     toggle()
    // }
    // const handleEventClick = (arg: any) => {
    //     const event = arg.event
    //     setEvent({
    //         id: event.id,
    //         title: event.title,
    //         title_category: event.title_category,
    //         start: event.start,
    //         className: event.classNames,
    //         category: event.classNames[0],
    //         event_category: event.classNames[0],
    //     })
    //     setIsEdit(true)
    //     toggle()
    // }
    // const handleDeleteEvent = () => {
    //     const { onDeleteEvent } = props
    //     onDeleteEvent(event)
    //     setDeleteModal(false)
    //     toggle()
    // }
    // const onDrag = (event: any) => {
    //     event.preventDefault()
    // }
    const onDrop = (event: any) => {
        const { onAddNewEvent } = props
        const draggedEl = event.draggedEl
        const newEvent = {
            id: Math.floor(Math.random() * 100),
            title: draggedEl.innerText,
            start: event.date,
            className: draggedEl.className,
        }
        onAddNewEvent(newEvent)
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="10" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>CALENDAR</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Menu / Calendar</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', backgroundColor: '#fff', padding: 20, borderRadius: 10 }}>
                            <FullCalendar
                                plugins={[
                                    // BootstrapTheme,
                                    dayGridPlugin,
                                    interactionPlugin,
                                ]}
                                // themeSystem="bootstrap"
                                slotDuration={"00:15:00"}
                                handleWindowResize={true}
                                headerToolbar={{
                                    left: isMobile ? "prev,next" : "prev,next,today",
                                    center: isMobile ? undefined : "title",
                                    right: isMobile ? 'title' : "dayGridMonth,dayGridWeek,dayGridDay",
                                }}
                                events={[
                                    {
                                        id: '1',
                                        title: 'Workday',
                                        start: '2023-10-01',
                                        end: '2023-10-05',
                                    },
                                    {
                                        id: '2',
                                        title: 'Meeting',
                                        date: '2023-10-11',
                                        allDay: true
                                    },
                                    {
                                        id: '3',
                                        title: 'Meeting',
                                        date: '2023-10-12',
                                        allDay: true
                                    },
                                    {
                                        id: '4',
                                        title: 'Workday',
                                        start: '2023-10-18',
                                        end: '2023-10-21',
                                    },
                                    {
                                        id: '5',
                                        title: 'Workday',
                                        start: '2023-10-23',
                                        end: '2023-10-26',
                                    },
                                ]}
                                editable={true}
                                droppable={true}
                                selectable={true}
                                // dateClick={handleDateClick}
                                // eventClick={handleEventClick}
                                drop={onDrop}
                            />
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default CalendarScreen;