import { Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Switch } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { HTTPGetCategories } from '../../apis/categories';
import { toast } from 'react-toastify';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { styled } from '@mui/material/styles';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            left: 12,
        },
        '&:after': {
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const PromotionCategoryScreen = () => {
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [Categories, setCategories] = React.useState([])

    const GetCategories = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetCategories({ page: 1 })
            setCategories(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    // React.useEffect(() => {
    //     async function APIList() {
    //         await GetCategories()
    //     }

    //     APIList().then().catch()
    // }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="30" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Kategori Pilihan</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pusat Promosi / Kategori Pilihan</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                Categories.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Brand terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '30%' }} align="left">Nama Kategori</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '40%' }} align="center">Gambar</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Categories.map((item: { id: string, name: string, image: string, products_total: number }) => (
                                                                    <TableRow
                                                                        key={item.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center">
                                                                            <img src={item.image} style={{ width: '30%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Android12Switch color={'secondary'} defaultChecked />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination count={5} />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default PromotionCategoryScreen;