import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPUpdateOrderWaybill } from '../../apis/orders';
import secureLocalStorage from 'react-secure-storage';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ReceiptForm = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const [ReceiptName, setReceiptName] = React.useState('')

    const onClose = () => {
        setReceiptName('')
        param.onClose()
    }

    const ActionReceipt = async () => {
        setLoading(true)
        try {
            await HTTPUpdateOrderWaybill({ order_id: param.item, token, waybill: ReceiptName })
            setLoading(false)
            onClose()
            param.getData()
            toast.success('Berhasil mengisi Resi')
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>Isi Resi</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} gap={isMobile ? 1 : 3} marginY={isMobile ? 3 : 5}>
                    <Typography variant="body1" color={Colors.secondary}>Isi Nomor Resi:</Typography>
                    <TextField
                        type="text"
                        placeholder="Nomor Resi"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={ReceiptName}
                        onChange={(text) => setReceiptName(text.target.value)}
                        onKeyDown={(e) => { if (e.keyCode === 13) ActionReceipt() }}
                    />
                </Stack>
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        onClick={ActionReceipt}
                        color={"secondary"}
                        variant={'contained'}
                        disabled={isLoading}
                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                    >SIMPAN</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}