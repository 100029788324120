import { Button, Icon, Stack, Toolbar, Typography, TextField, MenuItem, Select, FormControlLabel, RadioGroup, Radio, FormControl, FormHelperText, CircularProgress } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { HTTPAddVoucher, HTTPUpdateVoucher } from '../../apis/voucher';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';

const PromotionVoucherFormScreen = () => {
    const { type } = useParams()
    const navigate = useNavigate()
    const VoucherRedux = useSelector((state: RootState) => state.voucher_temp_data.data)
    const token = secureLocalStorage.getItem('token') as string

    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [VoucherName, setVoucherName] = React.useState('')
    const [VoucherCode, setVoucherCode] = React.useState('')
    const [VoucherDesc, setVoucherDesc] = React.useState('')
    const [DiscountType, setDiscountType] = React.useState('Nominal')
    const [VoucherType, setVoucherType] = React.useState('discount')
    const [DateFrom, setDateFrom] = React.useState<any>(null);
    const [DateTo, setDateTo] = React.useState<any>(null);
    const [DiscountValue, setDiscountValue] = React.useState('')
    const [DiscountMax, setDiscountMax] = React.useState('manual')
    const [DiscountMaxValue, setDiscountMaxValue] = React.useState('')
    const [MinimumTransaction, setMinimumTransaction] = React.useState('')
    const [TotalQuota, setTotalQuota] = React.useState('')
    const [DiscountUsage, setDiscountUsage] = React.useState('')

    const ActionVoucher = async () => {
        setLoading(true)
        try {
            const data = {
                token: token,
                name: VoucherName,
                code: VoucherCode,
                start_period: moment(DateFrom).format('YYYY-MM-DD') + ' ' + '00:00:00',
                end_period: moment(DateTo).format('YYYY-MM-DD') + ' ' + '23:59:59',
                description: VoucherDesc,
                type: 'discount',
                discount_type: DiscountType === 'Nominal' ? 'fixed' : 'percentage',
                discount_value: parseInt(DiscountValue),
                max_discount: DiscountType === 'Nominal' ? 0 : parseInt(DiscountMaxValue),
                max_usage: parseInt(DiscountUsage),
                min_transaction: parseInt(MinimumTransaction),
                total_quota: parseInt(TotalQuota)
            }
            if (type === 'add') {
                await HTTPAddVoucher(data)
                toast.success('Berhasil menambahkan Voucher!')
            } else {
                await HTTPUpdateVoucher({ ...data, voucher_id: VoucherRedux.id })
                toast.success('Berhasil mengubah Voucher!')
            }
            setLoading(false)
            navigate(-1)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan!')
             
        }
    }

    React.useEffect(() => {
        function Initial() {
            if (type === 'edit') {
                setVoucherName(VoucherRedux.name)
                setVoucherCode(VoucherRedux.code)
                setDateFrom(moment(VoucherRedux.start_period))
                setDateTo(moment(VoucherRedux.end_period))
                setVoucherDesc(VoucherRedux.description)
                setVoucherType('discount')
                setDiscountType(VoucherRedux.discount_type === 'fixed' ? 'Nominal' : 'Persentase')
                setDiscountValue(VoucherRedux.discount_value.toString())
                setDiscountMax(VoucherRedux.max_discount === VoucherRedux.discount_value ? 'auto' : 'manual')
                setDiscountMaxValue(VoucherRedux.max_discount.toString())
                setDiscountUsage(VoucherRedux.max_usage.toString())
                setMinimumTransaction(VoucherRedux.min_transaction.toString())
                setTotalQuota(VoucherRedux.total_quota.toString())
            }
        }
        Initial()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="30" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Voucher Toko</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pusat Promosi / Voucher Toko</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={'100%'}>
                                <Stack direction={'row'} gap={2} alignItems={'center'} marginBottom={3}>
                                    <Button
                                        variant={'outlined'}
                                        sx={{ borderColor: Colors.secondary }}
                                        color={'secondary'}
                                        onClick={() => navigate(-1)}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: Colors.secondaryDark }}>arrow_back</Icon>}
                                    >
                                        <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                                    </Button>
                                    <Typography variant="body1" fontWeight={'600'} color={Colors.secondaryDark}>{type === 'add' ? 'FORM TAMBAH VOUCHER' : 'FORM UBAH VOUCHER'}</Typography>
                                </Stack>
                                <Typography variant="body1" color={'#000'}><b>Rincian Voucher:</b></Typography>
                                <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Nama Voucher:</Typography>
                                    <FormControl sx={{ width: '60%' }}>
                                        <TextField
                                            type="text"
                                            placeholder="Nama Voucher"
                                            size="small"
                                            sx={{ bgcolor: "white" }}
                                            InputProps={{ sx: { borderRadius: 1 } }}
                                            value={VoucherName}
                                            onChange={(text) => setVoucherName(text.target.value)}
                                        />
                                        <FormHelperText>Masukkan A-Z, 0-9; Maksimum 10 karakter tanpa spasi</FormHelperText>
                                    </FormControl>
                                </Stack>
                                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Kode Voucher:</Typography>
                                    <TextField
                                        placeholder="Kode Voucher"
                                        size="small"
                                        sx={{ bgcolor: "white", width: '60%' }}
                                        value={VoucherCode}
                                        onChange={(text) => setVoucherCode(text.target.value)}
                                        InputProps={{
                                            endAdornment: <Typography variant={'body2'} color={Colors.secondaryDark}>0/10</Typography>,
                                            sx: { borderRadius: 1 }
                                        }}
                                    />
                                </Stack>
                                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Periode Pemakaian Voucher:</Typography>
                                    <Stack direction={"row"} width={"60%"} alignItems={'center'} justifyContent={'space-between'}>
                                        <DatePicker
                                            value={DateFrom}
                                            onChange={(date) => setDateFrom(date)}
                                            sx={{ bgcolor: "white", borderRadius: 1, width: "40%" }}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                        <Typography variant="h5" color={Colors.secondaryDark}>-</Typography>
                                        <DatePicker
                                            value={DateTo}
                                            onChange={(date) => setDateTo(date)}
                                            sx={{ bgcolor: "white", borderRadius: 1, width: "40%" }}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Deskripsi Voucher:</Typography>
                                    <ReactQuill
                                        value={VoucherDesc}
                                        theme={"snow"}
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, false] }],
                                                ["bold", "italic", "underline", "strike", "blockquote"],
                                                [
                                                    { list: "ordered" },
                                                    { list: "bullet" },
                                                    { indent: "-1" },
                                                    { indent: "+1" },
                                                ],
                                                ["link", "clean"],
                                            ],
                                        }}
                                        formats={[
                                            "header",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                            "list",
                                            "bullet",
                                            "indent",
                                            "link",
                                        ]}
                                        onChange={(value) => setVoucherDesc(value)}
                                        style={{ height: isMobile ? undefined : "18em", width: '60%' }}
                                    />
                                </Stack>
                                <Typography variant="body1" color={'#000'} marginTop={4}><b>Pengaturan Bonus:</b></Typography>
                                {/* <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Tipe Voucher:</Typography>
                                    <RadioGroup defaultValue="discount" sx={{ width: "60%" }} value={VoucherType} onChange={(e) => setVoucherType(e.target.value)}>
                                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                                            <FormControlLabel value="discount" control={<Radio color={'secondary'} />} label="Diskon" />
                                            <FormControlLabel value="coin" control={<Radio color={"secondary"} />} label="Cashback Koin" />
                                        </Stack>
                                    </RadioGroup>
                                </Stack> */}
                                <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Tipe Diskon:</Typography>
                                    {
                                        VoucherType === 'discount' ?
                                            <Stack direction={"row"} alignItems={'center'} width={"60%"} gap={1}>
                                                <Select
                                                    displayEmpty
                                                    size="small"
                                                    MenuProps={{
                                                        style: { maxHeight: 500 }
                                                    }}
                                                    sx={{ bgcolor: "white", width: '40%' }}
                                                    inputProps={{ sx: { borderRadius: 1 } }}
                                                    value={DiscountType}
                                                    onChange={(e) => setDiscountType(e.target.value)}
                                                >
                                                    {
                                                        ['Nominal', 'Persentase'].map((item) => (
                                                            <MenuItem value={item} key={item}>{item}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    DiscountType === 'Nominal' ?
                                                        <TextField
                                                            placeholder="Nominal Diskon"
                                                            size="small"
                                                            type={'number'}
                                                            sx={{ bgcolor: "white", width: '60%' }}
                                                            value={DiscountValue}
                                                            onChange={(text) => setDiscountValue(text.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                                        <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                                                        <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                                                        <div></div>
                                                                    </Stack>
                                                                ),
                                                                sx: { borderRadius: 1 },
                                                            }}
                                                        />
                                                        :
                                                        <TextField
                                                            placeholder="Jumlah Persen"
                                                            size="small"
                                                            type={'number'}
                                                            sx={{ bgcolor: "white", width: '60%' }}
                                                            value={DiscountValue}
                                                            onChange={(text) => setDiscountValue(parseInt(text.target.value) > 100 ? '100' : text.target.value)}
                                                            InputProps={{
                                                                endAdornment: <Typography variant={'body1'} marginLeft={1} width={'30%'} color={Colors.secondaryDark}>| % Diskon</Typography>,
                                                                sx: { borderRadius: 1 },
                                                            }}
                                                        />
                                                }
                                            </Stack>
                                            :
                                            <FormControl sx={{ width: '60%' }}>
                                                <TextField
                                                    placeholder="Jumlah Persen"
                                                    size="small"
                                                    type={'number'}
                                                    sx={{ bgcolor: "white" }}
                                                    InputProps={{
                                                        endAdornment: <Typography variant={'body1'} marginLeft={1} width={'30%'} color={Colors.secondaryDark}>| % Diskon</Typography>,
                                                        sx: { borderRadius: 1 },
                                                    }}
                                                />
                                                <FormHelperText>-% dari jumlah pembayaran akan dikembalikan ke Pembeli dalam bentuk Koin Angelo. Aturan Penukaran Koin: Rp1000 = 1 Koin Angelo Skin.</FormHelperText>
                                            </FormControl>
                                    }
                                </Stack>
                                {
                                    DiscountType === 'Persentase' || VoucherType === 'coin' ?
                                        <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'}>
                                            <Typography variant="body1" color={Colors.secondaryDark}>Maksimum Diskon:</Typography>
                                            <Stack direction={"column"} gap={2} width={'60%'}>
                                                <RadioGroup value={DiscountMax} onChange={(e) => setDiscountMax(e.target.value)} sx={{ width: "100%" }}>
                                                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                                                        <FormControlLabel value="manual" control={<Radio color={'secondary'} />} label="Atur Diskon Maksimum" />
                                                        <FormControlLabel value="auto" control={<Radio color={"secondary"} />} label="Tanpa batas" />
                                                    </Stack>
                                                </RadioGroup>
                                                {
                                                    DiscountMax === 'manual' ?
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <TextField
                                                                placeholder="Diskon Maksimum"
                                                                size="small"
                                                                type={'number'}
                                                                sx={{ bgcolor: "white", width: '100%' }}
                                                                value={DiscountMaxValue}
                                                                onChange={(text) => setDiscountMaxValue(text.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                                            <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                                                            <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                                                            <div></div>
                                                                        </Stack>
                                                                    ),
                                                                    sx: { borderRadius: 1 },
                                                                }}
                                                            />
                                                            <FormHelperText>Sama dengan: <b>10 Koin Angelo</b></FormHelperText>
                                                        </FormControl>
                                                        : null
                                                }
                                            </Stack>
                                        </Stack>
                                        :
                                        null
                                }
                                <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Minimum Pembelian:</Typography>
                                    <TextField
                                        placeholder="Nominal Minimum"
                                        size="small"
                                        type={'number'}
                                        sx={{ bgcolor: "white", width: '60%' }}
                                        value={MinimumTransaction}
                                        onChange={(text) => setMinimumTransaction(text.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                    <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                                    <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                                    <div></div>
                                                </Stack>
                                            ),
                                            sx: { borderRadius: 1 },
                                        }}
                                    />
                                </Stack>
                                <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Kuota Pemakaian:</Typography>
                                    <FormControl sx={{ width: '60%' }}>
                                        <TextField
                                            type="number"
                                            placeholder="Jumlah Kuota Pemakaian"
                                            size="small"
                                            sx={{ bgcolor: "white" }}
                                            InputProps={{ sx: { borderRadius: 1 } }}
                                            value={TotalQuota}
                                            onChange={(text) => setTotalQuota(text.target.value)}
                                        />
                                        <FormHelperText>Maks. kuota voucher yang dapat dipakai oleh Pembeli.</FormHelperText>
                                    </FormControl>
                                </Stack>
                                <Stack direction={'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'} marginBottom={3}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Jumlah Maks. Per-pembeli:</Typography>
                                    <FormControl sx={{ width: '60%' }}>
                                        <TextField
                                            type="number"
                                            placeholder="1"
                                            size="small"
                                            sx={{ bgcolor: "white" }}
                                            InputProps={{ sx: { borderRadius: 1 } }}
                                            value={DiscountUsage}
                                            onChange={(text) => setDiscountUsage(text.target.value)}
                                        />
                                        <FormHelperText>Jumlah Voucher yang diberikan per Pembeli tidak dapat melebihi Kuota Pemakaian</FormHelperText>
                                    </FormControl>
                                </Stack>
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Button
                                        onClick={ActionVoucher}
                                        color={"secondary"}
                                        variant={'contained'}
                                        sx={{ backgroundColor: Colors.primary, width: '20%' }}
                                        startIcon={isLoading ?
                                            <CircularProgress color="secondary" size={20} />
                                            : <Icon fontSize={'small'}>save</Icon>}
                                    >Simpan</Button>
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default PromotionVoucherFormScreen;