import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { ImagePicker } from '../utils/imagePicker';
import { HTTPAddCertificate, HTTPDeleteCertificate, HTTPUpdateCertificate } from '../../apis/certificate';
import secureLocalStorage from 'react-secure-storage';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CertificateForm = (param: { isOpen: boolean, onClose: any, type: string, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const [ImageResult, setImageResult] = React.useState('')
    const [CertificateName, setCertificateName] = React.useState('')

    const onClose = () => {
        setImageResult('')
        setCertificateName('')
        param.onClose()
    }

    const ActionCertificate = async () => {
        setLoading(true)
        try {
            if (param.type === 'add') {
                await HTTPAddCertificate({
                    image: ImageResult,
                    name: CertificateName,
                    token
                })
                toast.success('Berhasil menambahkan Sertifikat!')
            } else {
                await HTTPUpdateCertificate({
                    cert_id: param.item.id,
                    image: ImageResult,
                    name: CertificateName,
                    token
                })
                toast.success('Berhasil mengubah Sertifikat!')
            }
            setLoading(false)
            onClose()
            param.getData()
        } catch (error) {
            toast.error('Terjadi Kesalahan')
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (param.isOpen === true && param.type === 'edit') {
            setCertificateName(param.item.name)
            setImageResult(param.item.image)
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>{param.type === 'add' ? 'Tambah Sertifikat' : 'Edit Sertifikat'}</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Stack
                    direction={isMobile ? 'column' : "row"}
                    justifyContent={isMobile ? 'flex-start' : 'space-between'}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    gap={isMobile ? 1 : 3}
                    marginY={isMobile ? 3 : 5}
                >
                    <Typography variant="body1" color={Colors.secondary}>Nama Sertifikat:</Typography>
                    <TextField
                        type="text"
                        placeholder="Nama Sertifikat"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={CertificateName}
                        onChange={(text) => setCertificateName(text.target.value)}
                    />
                </Stack>
                <Stack
                    direction={isMobile ? 'column' : "row"}
                    justifyContent={isMobile ? 'flex-start' : 'space-between'}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    gap={isMobile ? 1 : 3}
                    marginBottom={5}
                >
                    <Typography variant="body1" color={Colors.secondary}>Gambar Sertifikat:</Typography>
                    <div style={{ width: isMobile ? '100%' : '70%' }}>
                        <ImagePicker onResult={(img: any) => setImageResult(img)} initValue={ImageResult} />
                    </div>
                </Stack>
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        color={"secondary"}
                        variant={'contained'}
                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                        onClick={ActionCertificate}
                    >Simpan</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const CertificateDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const DeleteCertificate = async () => {
        setLoading(true)
        try {
            await HTTPDeleteCertificate({ cert_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2">Anda yakin untuk menghapus data ini?</Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            sx={{ backgroundColor: Colors.primary }}
                            onClick={DeleteCertificate}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    'Yakin'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}