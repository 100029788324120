import { AxiosNormal } from "../utils/interceptors";
const url = "payment_methods";

export function HTTPGetPayments(param: {
  token: string;
  page: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/get?page=${param.page}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPStatusPayments(param: {
  token: string;
  id: string;
  is_active: boolean;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/change_status`, {
        token: param.token,
        id: param.id,
        is_active: param.is_active,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
