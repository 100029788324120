import { Button, Icon, Stack, Typography, TextField, CircularProgress } from '@mui/material';
import React from 'react'
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { HTTPGetReseller, HTTPUpdateReseller } from '../../apis/reseller';
import secureLocalStorage from 'react-secure-storage';

const ResellerContact = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    const [Label, setLabel] = React.useState('')
    const [Link, setLink] = React.useState('')

    const GetContact = async () => {
        try {
            const result = await HTTPGetReseller({ token })
            const data = result.data.data
            setLabel(data.button_name)
            setLink(data.whatsapp_link)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const UpdateContact = async () => {
        setLoading(true)
        try {
            await HTTPUpdateReseller({
                token,
                button_name: Label,
                whatsapp_link: Link
            })
            toast.success('Berhasil mengubah Kontak Reseller!')
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetContact()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile ? '100%' : '70%'} marginBottom={3}>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Label Button:</Typography>
                    <TextField
                        type="text"
                        placeholder="Label Button"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={Label}
                        onChange={(text) => setLabel(text.target.value)}
                        />
                </Stack>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Link WA:</Typography>
                    <TextField
                        type="text"
                        placeholder="Link Whatsapp"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={Link}
                        onChange={(text) => setLink(text.target.value)}
                    />
                </Stack>
            </Stack>
            <div style={{ width: '100%', ...CENTER }}>
                <Button
                    onClick={UpdateContact}
                    color={"secondary"}
                    variant={'contained'}
                    disabled={isLoading}
                    sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '20%' }}
                    startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                >Simpan</Button>
            </div>
        </div>
    )
}

export default ResellerContact;