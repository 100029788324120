import { Button, Icon, Stack, Typography, TextField, CircularProgress, IconButton } from '@mui/material';
import React from 'react'
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';

const PasswordSetting = () => {
    const [isLoading, setLoading] = React.useState(false)
    const [isOldPassShow, setOldPassShow] = React.useState(true)
    const [isNewPassShow, setNewPassShow] = React.useState(true)
    const [isConfirmPassShow, setConfirmPassShow] = React.useState(true)

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile ? '100%' : '70%'} marginBottom={3}>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Password Lama:</Typography>
                    <TextField
                        type={isOldPassShow ? 'password' : "text"}
                        placeholder="Password Lama"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: (
                                <IconButton onClick={() => setOldPassShow(!isOldPassShow)}>
                                    <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isOldPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                </IconButton>
                            ),
                        }}
                    />
                </Stack>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Password Baru:</Typography>
                    <TextField
                        type={isNewPassShow ? 'password' : "text"}
                        placeholder="Password Baru"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: (
                                <IconButton onClick={() => setNewPassShow(!isNewPassShow)}>
                                    <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isNewPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                </IconButton>
                            ),
                        }}
                    />
                </Stack>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Konfirmasi Password Baru:</Typography>
                    <TextField
                        type={isConfirmPassShow ? 'password' : "text"}
                        placeholder="Konfirmasi Password Baru"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: (
                                <IconButton onClick={() => setConfirmPassShow(!isConfirmPassShow)}>
                                    <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isConfirmPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                </IconButton>
                            ),
                        }}
                    />
                </Stack>
            </Stack>
            <div style={{ width: '100%', ...CENTER }}>
                <Button
                    // onClick={ActionCustomer}
                    color={"secondary"}
                    variant={'contained'}
                    disabled={isLoading}
                    sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '20%' }}
                    startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                >Simpan</Button>
            </div>
        </div>
    )
}

export default PasswordSetting;