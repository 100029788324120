import { combineReducers } from "@reduxjs/toolkit";
import { ProfileData } from "./data/profile";
import { AdminTempData } from "./data/admin";
import { CustomerTempData } from "./data/customer";
import { ArticleTempData } from "./data/article";
import { FAQTempData } from "./data/faq";
import { VoucherTempData } from "./data/voucher";
import { OrderIDData } from "./data/order";

const rootReducer = combineReducers({
  profile_data: ProfileData,
  admin_temp_data: AdminTempData,
  customer_temp_data: CustomerTempData,
  article_temp_data: ArticleTempData,
  faq_temp_data: FAQTempData,
  voucher_temp_data: VoucherTempData,
  orderID_data: OrderIDData,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
