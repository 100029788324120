import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { HTTPGetLogoConfig } from '../../apis/settings';
import { Images } from '../../assets/images';
import { Colors } from '../../utils/colors';
import { LogoModal } from './logoModal';

export const Logo = () => {
    const [init, setInit] = React.useState(false)
    const [ImageModal, setImageModal] = React.useState(false)
    const [CurrentLogo, setCurrentLogo] = React.useState('')

    const GetLogo = async () => {
        try {
            const result = await HTTPGetLogoConfig()
            setCurrentLogo(result.data.data)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetLogo()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%' }}>
            <Stack
                marginBottom={3}
                direction={isMobile ? 'column' : 'row'}
                gap={2}
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent={'space-between'}
                width={isMobile ? '100%' : '70%'}
            >
                <Typography variant="body1" width={"30%"} color={Colors.secondaryDark}>Logo Website</Typography>
                <Stack direction={'row'} alignItems={'center'} gap={3} width={'70%'}>
                    <img src={CurrentLogo} style={{ width: '30%', aspectRatio: 1, objectFit: 'cover' }} alt="" />
                    <Button onClick={() => setImageModal(true)} variant={"outlined"} color={'secondary'} sx={{ borderColor: Colors.secondary, color: '#000' }}>Ubah Logo</Button>
                </Stack>
            </Stack>
            <LogoModal
                getData={GetLogo}
                isOpen={ImageModal}
                item={{ logo: CurrentLogo }}
                onClose={() => setImageModal(false)}
            />
        </div>
    )
}