import { Stack, Toolbar, Typography, IconButton, Icon, Button, Tab, Tabs } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { Tax } from '../../components/settings/tax';
import { Fee } from '../../components/settings/fee';
import { Logo } from '../../components/settings/logo';

const SettingsScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [menuActive, setMenuActive] = React.useState(0)

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {isMobile ? null :
                        <div style={{ width: '20%' }}>
                            <SideBar nodeId="70" />
                        </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Pengaturan Toko</h2>
                            {isMobile ? null :
                                <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pengaturan</h4>
                            }
                        </Stack>
                        {isMobile ? null :
                            <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Tabs
                                    textColor={'secondary'}
                                    indicatorColor={'secondary'}
                                    value={menuActive}
                                    onChange={(e, value) => setMenuActive(value)}
                                >
                                    <Tab label="Pajak / Tax" />
                                    <Tab label="Fee / Biaya Admin" />
                                    <Tab label="Logo Website" />
                                </Tabs>
                                {
                                    menuActive === 0 ?
                                        <Tax />
                                        :
                                        menuActive === 1 ?
                                            <Fee />
                                            :
                                            <Logo />
                                }
                            </Stack>
                        </div>
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default SettingsScreen;