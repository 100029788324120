import * as React from "react";
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Icon,
    CssBaseline,
    Avatar,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
} from "@mui/material";
import { CENTER, Colors } from "../../utils/colors";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { Images } from "../../assets/images";
import SideBar from "./sideBar";
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";

const NavigationBar = () => {
    const navigate = useNavigate();
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)
    const [isDrawer, setDrawer] = React.useState(false);
    const [profile, setProfile] = React.useState<any>(null);

    const toggleDrawer = () => {
        setDrawer(!isDrawer)
    };

    const profileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProfile(event.currentTarget);
    };

    const profileClose = () => {
        setProfile(null);
    };

    const onLogout = () => {
        secureLocalStorage.clear()
        localStorage.setItem('isLogin', 'false')
        navigate('/')
    }

    return (
        <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="absolute"
                sx={{
                    backgroundColor: '#ffffff08',
                    width: '100%',
                    top: 0
                }}
            >
                <Toolbar sx={{ ...CENTER }}>
                    {
                        isMobile ?
                            <Stack spacing={2} width={'100%'}>
                                <Stack
                                    width={'100%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <Stack direction={'row'} gap={2} alignItems={'center'} height={'100%'}>
                                        <IconButton onClick={toggleDrawer}>
                                            <Icon fontSize={'medium'} sx={{ color: '#fff' }}>menu</Icon>
                                        </IconButton>
                                        <img src={Images.mainLogo} style={{ height: 30, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                    </Stack>
                                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                                        <Icon fontSize={'medium'} sx={{ color: '#fff' }}>translate</Icon>
                                        <Icon fontSize={'medium'} sx={{ color: '#fff' }}>notifications_none</Icon>
                                        <IconButton onClick={profileClick}>
                                            <Avatar src={Images.noImageProfile}></Avatar>
                                        </IconButton>
                                        <Menu
                                            anchorEl={profile}
                                            open={Boolean(profile)}
                                            onClose={profileClose}
                                        >
                                            <MenuItem onClick={() => navigate('/account')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                                                <ListItemIcon sx={{ color: "inherit" }}><Icon>account_circle</Icon></ListItemIcon>
                                                <ListItemText sx={{ color: "inherit" }}>Akun Saya</ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem sx={{ color: Colors.error }} onClick={onLogout}>
                                                <ListItemIcon sx={{ color: "inherit" }}><Icon>logout</Icon></ListItemIcon>
                                                <ListItemText sx={{ color: "inherit" }}>Logout</ListItemText>
                                            </MenuItem>
                                        </Menu>
                                    </Stack>
                                </Stack>
                            </Stack>
                            :
                            <div style={{ width: '100%', ...CENTER }}>
                                <Stack
                                    width={'90%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ padding: '10px 0' }}
                                >
                                    <img src={Images.mainLogo} style={{ height: 'auto', width: 100, objectFit: 'contain' }} alt="" className="" />
                                    <Stack direction={'row'} gap={3} alignItems={'center'}>
                                        <Icon fontSize={'medium'} sx={{ color: '#fff' }}>translate</Icon>
                                        <Icon fontSize={'medium'} sx={{ color: '#fff' }}>notifications_none</Icon>
                                        <Button variant="text" onClick={profileClick}>
                                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                <Avatar src={Images.noImageProfile}></Avatar>
                                                <span style={{ color: '#fff' }}>{ProfileRedux.name}</span>
                                                <Icon fontSize={'small'} sx={{ color: '#fff' }}>keyboard_arrow_down</Icon>
                                            </Stack>
                                        </Button>
                                        <Menu
                                            anchorEl={profile}
                                            open={Boolean(profile)}
                                            onClose={profileClose}
                                        >
                                            <MenuItem onClick={() => navigate('/account')} sx={{ "&:hover": { color: Colors.primary, transition: "all .3s", }, transition: "all .3s" }}>
                                                <ListItemIcon sx={{ color: "inherit" }}><Icon>account_circle</Icon></ListItemIcon>
                                                <ListItemText sx={{ color: "inherit" }}>Akun Saya</ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem sx={{ color: Colors.error }} onClick={onLogout}>
                                                <ListItemIcon sx={{ color: "inherit" }}><Icon>logout</Icon></ListItemIcon>
                                                <ListItemText sx={{ color: "inherit" }}>Logout</ListItemText>
                                            </MenuItem>
                                        </Menu>
                                    </Stack>
                                </Stack>
                            </div>
                    }
                </Toolbar>
            </AppBar>
            <div style={{ width: '100%', position: 'relative' }}>
                <div style={{
                    background: 'linear-gradient(to right, #861ea6, #713684)',
                    width: '100%',
                    height: 180,
                    position: 'relative'
                }}></div>
                <div style={{
                    background: `url(${Images.navbarBg})`,
                    width: '100%',
                    height: 180,
                    backgroundSize: 'cover',
                    opacity: 0.03,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0
                }}></div>
            </div>
            {
                isMobile ?
                    <Drawer
                        sx={{
                            width: '70%',
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: '70%',
                                boxSizing: "border-box",
                            },
                        }}
                        variant={"temporary"}
                        anchor="left"
                        open={isDrawer}
                        onClose={toggleDrawer}
                    >
                        <SideBar nodeId="10" />
                    </Drawer>
                    :
                    null
            }
        </Box >
    );
};

export default NavigationBar;
