import { AxiosNormal } from "../utils/interceptors";
const url = "footer/addresses";

export function HTTPGetAddresses(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/get_all`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetAddressByID(param: {
  address_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/${param.address_id}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddAddress(param: {
  token: string;
  province_id: string;
  city_id: string;
  subdistrict_id: string;
  postal_code: string;
  address: string;
  address_detail: string;
  is_main: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
        province_id: param.province_id,
        city_id: param.city_id,
        subdistrict_id: param.subdistrict_id,
        postal_code: param.postal_code,
        address: param.address,
        address_detail: param.address_detail,
        is_main: param.is_main,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateAddress(param: {
  token: string;
  province_id: string;
  city_id: string;
  subdistrict_id: string;
  postal_code: string;
  address: string;
  address_detail: string;
  is_main: number;
  address_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/update/${param.address_id}`, {
        token: param.token,
        province_id: param.province_id,
        city_id: param.city_id,
        subdistrict_id: param.subdistrict_id,
        postal_code: param.postal_code,
        address: param.address,
        address_detail: param.address_detail,
        is_main: param.is_main,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteAddress(param: {
  address_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/delete/${param.address_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
