import { AxiosNormal } from "../utils/interceptors";
const url = "orders";

export function HTTPGetOrders(param: { token: string, page: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/list?page=${param.page}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetOrdersByID(param: {
  token: string;
  order_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/detail`, {
        token: param.token,
        order_id: param.order_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateOrderWaybill(param: {
  token: string;
  order_id: string;
  waybill: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/update_waybill`, {
        token: param.token,
        order_id: param.order_id,
        waybill: param.waybill,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPSetOrderApproval(param: {
  token: string;
  return_approval_status: string;
  return_approval_season: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/return/approval`, {
        token: param.token,
        return_approval_status: param.return_approval_status,
        return_approval_season: param.return_approval_season,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
