import React from 'react'
import { Icon, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Switch, Typography, Toolbar, Button } from '@mui/material';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPUpdateAddress } from '../../apis/address';
import secureLocalStorage from 'react-secure-storage';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { OrderAddressDelete, OrderAddressForm } from '../../components/order/orderAddressModal';
import NavigationBar from '../../components/navigation/navigationBar';
import { isMobile } from 'react-device-detect';
import SideBar from '../../components/navigation/sideBar';
import { HTTPGetShopAddresses, HTTPUpdateShopAddress } from '../../apis/shopAddress';

const OrderShippingSettingScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [AddressData, setAddressData] = React.useState([])
    const [AddressModal, setAddressModal] = React.useState(false)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [isDelete, setDelete] = React.useState(false)
    const [ModalType, setModalType] = React.useState('add')
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const onUpdate = (value: any) => {
        setModalType('edit')
        setSelectedItem(value)
        setAddressModal(true)
    }
    const onDelete = (value: any) => {
        setSelectedItem(value)
        setDelete(true)
    }

    const GetAddresses = async (page: number) => {
        try {
            const result = await HTTPGetShopAddresses({ token, page })
            setAddressData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetAddresses(value)
    }

    const onAddressMain = async (value: any) => {
        try {
            await HTTPUpdateShopAddress({
                token,
                province_id: value.province.id,
                city_id: value.city.id,
                subdistrict_id: value.subdistrict.id,
                address: value.address,
                name: value.name,
                is_primary: value.is_primary === false ? 1 : 0,
                address_id: value.id
            })
            await GetAddresses(CurrentPage)
        } catch (error) {
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            setLoading(true)
            await GetAddresses(1)
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="70" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>PENGATURAN PENGIRIMAN</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pesanan / Pengaturan Pengiriman</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={"column"} gap={3}>
                                <Stack direction={'row'} alignItems={'center'} gap={isMobile ? 1 : 2} justifyContent={'space-between'} width={"100%"}>
                                    <Button
                                        variant={'contained'}
                                        color={"secondary"}
                                        onClick={() => { setModalType('add'); setAddressModal(true) }}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>add</Icon>}
                                        sx={{ backgroundColor: Colors.primary }}
                                    >
                                        <span style={{ color: '#fff' }}>{isMobile ? 'Tambah' : 'Tambah Alamat'}</span>
                                    </Button>
                                    <Stack direction={"row"} gap={isMobile ? 1 : 2} alignItems="center">
                                        <Stack direction={"row"} gap={1} alignItems="center">
                                            <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                            <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                        </Stack>
                                        <IconButton>
                                            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.secondaryDark }}>delete</Icon>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                    {
                                        isLoading ?
                                            <SkeletonListLoader />
                                            :
                                            <>
                                                {
                                                    AddressData.length === 0 ?
                                                        <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                            <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                            <Typography variant="body1" color={Colors.secondary}>Silahkan tambah data Alamat terlebih dahulu</Typography>
                                                        </Stack>
                                                        :
                                                        <TableContainer>
                                                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ width: '10%' }} align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Label</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '40%' }} align="left">Alamat</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Alamat Utama</TableCell>
                                                                        <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {AddressData.map((item: any, index: number) => (
                                                                        <TableRow
                                                                            key={index}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                            <TableCell align="left">{item.name}</TableCell>
                                                                            <TableCell align="left">
                                                                                {item.address + ', ' + item.subdistrict.name + ', ' + item.city.type + ' ' + item.city.name + ', ' + item.province.name}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Switch
                                                                                    checked={item.is_primary}
                                                                                    onChange={() => onAddressMain(item)}
                                                                                    color={'secondary'}
                                                                                /></TableCell>
                                                                            <TableCell align="center">
                                                                                <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                                    <IconButton onClick={() => onUpdate(item)}>
                                                                                        <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                    </IconButton>
                                                                                    <IconButton onClick={() => onDelete(item)}>
                                                                                        <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                }
                                            </>
                                    }
                                    <div style={{ width: '100%', ...CENTER }}>
                                        <Pagination
                                            page={CurrentPage}
                                            count={TotalPage}
                                            onChange={onChangePage}
                                        />
                                    </div>
                                </Stack>
                            </Stack>
                            <OrderAddressForm
                                isOpen={AddressModal}
                                onClose={() => setAddressModal(false)}
                                type={ModalType}
                                getData={() => GetAddresses(CurrentPage)}
                                item={SelectedItem}
                            />
                            <OrderAddressDelete
                                isOpen={isDelete}
                                onClose={() => setDelete(false)}
                                getData={() => GetAddresses(CurrentPage)}
                                item={SelectedItem}
                            />
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div >
    )
}

export default OrderShippingSettingScreen;