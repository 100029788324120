import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const AdminTempSlice = createSlice({
  name: "Admin_Temp_Data",
  initialState: {
    data: {
      id: "",
      name: "",
      email: "",
      role: {
        id: "",
        name: "",
      },
      permission: [],
    } as any,
  },
  reducers: {
    setAdminTempData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setAdminTempData } = AdminTempSlice.actions;
export const AdminTempData = AdminTempSlice.reducer;
