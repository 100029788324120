import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const OrderIDSlice = createSlice({
  name: "OrderID_Data",
  initialState: {
    id: "",
  },
  reducers: {
    setOrderIDData(state, action: PayloadAction<any>) {
      state.id = action.payload.id;
    },
  },
});

export const { setOrderIDData } = OrderIDSlice.actions;
export const OrderIDData = OrderIDSlice.reducer;
