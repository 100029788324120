import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const CustomerTempSlice = createSlice({
  name: "Customer_Temp_Data",
  initialState: {
    data: {
      dob: "",
      email: "",
      gender: "",
      id: "",
      is_active: true,
      is_banned: false,
      is_deleted: false,
      last_login: "",
      last_login_ip: "",
      name: "",
      phone_number: "",
      profile_picture: "",
      username: "",
    } as any,
  },
  reducers: {
    setCustomerTempData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setCustomerTempData } = CustomerTempSlice.actions;
export const CustomerTempData = CustomerTempSlice.reducer;
