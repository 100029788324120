import { Button, Stack, TextField, Toolbar } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { isMobile } from 'react-device-detect';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const CreateTaskScreen = () => {
    const [Article, setArticle] = React.useState("");
    const [dateFrom, setDateFrom] = React.useState<any>(moment());
    const [dateTo, setDateTo] = React.useState<any>(moment().add(1, 'day'));
    const handleArticle = (value: any) => setArticle(value);

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="10" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} sx={{ marginBottom: isMobile ? 3 : 0 }}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>CREATE TASK</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tasks / Create Task</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={3} width={'100%'}>
                                <span style={{ color: Colors.secondaryDark, fontWeight: '600' }}>Create New Task</span>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={'100%'} alignItems={'center'}>
                                    <span style={{ width: isMobile ? '100%' : '30%', color: Colors.secondaryDark }}>Task Name</span>
                                    <TextField
                                        type="text"
                                        placeholder="Enter Task Name..."
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : "70%" }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                    />
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={'100%'} alignItems={'flex-start'} sx={{ marginBottom: 6 }}>
                                    <span style={{ width: isMobile ? '100%' : '30%', color: Colors.secondaryDark }}>Task Description</span>
                                    <ReactQuill
                                        value={Article}
                                        theme={"snow"}
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, false] }],
                                                ["bold", "italic", "underline", "strike", "blockquote"],
                                                [
                                                    { list: "ordered" },
                                                    { list: "bullet" },
                                                    { indent: "-1" },
                                                    { indent: "+1" },
                                                ],
                                                ["link", "image"],
                                                ["clean"],
                                            ],
                                        }}
                                        formats={[
                                            "header",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                            "list",
                                            "bullet",
                                            "indent",
                                            "link",
                                            "image",
                                        ]}
                                        onChange={handleArticle}
                                        style={{ height: "200px", width: isMobile ? '100%' : '70%' }}
                                    />
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={'100%'} alignItems={'center'}>
                                    <span style={{ width: isMobile ? '100%' : '30%', color: Colors.secondaryDark }}>Task Date</span>
                                    <Stack direction={'row'} width={isMobile ? '100%' : '70%'} alignItems={'center'} gap={3}>
                                        <DatePicker
                                            value={dateFrom}
                                            onChange={(date) => setDateFrom(date)}
                                            sx={{
                                                bgcolor: "white",
                                                borderRadius: 1,
                                                width: isMobile ? "40vw" : "25%",
                                            }}
                                        />
                                        <h2 style={{ color: Colors.secondaryDark, margin: 0 }}>-</h2>
                                        <DatePicker
                                            value={dateTo}
                                            onChange={(date) => setDateTo(date)}
                                            sx={{
                                                bgcolor: "white",
                                                borderRadius: 1,
                                                width: isMobile ? "40vw" : "25%",
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={'100%'} alignItems={'flex-start'}>
                                    <span style={{ width: isMobile ? '100%' : '30%', color: Colors.secondaryDark }}>Add Team Member</span>
                                    <Stack direction={'column'} width={isMobile ? '100%' : '70%'} gap={2} alignItems={'flex-start'}>
                                        <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={'100%'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={isMobile ? 'flex-start' : 'space-between'}>
                                            <TextField
                                                type="text"
                                                placeholder="Enter Name..."
                                                size="small"
                                                sx={{ bgcolor: "white", width: isMobile ? '100%' : "50%" }}
                                                InputProps={{ sx: { borderRadius: 1 } }}
                                            />
                                            <Stack direction={'row'} gap={1} justifyContent={isMobile ? 'space-between' : 'flex-start'} width={isMobile ? '100%' : "48%"}>
                                                <TextField
                                                    type="file"
                                                    size="small"
                                                    sx={{ bgcolor: "white", width: "70%" }}
                                                    InputProps={{ sx: { borderRadius: 1 } }}
                                                />
                                                <Button variant='contained' color={'error'} sx={{ width: '25%' }}>Delete</Button>
                                            </Stack>
                                        </Stack>
                                        <Button variant='contained' color={'success'}>Add Member</Button>
                                    </Stack>
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={'100%'} alignItems={'start'}>
                                    <span style={{ width: isMobile ? '100%' : '30%', color: Colors.secondaryDark }}>Budget</span>
                                    <Stack direction={'column'} width={isMobile ? '100%' : '70%'} gap={2} alignItems={'flex-start'}>
                                        <TextField
                                            type="text"
                                            placeholder="Enter Task Budget..."
                                            size="small"
                                            sx={{ bgcolor: "white", width: "100%" }}
                                            InputProps={{ sx: { borderRadius: 1 } }}
                                        />
                                        <Button variant='contained' color={'secondary'} sx={{ backgroundColor: Colors.primary }}>Create Task</Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default CreateTaskScreen;