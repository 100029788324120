export const Colors = {
  primary: "#8e54a0",
  secondary: "#7c7b7e",
  secondaryDark: "#707070",
  success: "#60d195",
  error: "#bb2023",
  warning: "#ffc009",
  inherit: "#ffe4e3",
  info: "#0068ff",
};

export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Truncate = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
};