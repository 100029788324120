import { Stack, Toolbar, Typography, IconButton, Icon, Button, Tab, Tabs } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import ProfileSetting from '../../components/account/profileSetting';
import PasswordSetting from '../../components/account/passwordSettings';
import { PasswordForm } from '../../components/account/accountModal';
// import secureLocalStorage from 'react-secure-storage';

const AccountScreen = () => {
    // const token = secureLocalStorage.getItem('token') as string
    const [menuActive, setMenuActive] = React.useState(0)
    const [PasswordModal, setPasswordModal] = React.useState(false)

    const onChangeMenu = (value: number) => {
        if (value === 1) {
            setPasswordModal(true)
        } else {
            setMenuActive(0)
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {isMobile ? null :
                        <div style={{ width: '20%' }}>
                            <SideBar nodeId="10" />
                        </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Pengaturan Akun</h2>
                            {isMobile ? null :
                                <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Akun</h4>
                            }
                        </Stack>
                        {isMobile ? null :
                            <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight={"600"}>Pengaturan Akun</Typography>
                                <Tabs
                                    textColor={'secondary'}
                                    indicatorColor={'secondary'}
                                    value={menuActive}
                                    onChange={(e, value) => onChangeMenu(value)}
                                >
                                    <Tab label="Profil" />
                                    <Tab label="Kata Sandi" />
                                </Tabs>
                                {
                                    menuActive === 0 ?
                                        <ProfileSetting />
                                        :
                                        <PasswordSetting />
                                }
                            </Stack>
                        </div>
                    </Stack>
                </Stack>
            </div>
            <PasswordForm
                isOpen={PasswordModal}
                onClose={() => setPasswordModal(false)}
                onSuccess={() => {
                    setPasswordModal(false)
                    setMenuActive(1)
                }}
            />
        </div>
    )
}

export default AccountScreen;