import { Stack, Typography } from '@mui/material';
import React from 'react'
import { Images } from '../../assets/images';
import { Colors } from '../../utils/colors';

export const OrderSpec = (param: { data: any }) => {
    return (
        <Stack direction={'column'} gap={1} alignItems={'flex-start'} width={'100%'}>
            <Typography variant="body1" fontWeight={'600'} color={'#000'}>Spesifikasi Pesanan:</Typography>
            <br />
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>No. Pesanan:</Typography>
                <Typography width={'80%'} variant="body1" color={Colors.secondaryDark}><b>#{param.data.order_number}</b></Typography>
            </Stack>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Alamat Pengiriman:</Typography>
                <ul style={{ width: '80%' }}>
                    <li><Typography variant="body1" color={Colors.secondaryDark}>{param.data.origin.customer.email}</Typography></li>
                    <li><Typography variant="body1" color={Colors.secondaryDark}>{param.data.origin.customer.name}</Typography></li>
                    <li><Typography variant="body1" color={Colors.secondaryDark}>
                        {`${param.data.origin.address}, ${param.data.origin.subdistrict.type} ${param.data.origin.subdistrict.name}, ${param.data.origin.city.type} ${param.data.origin.city.name}, ${param.data.origin.province.name} (${param.data.origin.city.postal_code})`}
                    </Typography></li>
                    <li><Typography variant="body1" color={Colors.secondaryDark}>+62{param.data.origin.phone[0] === '0' ? param.data.origin.phone.slice(1) : param.data.origin.phone}</Typography></li>
                </ul>
            </Stack>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Info Jasa Kirim:</Typography>
                <Stack direction={'column'} gap={0} width={'80%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}><b>Pengiriman {param.data.shipping_info.shipping_package.service} {`(${param.data.shipping_info.courier_code})`}</b></Typography>
                    <Stack direction={'row'} alignItems={'center'}>
                        {param.data.details.length === 0 ?
                            <img
                                src={Images.noImage}
                                style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 10 }}
                                alt=""
                            />
                            :
                            <img
                                src={
                                    param.data.details[0].product === null ?
                                        Images.noImage
                                        :
                                        param.data.details[0].product.galleries.length === 0 ?
                                            Images.noImage
                                            : param.data.details[0].product.galleries[0].image_path
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = Images.noImage;
                                }}
                                style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 10 }}
                                alt=""
                            />
                        }
                        <ul>
                            <li><Typography variant="body1" color={Colors.secondaryDark}>Total {param.data.details.length} Produk</Typography></li>
                            <li><Typography variant="body1" color={Colors.secondaryDark}>Ongkir Rp{param.data.shipping_info.shipping_package.cost[0].value}</Typography></li>
                            <li><Typography variant="body1" color={Colors.secondaryDark}>Estimasi {param.data.shipping_info.shipping_package.cost[0].etd} Hari</Typography></li>
                        </ul>
                    </Stack>
                </Stack>
            </Stack>
        </Stack >
    )
}