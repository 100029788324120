import { Button, Icon, Stack, Typography, TextField, CircularProgress } from '@mui/material';
import React from 'react'
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';

const ProfileSetting = () => {
    const [isLoading, setLoading] = React.useState(false)
    const [NameActive, setNameActive] = React.useState(true)
    const [EmailActive, setEmailActive] = React.useState(true)

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile ? '100%' : '70%'} marginBottom={3}>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Nama:</Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={2} width={isMobile ? '100%' : '70%'}>
                        <TextField
                            type="text"
                            placeholder="Nama Akun"
                            size="small"
                            sx={{ bgcolor: NameActive ? '#ddd' : "white", width: '80%' }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                            disabled={NameActive}
                        />
                        <Button variant='text' color={"secondary"} onClick={() => setNameActive(!NameActive)}>Ubah</Button>
                    </Stack>
                </Stack>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body1" color={Colors.secondaryDark}>Email:</Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={2} width={isMobile ? '100%' : '70%'}>
                        <TextField
                            type="text"
                            placeholder="Email Akun"
                            size="small"
                            sx={{ bgcolor: EmailActive ? '#ddd' : "white", width: '80%' }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                            disabled={EmailActive}
                        />
                        <Button variant='text' color={"secondary"} onClick={() => setEmailActive(!EmailActive)}>Ubah</Button>
                    </Stack>
                </Stack>
            </Stack>
            <div style={{ width: '100%', ...CENTER }}>
                <Button
                    // onClick={ActionCustomer}
                    color={"secondary"}
                    variant={'contained'}
                    disabled={isLoading}
                    sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '20%' }}
                    startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                >Simpan</Button>
            </div>
        </div>
    )
}

export default ProfileSetting;