import React from "react";
import Page from "./routerTitle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouterInit } from "../utils/routerInit";

import LoginScreen from "../pages/auth/login";
import DashboardScreen from "../pages/dashboard/dashboard";
import CalendarScreen from "../pages/calendar/calendar";
import AccountScreen from "../pages/account/account";

import ProductBrandScreen from "../pages/products/brand";
import ProductCategoryScreen from "../pages/products/category";
import ProductMeScreen from "../pages/products/product";
import ProductMeFormScreen from "../pages/products/productForm";

import OrderMeScreen from "../pages/orders/order";
import OrderMeDetailScreen from "../pages/orders/orderDetail";
import OrderShippingSettingScreen from "../pages/orders/shippingSetting";
import OrderShippingCostScreen from "../pages/orders/shippingCost";

import TaskListScreen from "../pages/tasks/tasks";
import CreateTaskScreen from "../pages/tasks/create";

import PromotionVoucherScreen from "../pages/promotions/voucher";
import PromotionVoucherFormScreen from "../pages/promotions/voucherForm";
import PromotionBannerScreen from "../pages/promotions/banner";
import PromotionCategoryScreen from "../pages/promotions/categorySet";

import UserAdminScreen from "../pages/users/admin";
import UserCustomerScreen from "../pages/users/customer";
import UserAdminFormScreen from "../pages/users/adminForm";
import UserCustomerFormScreen from "../pages/users/customerForm";

import AboutUsFormScreen from "../pages/about/aboutUs";
import AboutCertificateScreen from "../pages/about/certificate";
import AboutFAQScreen from "../pages/about/faq";
import AboutFAQFormScreen from "../pages/about/faqForm";
import AboutPrivacPolicyScreen from "../pages/about/privacyPolicy";
import AboutArticleScreen from "../pages/about/article";
import AboutArticleFormScreen from "../pages/about/articleForm";

import FooterScreen from "../pages/footer/footer";

import SettingsScreen from "../pages/settings/settings";

const PageRoutes = () => {
  return (
    <Router>
      <RouterInit />
      <Routes>
        <Route
          path='/'
          element={
            <Page title='Auth | Angelo Admin'>
              <LoginScreen />
            </Page>
          }
        />
        {/* <Route
          path='/dashboard'
          element={
            <Page title='Dashboard | Angelo Admin'>
              <DashboardScreen />
            </Page>
          }
        /> */}
        <Route
          path='/calendar'
          element={
            <Page title='Kalender | Angelo Admin'>
              <CalendarScreen />
            </Page>
          }
        />
        <Route
          path='/account'
          element={
            <Page title='Akun | Angelo Admin'>
              <AccountScreen />
            </Page>
          }
        />

        <Route
          path='/product/brand'
          element={
            <Page title='Brand | Angelo Admin'>
              <ProductBrandScreen />
            </Page>
          }
        />
        <Route
          path='/product/category'
          element={
            <Page title='Kategori | Angelo Admin'>
              <ProductCategoryScreen />
            </Page>
          }
        />
        <Route
          path='/product/list'
          element={
            <Page title='Produk Saya | Angelo Admin'>
              <ProductMeScreen />
            </Page>
          }
        />
        <Route
          path='/product/:type'
          element={
            <Page title='Produk Saya | Angelo Admin'>
              <ProductMeFormScreen />
            </Page>
          }
        />

        <Route
          path='/order/list'
          element={
            <Page title='Pesanan Saya | Angelo Admin'>
              <OrderMeScreen />
            </Page>
          }
        />
        <Route
          path='/order/detail'
          element={
            <Page title='Pesanan Saya | Angelo Admin'>
              <OrderMeDetailScreen />
            </Page>
          }
        />
        <Route
          path='/order/setting'
          element={
            <Page title='Pengaturan Pengiriman | Angelo Admin'>
              <OrderShippingSettingScreen />
            </Page>
          }
        />
        <Route
          path='/order/shipping'
          element={
            <Page title='Ongkir & Pembayaran | Angelo Admin'>
              <OrderShippingCostScreen />
            </Page>
          }
        />

        <Route
          path='/task/list'
          element={
            <Page title='Task | Angelo Admin'>
              <TaskListScreen />
            </Page>
          }
        />
        <Route
          path='/task/create'
          element={
            <Page title='Buat Task | Angelo Admin'>
              <CreateTaskScreen />
            </Page>
          }
        />

        <Route
          path='/promotion/voucher'
          element={
            <Page title='Voucher | Angelo Admin'>
              <PromotionVoucherScreen />
            </Page>
          }
        />
        <Route
          path='/promotion/voucher/:type'
          element={
            <Page title='Voucher | Angelo Admin'>
              <PromotionVoucherFormScreen />
            </Page>
          }
        />
        <Route
          path='/promotion/banner'
          element={
            <Page title='Promosi | Angelo Admin'>
              <PromotionBannerScreen />
            </Page>
          }
        />
        <Route
          path='/promotion/category'
          element={
            <Page title='Kategori | Angelo Admin'>
              <PromotionCategoryScreen />
            </Page>
          }
        />

        <Route
          path='/user/admin'
          element={
            <Page title='Admin | Angelo Admin'>
              <UserAdminScreen />
            </Page>
          }
        />
        <Route
          path='/user/admin/:type'
          element={
            <Page title='Admin | Angelo Admin'>
              <UserAdminFormScreen />
            </Page>
          }
        />
        <Route
          path='/user/customer'
          element={
            <Page title='Pelanggan | Angelo Admin'>
              <UserCustomerScreen />
            </Page>
          }
        />
        <Route
          path='/user/customer/:type'
          element={
            <Page title='Pelanggan | Angelo Admin'>
              <UserCustomerFormScreen />
            </Page>
          }
        />

        <Route
          path='/about/us'
          element={
            <Page title='Tentang Kami | Angelo Admin'>
              <AboutUsFormScreen />
            </Page>
          }
        />
        <Route
          path='/about/certificate'
          element={
            <Page title='Sertifkat | Angelo Admin'>
              <AboutCertificateScreen />
            </Page>
          }
        />
        <Route
          path='/about/faq'
          element={
            <Page title='FAQ | Angelo Admin'>
              <AboutFAQScreen />
            </Page>
          }
        />
        <Route
          path='/about/faq/:type'
          element={
            <Page title='FAQ | Angelo Admin'>
              <AboutFAQFormScreen />
            </Page>
          }
        />
        <Route
          path='/about/privacy-policy'
          element={
            <Page title='Kebijakan Privasi | Angelo Admin'>
              <AboutPrivacPolicyScreen />
            </Page>
          }
        />
        <Route
          path='/about/article'
          element={
            <Page title='Artikel | Angelo Admin'>
              <AboutArticleScreen />
            </Page>
          }
        />
        <Route
          path='/about/article/:type'
          element={
            <Page title='Artikel | Angelo Admin'>
              <AboutArticleFormScreen />
            </Page>
          }
        />

        <Route
          path='/footer'
          element={
            <Page title='Footer | Angelo Admin'>
              <FooterScreen />
            </Page>
          }
        />

        <Route
          path='/settings'
          element={
            <Page title='Pengaturan | Angelo Admin'>
              <SettingsScreen />
            </Page>
          }
        />
      </Routes>
    </Router>
  );
};

export default PageRoutes;
