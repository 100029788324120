import { Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Icon, Button } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { CertificateDelete, CertificateForm } from '../../components/about/certificateModal';
import { toast } from 'react-toastify';
import { HTTPGetCertificates } from '../../apis/certificate';
import secureLocalStorage from 'react-secure-storage';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { Images } from '../../assets/images';

const AboutCertificateScreen = () => {
    const [isCertModal, setCertModal] = React.useState(false)
    const [isCertDelete, setCertDelete] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string
    const [CertData, setCertData] = React.useState([])
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [ModalType, setModalType] = React.useState('add')
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const GetCertificates = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetCertificates({ token, page })
            setCertData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetCertificates(value)
    }

    React.useEffect(() => {
        async function APIList() {
            await GetCertificates(1)
        }

        APIList().then().catch()
    }, [init])

    const onUpdate = (value: any) => {
        setSelectedItem(value)
        setModalType('edit')
        setCertModal(true)
    }

    const onDelete = (value: any) => {
        setSelectedItem(value)
        setCertDelete(true)
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="50" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Sertifikat</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tentang Angelo / Sertifikat</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Stack direction={'row'} alignItems={'center'} gap={isMobile ? 1 : 2} justifyContent={'space-between'} width={"100%"}>
                                    <Button
                                        onClick={() => { setModalType('add'); setCertModal(true) }}
                                        variant={'contained'}
                                        color={"secondary"}
                                        sx={{ backgroundColor: Colors.primary, padding: '10px 20px', }}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>add</Icon>}
                                    >{isMobile ? 'Tambah' : 'Tambah Sertifikat'}</Button>
                                    <Stack direction={"row"} gap={isMobile ? 1 : 2} alignItems="center">
                                        <Stack direction={"row"} gap={1} alignItems="center">
                                            <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                            <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                        </Stack>
                                        <IconButton>
                                            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.secondaryDark }}>delete</Icon>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                CertData.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah data Sertifikat terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" sx={{ width: '10%' }}><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '30%' }} align="left">Nama Sertifikat</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '30%' }} align="center">Gambar</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="right">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {CertData.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center">
                                                                            <img
                                                                                src={item.image}
                                                                                style={{ width: isMobile ? '50vw' : '100%', aspectRatio: 4 / 3, objectFit: 'contain' }}
                                                                                alt=""
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src = Images.noImage;
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'flex-end'}>
                                                                                <IconButton onClick={() => onUpdate(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onDelete(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination
                                        page={CurrentPage}
                                        count={TotalPage}
                                        onChange={onChangePage}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack >
            </div >
            <CertificateForm
                isOpen={isCertModal}
                onClose={() => setCertModal(false)}
                type={ModalType}
                getData={() => GetCertificates(CurrentPage)}
                item={SelectedItem}
            />
            <CertificateDelete
                isOpen={isCertDelete}
                onClose={() => setCertDelete(false)}
                getData={() => GetCertificates(CurrentPage)}
                item={SelectedItem}
            />
        </div >
    )
}

export default AboutCertificateScreen;