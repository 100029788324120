import { Button, Icon, Skeleton, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { OrderSpec } from '../../components/order/orderSpec';
import { OrderPayment } from '../../components/order/orderPayment';
import { ReasonForm } from '../../components/order/reasonModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';
import { HTTPGetOrdersByID } from '../../apis/orders';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { StatusList } from './data';

const OrderMeDetailScreen = () => {
    const navigate = useNavigate()
    const token = secureLocalStorage.getItem('token') as string
    const DetailRedux = useSelector((state: RootState) => state.orderID_data.id)
    const [init, setInit] = React.useState(false)
    const [isReason, setReason] = React.useState(false)
    const [DetailData, setDetailData] = React.useState<any>({})
    const [isLoading, setLoading] = React.useState(true)

    const GetDetailOrder = async () => {
        setLoading(true)
        try {
            const result = await HTTPGetOrdersByID({ order_id: DetailRedux, token })
            setDetailData(result.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetDetailOrder()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="70" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>RINCIAN PESANAN</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pesanan / Pesanan Saya</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        {
                            isLoading || DetailData.id === null || DetailData.id === undefined ?
                                <Stack direction={"column"} gap={3}>
                                    <Skeleton variant="rounded" width={'100%'} height={200} />
                                    <Skeleton variant="rounded" width={'100%'} height={300} />
                                    <Skeleton variant="rounded" width={'100%'} height={150} />
                                </Stack>
                                :
                                <Stack direction={"column"} gap={3}>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'}>
                                            <Button
                                                variant={'outlined'}
                                                sx={{ borderColor: Colors.secondary }}
                                                color={'secondary'}
                                                onClick={() => navigate(-1)}
                                                startIcon={<Icon fontSize={'small'} sx={{ color: Colors.secondaryDark }}>arrow_back</Icon>}
                                            >
                                                <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                                            </Button>
                                            <Typography variant="body1" fontWeight={'600'} color={Colors.secondaryDark}>Rincinan Pesanan</Typography>
                                        </Stack>
                                        <Toolbar />
                                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                                            <Icon fontSize={'medium'} sx={{ fontSize: '#000' }}>receipt</Icon>
                                            <Typography variant="h6" color={StatusList.find((val) => val.status === DetailData.status)?.color}><b>{StatusList.find((item) => item.status === DetailData.status)?.label}</b></Typography>
                                        </Stack>
                                        <Typography variant="body1" color={Colors.secondaryDark} width="70%">{StatusList.find((item) => item.status === DetailData.status)?.desc}</Typography>
                                    </div>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <OrderSpec data={DetailData} />
                                    </div>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <OrderPayment data={DetailData} />
                                    </div>
                                    <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={"100%"}>
                                            <Typography variant="h6" color="#000"><b>Penghasilan Akhir:</b></Typography>
                                            <Typography variant="h4" color={Colors.primary}>{DetailData.total_text}</Typography>
                                        </Stack>
                                    </div>
                                    {
                                        DetailData.status === 'RETURN_REQUESTED' ?
                                            <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                                <Stack direction={'row'} alignItems={'center'} width={"100%"} gap={2}>
                                                    <Button variant={'contained'} color={'secondary'} sx={{ backgroundColor: Colors.primary }}>Terima Pengajuan</Button>
                                                    <Button onClick={() => setReason(true)} variant={'contained'} color={'error'}>Tolak Pengajuan</Button>
                                                </Stack>
                                            </div>
                                            : null
                                    }
                                </Stack>
                        }
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <ReasonForm
                isOpen={isReason}
                onClose={() => setReason(false)}
                getData={() => { }}
                item={{}}
            />
        </div>
    )
}

export default OrderMeDetailScreen;