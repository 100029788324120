import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress, Grid, Select, MenuItem, ListItemSecondaryAction } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPGetCities, HTTPGetDistricts, HTTPGetProvinces } from '../../apis/locations';
import secureLocalStorage from 'react-secure-storage';
import { DeleteContent } from '../utils/deleteContent';
import { HTTPAddShopAddress, HTTPDeleteShopAddress, HTTPUpdateShopAddress } from '../../apis/shopAddress';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderAddressForm = (param: { isOpen: boolean, onClose: any, type: string, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const [Provinces, setProvinces] = React.useState([])
    const [Province, setProvince] = React.useState('')
    const [Cities, setCities] = React.useState([])
    const [City, setCity] = React.useState('')
    const [Districts, setDistricts] = React.useState([])
    const [District, setDistrict] = React.useState('')
    const [Address, setAddress] = React.useState('')
    const [Name, setName] = React.useState('')

    const onClose = () => {
        setProvince('')
        setCity('')
        setDistrict('')
        setAddress('')
        setName('')
        param.onClose()
    }

    const ActionAddress = async () => {
        setLoading(true)
        try {
            if (param.type === 'add') {
                await HTTPAddShopAddress({
                    token,
                    province_id: Province,
                    city_id: City,
                    subdistrict_id: District,
                    address: Address,
                    name: Name,
                    is_primary: 0
                })
                toast.success('Berhasil Menambahkan Data Alamat!')
            } else {
                await HTTPUpdateShopAddress({
                    token,
                    province_id: Province,
                    city_id: City,
                    subdistrict_id: District,
                    address: Address,
                    name: Name,
                    is_primary: 0,
                    address_id: param.item.id
                })
                toast.success('Berhasil Mengubah Data Alamat!')
            }
            setLoading(false)
            onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    const handleProvince = (item: any) => {
        setProvince(item.target.value)
        setCity('')
        setCities([])
        setDistrict('')
        setDistricts([])
        GetCities(item.target.value)
    }

    const handleCity = (item: any) => {
        setCity(item.target.value)
        setDistrict('')
        setDistricts([])
        GetDistricts(item.target.value)
    }

    const GetProvinces = async () => {
        try {
            const result = await HTTPGetProvinces()
            setProvinces(result.data.data)
        } catch (error) {
             
        }
    }

    const GetCities = async (province_id: string) => {
        try {
            const result = await HTTPGetCities({ province_id })
            setCities(result.data.data)
        } catch (error) {
             
        }
    }

    const GetDistricts = async (city_id: string) => {
        try {
            const result = await HTTPGetDistricts({ city_id })
            setDistricts(result.data.data)
        } catch (error) {
             
        }
    }

    const ProvinceValue = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>Provinsi</span>;
        } else {
            const result: any = Provinces.filter(
                (value: any) => value.id === Province
            );
            return <span style={{ color: "#000" }}>{result[0].name}</span>;
        }
    };

    const CityValue = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>Kota / Kab</span>;
        } else {
            const result: any = Cities.filter(
                (value: any) => value.id === City
            );
            return <span style={{ color: "#000" }}>{result[0].type === 'Kabupaten' ? 'Kab. ' + result[0].name : 'Kota ' + result[0].name}</span>;
        }
    };

    const DistrictValue = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>Kecamatan</span>;
        } else {
            const result: any = Districts.filter(
                (value: any) => value.id === District
            );
            return <span style={{ color: "#000" }}>{result[0].name}</span>;
        }
    };

    React.useEffect(() => {
        async function Initial() {
            if (param.isOpen === true) {
                await GetProvinces()
                if (param.type === 'edit') {
                    setTimeout(async () => {
                        await GetCities(param.item.province.id)
                        await GetDistricts(param.item.city.id)
                        setAddress(param.item.address)
                        setName(param.item.name)
                        setProvince(param.item.province.id)
                        setCity(param.item.city.id)
                        setDistrict(param.item.subdistrict.id)
                    }, 500)
                }
            }
        }

        Initial().then().catch()
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant="h6" fontWeight={'600'}>{param.type === 'add' ? 'Tambah Alamat' : 'Edit Alamat'}</Typography>
                        <Typography variant="body2">{'Pastikan alamat toko yang anda cantumkan benar.'}</Typography>
                    </Stack>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction={'column'} gap={3} width={'100%'}>
                    <Divider></Divider>
                    <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} gap={isMobile ? 1 : 3}>
                        <Typography variant="body1" color={Colors.secondary} width={'30%'}>Label:</Typography>
                        <TextField
                            type="text"
                            placeholder="Nama Alamat"
                            size="small"
                            sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                            value={Name}
                            onChange={(text) => setName(text.target.value)}
                        />
                    </Stack>
                    <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} gap={isMobile ? 1 : 3}>
                        <Typography variant="body1" color={Colors.secondary} width={'30%'}>Provinsi, Kab/Kota, Kecamatan:</Typography>
                        <Grid container spacing={2} sx={{ width: '70%' }}>
                            <Grid item md={6} xs={12}>
                                <Select
                                    displayEmpty
                                    size={'small'}
                                    MenuProps={{ style: { maxHeight: 500 } }}
                                    sx={{ bgcolor: "white", width: '100%' }}
                                    inputProps={{ sx: { borderRadius: 1 } }}
                                    value={Province}
                                    onChange={(e) => handleProvince(e)}
                                    renderValue={(selected: any) => ProvinceValue(selected)}
                                >
                                    {
                                        Provinces.map((item: any, index: number) => (
                                            <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Select
                                    displayEmpty
                                    size={'small'}
                                    disabled={Province.length === 0}
                                    MenuProps={{ style: { maxHeight: 500 } }}
                                    sx={{ bgcolor: "white", width: '100%' }}
                                    inputProps={{ sx: { borderRadius: 1 } }}
                                    value={City}
                                    onChange={(e) => handleCity(e)}
                                    renderValue={(selected: any) => CityValue(selected)}
                                >
                                    {
                                        Cities.map((item: any, index: number) => (
                                            <MenuItem value={item.id} key={index}>{item.type === 'Kabupaten' ? 'Kab. ' + item.name : 'Kota ' + item.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Select
                                    displayEmpty
                                    size={'small'}
                                    disabled={City.length === 0}
                                    MenuProps={{ style: { maxHeight: 500 } }}
                                    sx={{ bgcolor: "white", width: '100%' }}
                                    inputProps={{ sx: { borderRadius: 1 } }}
                                    value={District}
                                    onChange={(e) => setDistrict(e.target.value)}
                                    renderValue={(selected: any) => DistrictValue(selected)}
                                >
                                    {
                                        Districts.map((item: any, index: number) => (
                                            <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <TextField
                                    type="number"
                                    placeholder="Kode Pos"
                                    size="small"
                                    sx={{ bgcolor: "white", width: '100%' }}
                                    InputProps={{ sx: { borderRadius: 1 } }}
                                    value={PostCode}
                                    onChange={(text) => setPostCode(text.target.value)}
                                />
                            </Grid> */}
                        </Grid>
                    </Stack>
                    <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} gap={isMobile ? 1 : 3}>
                        <Typography variant="body1" color={Colors.secondary} width={'30%'}>Alamat Lengkap:</Typography>
                        <TextField
                            type="text"
                            placeholder="Nama jalan, Gedung, No. Rumah, dll"
                            size="small"
                            sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                            InputProps={{ sx: { borderRadius: 1 } }}
                            value={Address}
                            onChange={(text) => setAddress(text.target.value)}
                            onKeyDown={(e) => { if (e.keyCode === 13) ActionAddress() }}
                        />
                    </Stack>
                    <div style={{ width: '100%', ...CENTER }}>
                        <Button
                            onClick={ActionAddress}
                            color={"secondary"}
                            variant={'contained'}
                            disabled={isLoading}
                            sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                            startIcon={isLoading ?
                                <CircularProgress color="secondary" size={20} />
                                : <Icon fontSize={'small'}>save</Icon>}
                        >SIMPAN</Button>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const OrderAddressDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteAddress = async () => {
        setLoading(true)
        try {
            await HTTPDeleteShopAddress({ address_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
            toast.success('Berhasil Menghapus Alamat Toko')
        } catch (error) {
            setLoading(false)
             
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <DeleteContent
                    isLoading={isLoading}
                    onCancel={param.onClose}
                    onConfirm={DeleteAddress}
                    text={'alamat '}
                />
            </DialogContent>
        </Dialog>
    )
}