export const Images = {
  mainLogo: require("./mainlogo-light.png"),
  shopeeLogo: require("./shopee-logo.png"),
  mainlogoColor: require("./mainlogo.png"),
  logo: require("./logo-sm-dark.png"),
  logoTextLight: require("./logo-light.png"),
  banner: require("./banner.png"),
  bagPurple: require("./bag-purple.png"),
  loginBg: require("./login-img.png"),
  navbarBg: require("./bg-effect.png"),
  noImage: require("./no-image.png"),
  noImageProfile: require("./empty-profile.png"),
};
