import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import secureLocalStorage from 'react-secure-storage';
import { HTTPDeleteProduct } from '../../apis/products';
import { toast } from 'react-toastify';
import { DeleteContent } from '../utils/deleteContent';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ProductVariantModal = (param: { isOpen: boolean, onClose: any, item: any }) => {
    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
            scroll={'body'}
            PaperProps={{ style: { width: '70vw' } }}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Stack direction={'row'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="h6" fontWeight={'600'}>Detail Variasi</Typography>
                        <IconButton onClick={() => param.onClose()}>
                            <Icon sx={{ color: Colors.secondary }} fontSize={'medium'}>close</Icon>
                        </IconButton>
                    </Stack>
                    <TableContainer>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead sx={{ backgroundColor: '#e6e6e6' }}>
                                <TableRow>
                                    <TableCell sx={{ width: '10%', fontWeight: '700' }} align="center">No.</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Nama Variasi</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Sub Variasi</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Harga</TableCell>
                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Stok</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {param.item.map((item: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{item.variant.name_id}</TableCell>
                                        <TableCell align="center">{item.variant_sub === null ? <small><i>Tidak Ada Sub Variasi</i></small> : item.variant_sub.name_id}</TableCell>
                                        <TableCell align="center">Rp.{item.price}</TableCell>
                                        <TableCell align="center">{item.quantity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const ProductDelete = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteProduct = async () => {
        setLoading(true)
        try {
            await HTTPDeleteProduct({ product_id: param.item.id })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error("Terjadi Kesalahan")
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <DeleteContent
                    isLoading={isLoading}
                    onCancel={param.onClose}
                    onConfirm={DeleteProduct}
                    text={'produk ' + param.item.name}
                />
            </DialogContent>
        </Dialog>
    )
}