import { Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Icon, Button } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { HTTPGetFAQs } from '../../apis/faq';
import secureLocalStorage from 'react-secure-storage';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { useDispatch } from 'react-redux';
import { setFAQTempData } from '../../stores/data/faq';
import { FAQDelete } from '../../components/about/faqModal';

const AboutFAQScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = secureLocalStorage.getItem('token') as string
    const [FAQData, setFAQData] = React.useState([])
    const [isLoading, setLoading] = React.useState(true)
    const [init, setInit] = React.useState(true)
    const [isDelete, setDelete] = React.useState(false)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const GetFAQs = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetFAQs({ token, page })
            setFAQData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetFAQs(value)
    }

    const onUpdate = (value: any) => {
        dispatch(setFAQTempData({ data: value }))
        navigate('/about/faq/edit')
    }

    const onDelete = (value: any) => {
        setSelectedItem(value)
        setDelete(true)
    }

    React.useEffect(() => {
        async function Initial() {
            await GetFAQs(1)
        }
        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="50" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Frequently Ask Question</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tentang Angelo / FAQ</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Stack direction={'row'} alignItems={'center'} gap={isMobile ? 1 : 2} justifyContent={'space-between'} width={"100%"}>
                                    <Button
                                        onClick={() => navigate('/about/faq/add')}
                                        variant={'contained'}
                                        color={"secondary"}
                                        sx={{ backgroundColor: Colors.primary, padding: '10px 20px', }}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>add</Icon>}
                                    >{isMobile ? 'Tambah' : 'Tambah FAQ'}</Button>
                                    <Stack direction={"row"} gap={isMobile ? 1 : 2} alignItems="center">
                                        <Stack direction={"row"} gap={1} alignItems="center">
                                            <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                            <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                        </Stack>
                                        <IconButton>
                                            <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.secondaryDark }}>delete</Icon>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                FAQData.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah data FAQ terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" sx={{ width: '10%' }}><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">FAQ</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="right">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {FAQData.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell align="left">{item.question_id}</TableCell>
                                                                        <TableCell align="right">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'flex-end'}>
                                                                                <IconButton onClick={() => onUpdate(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onDelete(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination
                                        page={CurrentPage}
                                        count={TotalPage}
                                        onChange={onChangePage}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack >
            </div >
            <FAQDelete
                isOpen={isDelete}
                onClose={() => setDelete(false)}
                getData={() => GetFAQs(CurrentPage)}
                item={SelectedItem}
            />
        </div >
    )
}

export default AboutFAQScreen;