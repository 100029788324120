import { AxiosNormal } from "../utils/interceptors";
const url = "locations";

export function HTTPGetProvinces(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}/provinces`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetProvinceByID(param: {
  province_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(
        `${url}/provinces/${param.province_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCities(param: { province_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(
        `${url}/cities/${param.province_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetCityByID(param: { city_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(
        `${url}/cities/show/${param.city_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetDistricts(param: { city_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(
        `${url}/subdistricts/${param.city_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
