import { AxiosNormal } from "../utils/interceptors";
const url = "articles";

export function HTTPGetArticle(param: {
  token: string;
  page: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/get?page=${param.page}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

// export function HTTPGetArticleByID(param: {
//   article_id: string;
// }): Promise<any> {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await AxiosNormal().post(
//         `${url}/show/${param.article_id}`
//       );
//       return resolve(response);
//     } catch (error: any) {
//       return reject(error);
//     }
//   });
// }

export function HTTPAddArticle(param: {
  token: string;
  title_id: string;
  title_en: string;
  content_id: string;
  content_en: string;
  thumbnail: string;
  category_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
        title_id: param.title_id,
        title_en: param.title_en,
        content_id: param.content_id,
        content_en: param.content_en,
        thumbnail: param.thumbnail,
        category_id: param.category_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPEditArticle(param: {
  article_id: string;
  token: string;
  title_id: string;
  title_en: string;
  content_id: string;
  content_en: string;
  thumbnail: string;
  category_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().put(`${url}/${param.article_id}`, {
        token: param.token,
        title_id: param.title_id,
        title_en: param.title_en,
        content_id: param.content_id,
        content_en: param.content_en,
        thumbnail: param.thumbnail,
        category_id: param.category_id,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteArticle(param: {
  article_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/delete/${param.article_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
