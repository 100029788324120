import { Button, Icon, Stack, Typography, TextField, CircularProgress, Select, MenuItem } from '@mui/material';
import React from 'react'
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { HTTPGetReseller, HTTPUpdateReseller } from '../../apis/reseller';
import secureLocalStorage from 'react-secure-storage';
import { HTTPGetFinanceConfig, HTTPUpdateFinanceConfig } from '../../apis/settings';

export const Fee = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    const [FeeType, setFeeType] = React.useState('Nominal')
    const [FeeValue, setFeeValue] = React.useState('')
    const [FinanceValue, setFinanceValue] = React.useState({
        service_fee_type: '',
        service_fee_value: 0,
        tax_type: '',
        tax_value: 0,
    })

    const GetFee = async () => {
        try {
            const result = await HTTPGetFinanceConfig()
            setFeeValue((result.data.data.service_fee_value as string).toString())
            setFeeType(result.data.data.service_fee_type === 'fixed' ? 'Nominal' : 'Persentase')
            setFinanceValue(result.data.data)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const UpdateFee = async () => {
        setLoading(true)
        try {
            await HTTPUpdateFinanceConfig({
                token,
                service_fee_type: FeeType === 'Nominal' ? 'fixed' : 'persentage',
                service_fee_value: parseInt(FeeValue),
                tax_type: FinanceValue.tax_type,
                tax_value: FinanceValue.tax_value,
            })
            toast.success('Berhasil mengubah Biaya Admin!')
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetFee()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%' }}>
            <Stack
                marginBottom={3}
                direction={isMobile ? 'column' : 'row'}
                gap={5}
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent={'space-between'}
                width={isMobile ? '100%' : '70%'}
            >
                <Stack direction={"column"} gap={1} width="40%">
                    <Typography variant="body1" color={Colors.secondaryDark}>Pengaturan Fee / Biaya Admin:</Typography>
                    <Typography variant="body2" color={Colors.secondaryDark}>Fee / Biaya Admin ini akan tampil dan ter-hitung pada setiap 1x transaksi customer.</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={'center'} width={'60%'}>
                    <Select
                        displayEmpty
                        size="small"
                        MenuProps={{
                            style: { maxHeight: 500 }
                        }}
                        sx={{ bgcolor: "white", width: '40%' }}
                        inputProps={{ sx: { borderRadius: 1 } }}
                        value={FeeType}
                        onChange={(e) => setFeeType(e.target.value)}
                    >
                        {
                            ['Nominal', 'Persentase'].map((item) => (
                                <MenuItem value={item} key={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                    {
                        FeeType === 'Nominal' ?
                            <TextField
                                placeholder="Nominal Biaya Admin"
                                size="small"
                                type={'number'}
                                sx={{ bgcolor: "white", width: '60%' }}
                                value={FeeValue}
                                onChange={(text) => setFeeValue(text.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                            <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                            <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                            <div></div>
                                        </Stack>
                                    ),
                                    sx: { borderRadius: 1 },
                                }}
                            />
                            :
                            <TextField
                                placeholder="Jumlah Persen"
                                size="small"
                                type={'number'}
                                sx={{ bgcolor: "white", width: '60%' }}
                                value={FeeValue}
                                onChange={(text) => setFeeValue(parseInt(text.target.value) > 100 ? '100' : text.target.value)}
                                InputProps={{
                                    endAdornment: <Typography variant={'body1'} marginLeft={1} width={'30%'} color={Colors.secondaryDark}> % Biaya Admin</Typography>,
                                    sx: { borderRadius: 1 },
                                }}
                            />
                    }
                </Stack>
            </Stack>
            <div style={{ width: '100%', ...CENTER }}>
                <Button
                    onClick={UpdateFee}
                    color={"secondary"}
                    variant={'contained'}
                    disabled={isLoading}
                    sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '20%' }}
                    startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                >Simpan</Button>
            </div>
        </div>
    )
}