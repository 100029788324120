import { Stack, Typography, Icon, TextField, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { isMobile } from 'react-device-detect';
import { Colors } from '../../../utils/colors';

export const ProductShipping = (param: { initialData: any; finalData: any; type: string }) => {
    const [init, setInit] = React.useState(false)
    const [Weight, setWeight] = React.useState('')
    const [Length, setLength] = React.useState('')
    const [Width, setWidth] = React.useState('')
    const [Height, setHeight] = React.useState('')
    const [isPreorder, setPreorder] = React.useState('no')
    const [PreorderPeriod, setPreorderPeriod] = React.useState('')
    const [Condition, setCondition] = React.useState('baru')
    const [SKU, setSKU] = React.useState('')
    const [LatestUpdate, setLatestUpdate] = React.useState('')

    const InputData = (e: any, type: string) => {
        const text = e.target.value
        if (type === 'weight') {
            setWeight(text)
        } else if (type === 'length') {
            setLength(text)
        } else if (type === 'width') {
            setWidth(text)
        } else if (type === 'height') {
            setHeight(text)
        } else if (type === 'preorder') {
            setPreorder(text)
        } else if (type === 'period') {
            setPreorderPeriod(text)
        } else if (type === 'condition') {
            setCondition(text)
        } else if (type === 'sku') {
            setSKU(text)
        } else {
            setLatestUpdate(`InputData ${type} ${moment().format('x')}`)
        }
        setLatestUpdate(`InputData ${type} ${moment().format('x')}`)
    }

    const InputUpdates = () => {
        const data = {
            weight: Weight,
            dimension_width: Width,
            dimension_height: Height,
            dimension_length: Length,
            is_pre_order: isPreorder,
            is_new: Condition,
            sku: SKU,
            pre_order_days: PreorderPeriod
        }
        param.finalData(data)
    }

    const InitialData = () => {
        const data = param.initialData
        setLength(data['length'])
        setWeight(data.weight)
        setWidth(data.width)
        setHeight(data.height)
        setPreorder(data.isPreorder)
        setPreorderPeriod(data.preOrderDays)
        setCondition(data.condition)
        setSKU(data.sku)
        setLatestUpdate(`Intial Data ${moment().format('x')}`)
    }

    React.useEffect(() => {
        function Initial() {
            if (param.type === 'edit') {
                InitialData()
            }
        }
        Initial()
    }, [init])

    React.useEffect(() => {
        setTimeout(() => {
            InputUpdates()
        }, 1000)
    }, [LatestUpdate])

    return (
        <Stack direction={'column'} gap={3} alignItems={'flex-start'} width={'100%'}>
            <Typography variant="body1" fontWeight={'600'} color={'#000'}>Pengiriman:</Typography>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>*Berat:</Typography>
                <TextField
                    type="number"
                    placeholder="Masukkan Berat"
                    size="small"
                    sx={{ bgcolor: "white", width: '30%' }}
                    value={Weight}
                    onChange={(text) => InputData(text, 'weight')}
                    InputProps={{
                        sx: { borderRadius: 1 },
                        endAdornment: (
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <div></div>
                                <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                <Typography variant={'body1'} color={Colors.secondary}>gr</Typography>
                            </Stack>
                        )
                    }}
                />
            </Stack>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Ukuran Paket:</Typography>
                <Stack direction={'row'} gap={1} alignItems={"center"} width={'70%'}>
                    <TextField
                        type="number"
                        placeholder="L"
                        size="small"
                        sx={{ bgcolor: "white", width: '30%' }}
                        value={Width}
                        onChange={(text) => InputData(text, 'width')}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: (
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <div></div>
                                    <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                    <Typography variant={'body1'} color={Colors.secondary}>cm</Typography>
                                </Stack>
                            )
                        }}
                    />
                    <Icon fontSize={'medium'} sx={{ color: Colors.secondary }}>close</Icon>
                    <TextField
                        type="number"
                        placeholder="P"
                        size="small"
                        sx={{ bgcolor: "white", width: '30%' }}
                        value={Length}
                        onChange={(text) => InputData(text, 'length')}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: (
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <div></div>
                                    <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                    <Typography variant={'body1'} color={Colors.secondary}>cm</Typography>
                                </Stack>
                            )
                        }}
                    />
                    <Icon fontSize={'medium'} sx={{ color: Colors.secondary }}>close</Icon>
                    <TextField
                        type="number"
                        placeholder="T"
                        size="small"
                        sx={{ bgcolor: "white", width: '30%' }}
                        value={Height}
                        onChange={(text) => InputData(text, 'height')}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: (
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <div></div>
                                    <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                    <Typography variant={'body1'} color={Colors.secondary}>cm</Typography>
                                </Stack>
                            )
                        }}
                    />
                </Stack>
            </Stack>
            <Typography variant="body1" fontWeight={'600'} color={'#000'}>Lainnya:</Typography>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Pre-order:</Typography>
                <Stack direction={"column"} gap={1}>
                    <RadioGroup row value={isPreorder} onChange={(e) => InputData(e, 'preorder')}>
                        <FormControlLabel value={'no'} control={<Radio color={"secondary"} />} label="Tidak" />
                        <FormControlLabel value={'yes'} control={<Radio color={"secondary"} />} label="Ya" />
                    </RadioGroup>
                    {
                        isPreorder === 'no' ?
                            <Typography variant={'body2'} color={Colors.secondary}>{'Kirimkan produk dalam 2 hari (tidak termasuk hari Sabtu, Minggu, libur nasional dan non-operasional jasa kirim).'}</Typography>
                            :
                            <Stack direction={'column'} gap={1}>
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <Typography variant={'body2'} color={Colors.secondary}>{'Saya memerlukan'}</Typography>
                                    <TextField
                                        type="number"
                                        placeholder="0"
                                        size="small"
                                        sx={{ bgcolor: "white", width: '8ch' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={PreorderPeriod}
                                        onChange={(text) => InputData(text, 'period')}
                                    />
                                    <Typography variant={'body2'} color={Colors.secondary}>{'Masa pengemasan (atur dari 7 sampai 30 hari)'}</Typography>
                                </Stack>
                                <Typography variant={'body2'} color={Colors.error}>{'Mohon ubah jumlah hari-mu, karena jumlah hari dalam status Dikirim Dalam-mu di luar batas waktu'}</Typography>
                            </Stack>
                    }
                </Stack>
            </Stack>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>Kondisi:</Typography>
                <FormControl>
                    <RadioGroup row value={Condition} onChange={(text) => InputData(text, 'condition')}>
                        <FormControlLabel value="baru" control={<Radio color={"secondary"} />} label="Baru" />
                        <FormControlLabel value="second" control={<Radio color={"secondary"} />} label="Pernah Dipakai" />
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Stack direction={'row'} gap={2} width={'100%'} alignItems={'center'}>
                <Typography width={'20%'} variant="body1" color={Colors.secondaryDark}>SKU Induk:</Typography>
                <TextField
                    type="text"
                    placeholder="-"
                    size="small"
                    sx={{ bgcolor: "white", width: '30%' }}
                    value={SKU}
                    onChange={(text) => InputData(text, 'sku')}
                    InputProps={{ sx: { borderRadius: 1 }, }}
                />
            </Stack>
        </Stack >
    )
}