import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ReasonForm = (param: { isOpen: boolean, onClose: any, getData: any, item: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const [Reason, setReason] = React.useState('')

    const onClose = () => {
        setReason('')
        param.onClose()
    }

    const ActionReason = async () => {
        setLoading(true)
        try {
            // await HTTPAddReason({ name: Reason })
            setLoading(false)
            onClose()
            param.getData()
            toast.success('Berhasil Menolak Pengajuan')
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>Alasan Penolakan</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Stack direction={isMobile ? 'column' : "row"} alignItems={'flex-start'} gap={isMobile ? 1 : 3} marginY={isMobile ? 3 : 5}>
                    <Typography variant="body1" color={Colors.secondary}>Tulis Alasan Penolakan:</Typography>
                    <TextField
                        type="text"
                        placeholder="Tulis Alasan Anda..."
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={Reason}
                        onChange={(text) => setReason(text.target.value)}
                        onKeyDown={(e) => { if (e.keyCode === 13) ActionReason() }}
                        multiline
                        rows={5}
                    />
                </Stack>
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        onClick={ActionReason}
                        color={"secondary"}
                        variant={'contained'}
                        disabled={isLoading}
                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                    >SIMPAN</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}