import { AxiosNormal } from "../utils/interceptors";
const url = "privacy_policies";

export function HTTPGetPrivacyPolicy(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/get`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdatePrivacyPolicy(param: {
  token: string;
  title_id: string;
  title_en: string;
  content_id: string;
  content_en: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/update`, {
        token: param.token,
        title_id: param.title_id,
        title_en: param.title_en,
        content_id: param.content_id,
        content_en: param.content_en,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
