import React from 'react';
import './App.css';
import PageRoutes from './routes/router';
import moment from 'moment';
import 'moment/locale/id'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

moment.locale('id')
const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <PageRoutes></PageRoutes>
      </LocalizationProvider>
      <ToastContainer
        position={"top-right"}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme={"light"}
      />
    </div>
  );
}

export default App;
