import { Skeleton, Stack } from '@mui/material';
import * as React from 'react';

export const SkeletonListLoader = () => {
    return (
        <Stack direction={'column'} spacing={2} width={'100%'}>
            {[...Array(10)].map((item, index) => (
                <Skeleton key={index} variant="rounded" width={'100%'} height={60} />
            ))}
        </Stack>
    )
}