import { Button, CircularProgress, Divider, Icon, MenuItem, Select, Stack, Typography } from '@mui/material';
import React from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPGetOperationalTime, HTTPUpdateOperationalTime } from '../../apis/operationalTime';
import secureLocalStorage from 'react-secure-storage';
import { isMobile } from 'react-device-detect';

const GMT = ['WIB', 'WITA', 'WIT']

const Days = [
    { id: '1', label: 'Senin' },
    { id: '2', label: 'Selasa' },
    { id: '3', label: 'Rabu' },
    { id: '4', label: 'Kamis' },
    { id: '5', label: "Jum'at" },
    { id: '6', label: 'Sabtu' },
    { id: '7', label: 'Minggu' },
]

const OperationalTime = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [dayFrom, setDayFrom] = React.useState('')
    const [dayTo, setDayTo] = React.useState('')
    const [timeFrom, setTimeFrom] = React.useState<any>(moment())
    const [timeTo, setTimeTo] = React.useState<any>(moment())
    const [timeZone, setTimeZone] = React.useState('WIB')
    const [isLoading, setLoading] = React.useState(false)

    const GetSchedule = async () => {
        try {
            const result = await HTTPGetOperationalTime({ token })
            const data = result.data.data
            const startDay = Days.filter((value: any) => value.label === data.start_day.id)
            const endDay = Days.filter((value: any) => value.label === data.end_day.id)
            setDayFrom(startDay[0].id)
            setDayTo(endDay[0].id)
            setTimeFrom(moment(data.start_time, 'HH:mm'))
            setTimeTo(moment(data.end_time, 'HH:mm'))
            setTimeZone(data.timezone)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const UpdateSchedule = async () => {
        setLoading(true)
        try {
            await HTTPUpdateOperationalTime({
                token,
                start_day: parseInt(dayFrom),
                end_day: parseInt(dayTo),
                start_time: moment(timeFrom).format('HH:mm'),
                end_time: moment(timeTo).format('HH:mm'),
                timezone: timeZone
            })
            toast.success('Berhasil mengubah Jam Operasional!')
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetSchedule()
        }

        Initial().then().catch()
    }, [init])

    const DayValue = (values: any, type: string) => {
        if (values.length === 0) {
            return <span style={{ color: "#a7a5a6" }}>{type === 'from' ? 'Senin' : 'Minggu'}</span>;
        } else {
            const result: any = Days.filter(
                (value: any) => value.id === (type === 'from' ? dayFrom : dayTo)
            );
            return <span style={{ color: "#000" }}>{result[0].label}</span>;
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={'space-between'}>
                <Typography variant="body1" color="#000">{'Hari & Jam Operasional:'}</Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Select
                        displayEmpty
                        MenuProps={{ style: { maxHeight: 500 } }}
                        sx={{ bgcolor: "white" }}
                        inputProps={{ sx: { borderRadius: 1 } }}
                        value={dayFrom}
                        onChange={(e) => setDayFrom(e.target.value)}
                        renderValue={(selected: any) => DayValue(selected, 'from')}
                    >
                        {
                            Days.map((item: any, index: number) => (
                                <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                            ))
                        }
                    </Select>
                    <div style={{ width: 30, height: 1, backgroundColor: '#000' }}></div>
                    <Select
                        displayEmpty
                        MenuProps={{ style: { maxHeight: 500 } }}
                        sx={{ bgcolor: "white" }}
                        inputProps={{ sx: { borderRadius: 1 } }}
                        value={dayTo}
                        onChange={(e) => setDayTo(e.target.value)}
                        renderValue={(selected: any) => DayValue(selected, 'to')}
                    >
                        {
                            Days.map((item: any, index: number) => (
                                <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
                            ))
                        }
                    </Select>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={1} width={"30%"}>
                    <TimePicker
                        value={timeFrom}
                        onChange={(time) => setTimeFrom(time)}
                        sx={{
                            bgcolor: "white",
                            borderRadius: 1,
                        }}
                    />
                    <div style={{ width: 30, height: 1, backgroundColor: '#000' }}></div>
                    <TimePicker
                        value={timeTo}
                        onChange={(time) => setTimeTo(time)}
                        sx={{
                            bgcolor: "white",
                            borderRadius: 1,
                        }}
                    />
                </Stack>
                <Select
                    displayEmpty
                    MenuProps={{ style: { maxHeight: 500 } }}
                    sx={{ bgcolor: "white" }}
                    inputProps={{ sx: { borderRadius: 1 } }}
                    value={timeZone}
                    onChange={(e) => setTimeZone(e.target.value)}
                >
                    {
                        GMT.map((item: any, index: number) => (
                            <MenuItem value={item} key={index}>{item}</MenuItem>
                        ))
                    }
                </Select>
            </Stack>
            <Divider sx={{ marginY: 5 }} />
            <div style={{ width: '100%', ...CENTER }}>
                <Button
                    onClick={UpdateSchedule}
                    color={"secondary"}
                    variant={'contained'}
                    disabled={isLoading}
                    sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '20%' }}
                    startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                >Simpan</Button>
            </div>
        </div>
    )
}

export default OperationalTime;