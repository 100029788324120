import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { HTTPUploadFile } from '../../apis/utils';
import { CENTER, Colors } from '../../utils/colors';

export const ImagePicker = (param: { onResult: any, initValue: string }) => {
    const [isImageLoading, setImageLoading] = React.useState(false)
    const [ImageResult, setImageResult] = React.useState('')

    const onImageUpdate = async (file: any) => {
        setImageLoading(true)
        try {
            const Forms: any = new FormData()
            Forms.append('file', file)
            Forms.append('type', 'brand_pic')
            const result = await HTTPUploadFile({ form: Forms })
            setImageResult(result.data.data)
            param.onResult(result.data.data)
            setImageLoading(false)
        } catch (error) {
            setImageLoading(false)
            toast.error('Terjadi kesalahan saat mengunggah foto!')
             
        }
    }

    const SetImage = async (file: any) => {
        if (file[0].size > 3145728) {
            toast.error('Ukuran file harus dibawah 3MB')
        } else {
            await onImageUpdate(file[0])
        }
    }

    const { getRootProps, open } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: SetImage,
        multiple: false,
    });

    React.useEffect(() => {
        function Initial() {
            setImageResult(param.initValue)
        }

        Initial()
    }, [param.initValue])

    return (
        <Stack direction={isMobile ? 'column' : 'row'} gap={2} sx={{ width: '100%' }}>
            {
                isImageLoading ?
                    <div style={{ width: '30%', aspectRatio: 1, backgroundColor: '#ddd', ...CENTER }}>
                        <CircularProgress color="secondary" size={50} />
                    </div>
                    :
                    <>
                        {
                            ImageResult.length === 0 ?
                                <div
                                    {...getRootProps({ className: "dropzone" })}
                                    style={{
                                        ...CENTER,
                                        border: `2px dashed ${Colors.primary}`,
                                        padding: "10px 0",
                                        borderRadius: 10,
                                        cursor: "pointer",
                                        width: isMobile ? '100%' : '30%',
                                        position: 'relative',
                                        aspectRatio: 16 / 9
                                    }}
                                >
                                    <Typography variant="h6" color={Colors.primary}>{isMobile ? 'PILIH GAMBAR' : 'DROP IMAGE'}</Typography>
                                </div>
                                :
                                <img src={ImageResult} style={{ width: '30%', aspectRatio: 1, objectFit: 'cover' }} alt="" />
                        }
                    </>
            }
            {
                isMobile ? null :
                    <Stack direction={'column'} gap={0}>
                        <Typography variant={"body1"} color={Colors.secondaryDark}>Rasio: 10:2</Typography>
                        <Typography variant={"body1"} color={Colors.secondaryDark}>Ukuran gambar: maks 1MB</Typography>
                        <Typography variant={"body1"} color={Colors.secondaryDark}>Format gambar: .JPEG, .PNG</Typography>
                        <Button onClick={open} color={'secondary'} variant={"outlined"} sx={{ borderColor: Colors.secondaryDark, color: '#000', marginTop: 3 }}>Pilih Gambar</Button>
                    </Stack>
            }
        </Stack>
    )
}