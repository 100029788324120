import { Button, Icon, Stack, Typography, TextField, CircularProgress, Select, MenuItem } from '@mui/material';
import React from 'react'
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { HTTPGetFinanceConfig, HTTPUpdateFinanceConfig } from '../../apis/settings';

export const Tax = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    const [TaxType, setTaxType] = React.useState('Nominal')
    const [TaxValue, setTaxValue] = React.useState('')
    const [FinanceValue, setFinanceValue] = React.useState({
        service_fee_type: '',
        service_fee_value: 0,
        tax_type: '',
        tax_value: 0,
    })

    const GetTax = async () => {
        try {
            const result = await HTTPGetFinanceConfig()
            setTaxValue((result.data.data.tax_value as string).toString())
            setTaxType(result.data.data.tax_type === 'fixed' ? 'Nominal' : 'Persentase')
            setFinanceValue(result.data.data)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const UpdateTax = async () => {
        setLoading(true)
        try {
            await HTTPUpdateFinanceConfig({
                token,
                tax_type: TaxType === 'Nominal' ? 'fixed' : 'percentage',
                tax_value: parseInt(TaxValue),
                service_fee_type: FinanceValue.service_fee_type,
                service_fee_value: FinanceValue.service_fee_value,
            })
            toast.success('Berhasil mengubah Biaya Pajak!')
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetTax()
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%' }}>
            <Stack
                marginBottom={3}
                direction={isMobile ? 'column' : 'row'}
                gap={5}
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent={'space-between'}
                width={isMobile ? '100%' : '70%'}
            >
                <Stack direction={"column"} gap={1} width="40%">
                    <Typography variant="body1" color={Colors.secondaryDark}>Pengaturan Pajak / Tax:</Typography>
                    <Typography variant="body2" color={Colors.secondaryDark}>Tax / Pajak ini akan tampil dan ter-hitung pada setiap 1x transaksi customer.</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={'center'} width="60%">
                    <Select
                        displayEmpty
                        size="small"
                        MenuProps={{
                            style: { maxHeight: 500 }
                        }}
                        sx={{ bgcolor: "white", width: '40%' }}
                        inputProps={{ sx: { borderRadius: 1 } }}
                        value={TaxType}
                        onChange={(e) => {
                            setTaxValue('')
                            setTaxType(e.target.value);
                        }}
                    >
                        {
                            ['Nominal', 'Persentase'].map((item) => (
                                <MenuItem value={item} key={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                    {
                        TaxType === 'Nominal' ?
                            <TextField
                                placeholder="Nominal Pajak"
                                size="small"
                                type={'number'}
                                sx={{ bgcolor: "white", width: '100%' }}
                                value={TaxValue}
                                onChange={(text) => setTaxValue(text.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                                            <Typography variant={'body1'} color={Colors.secondary}>Rp</Typography>
                                            <div style={{ height: 15, width: 1, backgroundColor: Colors.secondary }}></div>
                                            <div></div>
                                        </Stack>
                                    ),
                                    sx: { borderRadius: 1 },
                                }}
                            />
                            :
                            <TextField
                                placeholder="Jumlah Persen"
                                size="small"
                                type={'number'}
                                sx={{ bgcolor: "white", width: '100%' }}
                                value={TaxValue}
                                onChange={(text) => setTaxValue(parseInt(text.target.value) > 100 ? '100' : text.target.value)}
                                InputProps={{
                                    endAdornment: <Typography variant={'body1'} marginLeft={1} width={'30%'} color={Colors.secondaryDark}> % Pajak</Typography>,
                                    sx: { borderRadius: 1 },
                                }}
                            />
                    }
                </Stack>
            </Stack>
            <div style={{ width: '100%', ...CENTER }}>
                <Button
                    onClick={UpdateTax}
                    color={"secondary"}
                    variant={'contained'}
                    disabled={isLoading}
                    sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '20%' }}
                    startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                >Simpan</Button>
            </div>
        </div>
    )
}