import { AxiosNormal } from "../utils/interceptors";
const url = "footer/operational_times";

export function HTTPGetOperationalTime(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateOperationalTime(param: {
  token: string;
  start_day: number;
  end_day: number;
  start_time: string;
  end_time: string;
  timezone: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/update`, {
        token: param.token,
        start_day: param.start_day,
        end_day: param.end_day,
        start_time: param.start_time,
        end_time: param.end_time,
        timezone: param.timezone,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
