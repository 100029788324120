import { Button, Icon, Stack, Toolbar, Typography, TextField, CircularProgress } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import secureLocalStorage from 'react-secure-storage';
import "react-quill/dist/quill.snow.css";
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { HTTPGetAbout, HTTPUpdateAbout } from '../../apis/about';
import './style.css'

const AboutUsFormScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [Title, setTitle] = React.useState('')
    const [Content, setContent] = React.useState('')
    const [onEdit, setEdit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)

    const GetAboutData = async () => {
        try {
            const result = await HTTPGetAbout({ token })
            setTitle(result.data.data.title_id)
            setContent(result.data.data.description_id)
        } catch (error) {
            toast.error('Terjadi Kesalahan!')
             
        }
    }

    const UpdateAboutData = async () => {
        setLoading(true)
        try {
            await HTTPUpdateAbout({
                token,
                title_id: Title,
                title_en: Title,
                description_id: Content,
                description_en: Content
            })
            toast.success('Berhasil mengubah data!')
            setLoading(false)
            setEdit(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan ketika mengubah data')
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await GetAboutData()
        }
        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="50" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Tentang Angelo Skin</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tentang Angelo / Tentang Kami</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'} width={'100%'}>
                                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="h5" fontWeight={'600'} color={'#000'}>Pengaturan Tentang Kami</Typography>
                                    {
                                        onEdit ? null :
                                            <Button
                                                variant={'contained'}
                                                sx={{ borderColor: Colors.warning, padding: '10px 20px', borderRadius: 2 }}
                                                color={'warning'}
                                                onClick={() => setEdit(true)}
                                                endIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>edit</Icon>}
                                            >{'UBAH'}</Button>
                                    }
                                </Stack>
                                <Stack direction={'column'} gap={2} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Judul:</Typography>
                                    <TextField
                                        type="text"
                                        placeholder="Judul FAQ"
                                        size="small"
                                        sx={{ bgcolor: onEdit ? 'white' : "#ddd", width: '100%' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={Title}
                                        onChange={(text) => setTitle(text.target.value)}
                                        disabled={!onEdit}
                                    />
                                </Stack>
                                <Stack direction={'column'} gap={2} width={'100%'} sx={{ position: 'relative' }}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Deskripsi:</Typography>
                                    <ReactQuill
                                        readOnly={!onEdit}
                                        value={Content}
                                        theme={"snow"}
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, false] }],
                                                ["bold", "italic", "underline", "strike", "blockquote"],
                                                [
                                                    { list: "ordered" },
                                                    { list: "bullet" },
                                                    { indent: "-1" },
                                                    { indent: "+1" },
                                                ],
                                                ["link", "clean", "video"],
                                            ],
                                        }}
                                        formats={[
                                            "header",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                            "list",
                                            "bullet",
                                            "indent",
                                            "link",
                                            "video"
                                        ]}
                                        onChange={(value) => setContent(value)}
                                        style={{ backgroundColor: onEdit ? '#fff' : '#ddd'}}
                                    />
                                </Stack>
                                {
                                    onEdit ?
                                        <div style={{ width: '100%', ...CENTER }}>
                                            <Button
                                                onClick={UpdateAboutData}
                                                color={"secondary"}
                                                variant={'contained'}
                                                disabled={isLoading}
                                                sx={{ backgroundColor: Colors.primary, width: '30%', marginTop: 5 }}
                                                startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                                            >Simpan</Button>
                                        </div>
                                        :
                                        <Toolbar></Toolbar>
                                }
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default AboutUsFormScreen;