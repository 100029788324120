import {
  Pagination,
  Stack,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Icon,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import React from "react";
import NavigationBar from "../../components/navigation/navigationBar";
import SideBar from "../../components/navigation/sideBar";
import { CENTER, Colors } from "../../utils/colors";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import {
  ArticleCategoryDelete,
  ArticleDelete,
  CategoryArticleForm,
} from "../../components/about/articleModal";
import { toast } from "react-toastify";
import { HTTPGetArticle } from "../../apis/article";
import secureLocalStorage from "react-secure-storage";
import { SkeletonListLoader } from "../../components/loader/skeleton";
import { Images } from "../../assets/images";
import { useDispatch } from "react-redux";
import { setArticleTempData } from "../../stores/data/article";
import { HTTPGetArticleCategories } from "../../apis/articleCategories";

const AboutArticleScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = secureLocalStorage.getItem("token") as string;

  const [menuActive, setMenuActive] = React.useState(0);
  const [isArticleModal, setArticleModal] = React.useState(false);
  const [isArticleDelete, setArticleDelete] = React.useState(false);
  const [isArticleCategoryDelete, setArticleCategoryDelete] =
    React.useState(false);
  const [ArticleData, setArticleData] = React.useState([]);
  const [CategoryData, setCategoryData] = React.useState([]);
  const [CategoryType, setCategoryType] = React.useState("");
  const [isLoading, setLoading] = React.useState(true);
  const [SelectedData, setSelectedData] = React.useState({});
  const [CurrentPage, setCurrentPage] = React.useState(1);
  const [TotalData, setTotalData] = React.useState(0);
  const [TotalPage, setTotalPage] = React.useState(1);

  const GetDataList = async (page: number) => {
    setLoading(true);
    try {
      if (menuActive === 0) {
        const result = await HTTPGetArticle({ token, page });
        setArticleData(result.data.data);
        setTotalPage(result.data.meta.pagination.last_page);
        setTotalData(result.data.meta.pagination.total);
      } else {
        const result = await HTTPGetArticleCategories({ token, page });
        setCategoryData(result.data.data);
        setTotalPage(result.data.meta.pagination.last_page);
        setTotalData(result.data.meta.pagination.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Terjadi Kesalahan");
    }
  };

  const onChangePage = async (e: any, value: number) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
    await GetDataList(value);
  };

  React.useEffect(() => {
    async function Initial() {
      await GetDataList(1);
    }

    Initial().then().catch();
  }, [menuActive]);

  const onUpdate = (value: any) => {
    if (menuActive === 0) {
      dispatch(setArticleTempData({ data: value }));
      navigate("/about/article/edit");
    } else {
      setCategoryType("edit");
      setSelectedData(value);
      setArticleModal(true);
    }
  };

  const onDelete = (value: any) => {
    setSelectedData(value);
    if (menuActive === 0) {
      setArticleDelete(true);
    } else {
      setArticleCategoryDelete(true);
    }
  };

  const RenderData = () => {
    if (menuActive === 0) {
      return ArticleData;
    } else {
      return CategoryData;
    }
  };

  return (
    <div style={{ width: "100%", backgroundColor: "#f2f5f7" }}>
      <NavigationBar />
      <div style={{ width: "100%", ...CENTER }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{
            width: "95%",
            position: "absolute",
            top: isMobile ? "70px" : "120px",
          }}
        >
          {isMobile ? null : (
            <div style={{ width: "20%" }}>
              <SideBar nodeId="50" />
            </div>
          )}
          <Stack
            direction={"column"}
            sx={{ width: isMobile ? "100%" : "78%", paddingTop: 2 }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              marginBottom={isMobile ? 3 : 0}
              paddingLeft={2}
            >
              <h2 style={{ margin: 0, color: "#fff", fontWeight: "500" }}>
                Artikel
              </h2>
              {isMobile ? null : (
                <h4
                  style={{ margin: 0, color: "#ffffff80", fontWeight: "400" }}
                >
                  Tentang Angelo / Artikel
                </h4>
              )}
            </Stack>
            {isMobile ? null : <Toolbar></Toolbar>}
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                padding: 20,
                backgroundColor: "#fff",
              }}
            >
              <Stack direction={"column"} gap={4} alignItems={"flex-start"}>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "flex-start" : "center"}
                  gap={isMobile ? 1 : 2}
                  justifyContent={isMobile ? "flex-start" : "space-between"}
                  width={"100%"}
                >
                  <Stack direction={"row"} gap={2} alignItems="center">
                    {menuActive === 0 ? (
                      <Button
                        onClick={() => navigate("/about/article/add")}
                        variant={"contained"}
                        color={"secondary"}
                        sx={{
                          backgroundColor: Colors.primary,
                          padding: "10px 20px",
                        }}
                        startIcon={
                          <Icon fontSize={"small"} sx={{ color: "#fff" }}>
                            add
                          </Icon>
                        }
                      >
                        {isMobile ? "Tambah" : "Tambah Artikel"}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setCategoryType("add");
                          setArticleModal(true);
                        }}
                        variant={"outlined"}
                        color={"secondary"}
                        sx={{
                          borderColor: Colors.primary,
                          padding: "10px 20px",
                          color: Colors.primary,
                        }}
                        startIcon={
                          <Icon
                            fontSize={"small"}
                            sx={{ color: Colors.primary }}
                          >
                            add
                          </Icon>
                        }
                      >
                        {isMobile ? "Kategori" : "Tambah Kategori Artikel"}
                      </Button>
                    )}
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={isMobile ? 1 : 2}
                    alignItems="center"
                  >
                    <Stack direction={"row"} gap={1} alignItems="center">
                      <Typography variant={isMobile ? "body1" : "h6"}>
                        Total
                      </Typography>
                      <Typography
                        variant={isMobile ? "body1" : "h6"}
                        color={Colors.primary}
                      >
                        {TotalData}
                      </Typography>
                    </Stack>
                    <IconButton>
                      <Icon
                        fontSize={isMobile ? "medium" : "large"}
                        sx={{ color: Colors.secondaryDark }}
                      >
                        delete
                      </Icon>
                    </IconButton>
                  </Stack>
                </Stack>
                <Tabs
                  textColor={"secondary"}
                  indicatorColor={"secondary"}
                  value={menuActive}
                  onChange={(e, value) => setMenuActive(value)}
                >
                  <Tab label="Artikel" />
                  <Tab label="Kategori Artikel" />
                </Tabs>
                {isLoading ? (
                  <SkeletonListLoader />
                ) : (
                  <>
                    {RenderData().length === 0 ? (
                      <Stack
                        direction={"column"}
                        width={"100%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          variant="body1"
                          color={Colors.secondaryDark}
                        >
                          TIDAK ADA DATA
                        </Typography>
                        <Typography variant="body1" color={Colors.secondary}>
                          Silahkan tambah data{" "}
                          {menuActive === 0 ? "Artikel" : "Kategori"} terlebih
                          dahulu
                        </Typography>
                      </Stack>
                    ) : (
                      <TableContainer>
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                          <TableHead>
                            {menuActive === 0 ? (
                              <TableRow>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  <Icon
                                    fontSize={"medium"}
                                    sx={{ color: Colors.secondaryDark }}
                                  >
                                    check_box_outline_blank
                                  </Icon>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "700",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  Judul Artikel
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "700",
                                    width: "30%",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="center"
                                >
                                  Gambar
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "700",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="center"
                                >
                                  Kategori
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "700",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="right"
                                >
                                  Actions
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  <Icon
                                    fontSize={"medium"}
                                    sx={{ color: Colors.secondaryDark }}
                                  >
                                    check_box_outline_blank
                                  </Icon>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "700",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="left"
                                >
                                  Nama Kategori Artikel
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "700",
                                    whiteSpace: "nowrap",
                                  }}
                                  align="right"
                                >
                                  Actions
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>
                          <TableBody>
                            {RenderData().map((item: any, index: number) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  <Icon
                                    fontSize={"medium"}
                                    sx={{ color: Colors.secondaryDark }}
                                  >
                                    check_box_outline_blank
                                  </Icon>
                                </TableCell>
                                {menuActive === 0 ? (
                                  <TableCell
                                    align="left"
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {item.title_id}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {item.name_id}
                                  </TableCell>
                                )}
                                {menuActive === 1 ? null : (
                                  <TableCell align="center">
                                    <img
                                      src={item.thumbnail}
                                      style={{
                                        width: isMobile ? "50vw" : "70%",
                                        aspectRatio: 4 / 3,
                                        objectFit: "cover",
                                      }}
                                      alt=""
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = Images.noImage;
                                      }}
                                    />
                                  </TableCell>
                                )}
                                {menuActive === 1 ? null : (
                                  <TableCell
                                    align="center"
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {ArticleData.length === 0
                                      ? ""
                                      : item.category.name_id}
                                  </TableCell>
                                )}
                                <TableCell align="right">
                                  <Stack
                                    direction={"row"}
                                    gap={1}
                                    alignItems={"center"}
                                    justifyContent={"flex-end"}
                                  >
                                    <IconButton onClick={() => onUpdate(item)}>
                                      <Icon
                                        fontSize={"medium"}
                                        sx={{ color: Colors.warning }}
                                      >
                                        edit
                                      </Icon>
                                    </IconButton>
                                    <IconButton onClick={() => onDelete(item)}>
                                      <Icon
                                        fontSize={"medium"}
                                        sx={{ color: Colors.error }}
                                      >
                                        delete
                                      </Icon>
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </>
                )}
                <div style={{ width: "100%", ...CENTER }}>
                  <Pagination
                    page={CurrentPage}
                    count={TotalPage}
                    onChange={onChangePage}
                  />
                </div>
              </Stack>
            </div>
            <Toolbar />
          </Stack>
        </Stack>
      </div>
      <ArticleDelete
        isOpen={isArticleDelete}
        onClose={() => setArticleDelete(false)}
        item={SelectedData}
        getData={() => GetDataList(CurrentPage)}
      />
      <ArticleCategoryDelete
        isOpen={isArticleCategoryDelete}
        onClose={() => setArticleCategoryDelete(false)}
        item={SelectedData}
        getData={() => GetDataList(CurrentPage)}
      />
      <CategoryArticleForm
        isOpen={isArticleModal}
        onClose={() => setArticleModal(false)}
        getData={() => GetDataList(CurrentPage)}
        type={CategoryType}
        item={SelectedData}
      />
    </div>
  );
};

export default AboutArticleScreen;
