import { AxiosNormal } from "../utils/interceptors";
const url = "products/brands";

export function HTTPGetAllBrands(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}/all`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetBrands(param: { page: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}?page=${param.page}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetBrandByID(param: { brand_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().get(`${url}/${param.brand_id}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddBrand(param: { name: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        name: param.name,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPEditBrand(param: {
  name: string;
  brand_id: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().put(`${url}/${param.brand_id}`, {
        name: param.name,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteBrand(param: { brand_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().delete(`${url}/${param.brand_id}`);
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
