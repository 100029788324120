import React from 'react'
import { Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, Toolbar, Tabs, Tab } from '@mui/material';
import { CENTER } from '../../utils/colors';
import NavigationBar from '../../components/navigation/navigationBar';
import { isMobile } from 'react-device-detect';
import SideBar from '../../components/navigation/sideBar';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { HTTPGetCouriers, HTTPStatusCouriers } from '../../apis/couriers';
import { HTTPGetPayments, HTTPStatusPayments } from '../../apis/payments';
import { Images } from '../../assets/images';
import { SkeletonListLoader } from '../../components/loader/skeleton';

const data = [
    { logo: 'https://buatlogoonline.com/wp-content/uploads/2022/10/Logo-Bank-BCA-1.png', name: 'Bank Central Asia' },
    { logo: 'https://logowik.com/content/uploads/images/bank-mandiri.jpg', name: 'Bank Mandiri' },
    { logo: 'https://upload.wikimedia.org/wikipedia/id/thumb/5/55/BNI_logo.svg/2560px-BNI_logo.svg.png', name: 'Bank Negara Indonesia' },
    { logo: 'https://buatlogoonline.com/wp-content/uploads/2022/10/Logo-Bank-BRI-1024x538.png', name: 'Bank Rakyat Indonesia' },
    { logo: 'https://images.bisnis.com/posts/2021/02/01/1350506/logo-bank-syariah-indonesia-1.jpg', name: 'Bank Syariah Indonesia' },
]

const OrderShippingCostScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [MenuActive, setMenuActive] = React.useState(0)
    const [CourierData, setCourierData] = React.useState([])
    const [PaymentData, setPaymentData] = React.useState([])
    const [isLoading, setLoading] = React.useState(true)
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalPage, setTotalPage] = React.useState(1)

    const GetCouriers = async (page: number) => {
        try {
            const result = await HTTPGetCouriers({ token, page })
            setCourierData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const GetPayments = async (page: number) => {
        try {
            const result = await HTTPGetPayments({ token, page })
            setPaymentData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        if (MenuActive === 0) {
            await GetCouriers(value)
        } else {
            await GetPayments(value)
        }
    }

    React.useEffect(() => {
        async function Initial() {
            setLoading(true)
            if (MenuActive === 0) {
                await GetCouriers(1)
            } else {
                await GetPayments(1)
            }
        }
        Initial().then().catch()
    }, [MenuActive])

    const StatusCourier = async (item: any) => {
        try {
            await HTTPStatusCouriers({
                id: item.id,
                is_active: !item.is_active,
                token
            })
            await GetCouriers(CurrentPage)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }
    
    const StatusPayment = async (item: any) => {
        try {
            await HTTPStatusPayments({
                id: item.id,
                is_active: !item.is_active,
                token
            })
            await GetPayments(CurrentPage)
        } catch (error) {
             
            toast.error('Terjadi Kesalahan!')
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="70" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>ONGKIR & PEMBAYARAN</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pesanan / Ongkir & Pembayaran</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={"column"} gap={3}>
                                <Tabs
                                    textColor={'secondary'}
                                    indicatorColor={'secondary'}
                                    value={MenuActive}
                                    onChange={(e, value) => setMenuActive(value)}
                                >
                                    <Tab label="Ongkir" />
                                    <Tab label="Metode Pembayaran" />
                                </Tabs>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                MenuActive === 0 ?
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Daftar Kurir</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Aktivasi</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {CourierData.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center">
                                                                            <Switch
                                                                                checked={item.is_active}
                                                                                onChange={() => StatusCourier(item)}
                                                                                color={'secondary'}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Logo</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Nama</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Tipe Metode</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Aktivasi</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {PaymentData.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">
                                                                            <img
                                                                                src={item.image}
                                                                                style={{ width: 60, height: 60, objectFit: 'contain' }}
                                                                                alt=""
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src = Images.noImage;
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>{item.type.replace(/_/g, ' ')}</TableCell>
                                                                        <TableCell align="center">
                                                                            <Switch
                                                                                checked={item.is_active}
                                                                                onChange={() => StatusPayment(item)}
                                                                                color={'secondary'}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                            <div style={{ width: '100%', ...CENTER }}>
                                                <Pagination
                                                    page={CurrentPage}
                                                    count={TotalPage}
                                                    onChange={onChangePage}
                                                />
                                            </div>
                                        </>
                                }
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div >
    )
}

export default OrderShippingCostScreen;