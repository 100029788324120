import { Stack, Typography, Select, MenuItem } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { HTTPGetAllBrands } from "../../../apis/brands";
import { Colors } from "../../../utils/colors";

export const ProductSpec = (param: {
  initialData: any;
  finalData: any;
  type: string;
}) => {
  const [init, setInit] = React.useState(false);
  const [Brand, setBrand] = React.useState("");
  const [BrandList, setBrandList] = React.useState([]);
  const [LatestUpdate, setLatestUpdate] = React.useState("");

  const GetBrands = async () => {
    try {
      const result = await HTTPGetAllBrands();
      setBrandList(result.data.data);
    } catch (error) {
      toast.error("Terjadi Kesalahan!");
    }
  };

  const renderBrand = (values: any) => {
    if (values.length === 0) {
      return <span style={{ color: "#a7a5a6" }}>Pilih Brand</span>;
    } else {
      const result: any = BrandList.filter((value: any) => value.id === Brand);
      return <span style={{ color: "#000" }}>{result[0].name}</span>;
    }
  };

  const InitialData = () => {
    const data = param.initialData;
    setBrand(data.brand);
    param.finalData({ product_brand_id: data.brand });
  };

  React.useEffect(() => {
    async function Initial() {
      await GetBrands();
      if (param.type === "edit") {
        InitialData();
      }
    }
    Initial().then().catch();
  }, [init]);

  return (
    <Stack
      direction={"column"}
      gap={3}
      alignItems={"flex-start"}
      width={"100%"}
    >
      <Typography variant="body1" fontWeight={"600"} color={"#000"}>
        Spesifikasi Produk:
      </Typography>
      <Stack direction={"row"} gap={2} width={"100%"} alignItems={"center"}>
        <Typography width={"20%"} variant="body1" color={Colors.secondaryDark}>
          Brand:
        </Typography>
        <Select
          displayEmpty
          size={"small"}
          MenuProps={{ style: { maxHeight: 500 } }}
          sx={{ bgcolor: "white", width: "80%" }}
          inputProps={{ sx: { borderRadius: 1 } }}
          value={Brand}
          onChange={(e) => {
            setBrand(e.target.value);
            param.finalData({ product_brand_id: e.target.value });
          }}
          renderValue={renderBrand}
        >
          {BrandList.map((item: any, index: number) => (
            <MenuItem value={item.id} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};
