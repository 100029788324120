import * as React from 'react';
import { Stack, Typography, Button, Divider, CircularProgress } from '@mui/material'
import { Colors } from '../../utils/colors';

export const DeleteContent = (param: { text: string, onConfirm: any, onCancel: any, isLoading: boolean }) => {
    return (
        <Stack direction={"column"} alignItems={'center'} gap={2}>
            <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
            <Typography variant="body2" textAlign={'center'}>Anda yakin ingin menghapus {param.text}?</Typography>
            <Divider sx={{ width: '100%' }}></Divider>
            <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                <Button
                    color={"secondary"}
                    variant={'contained'}
                    disabled={param.isLoading}
                    sx={{ backgroundColor: Colors.primary }}
                    onClick={param.onConfirm}
                >
                    {
                        param.isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            :
                            'Yakin'
                    }
                </Button>
                <Button
                    color={"error"}
                    variant={'text'}
                    onClick={param.onCancel}
                >Tidak</Button>
            </Stack>
        </Stack>
    )
}