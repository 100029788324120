import { Button, Icon, Stack, Toolbar, Typography, TextField, MenuItem, Select, FormControlLabel, Checkbox, Grid, IconButton, CircularProgress } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { HTTPGetRoles } from '../../apis/roles';
import secureLocalStorage from 'react-secure-storage';
import { HTTPGetPermissions } from '../../apis/permissions';
import { HTTPAddUser, HTTPUpdateUser } from '../../apis/users';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';

const UserAdminFormScreen = () => {
    const { type } = useParams()
    const navigate = useNavigate()
    const token = secureLocalStorage.getItem('token') as string
    const AdminRedux = useSelector((state: RootState) => state.admin_temp_data.data)

    const [init, setInit] = React.useState(false)
    const [Role, setRole] = React.useState('')
    const [RoleData, setRoleData] = React.useState([])
    const [isPassShow, setPassShow] = React.useState(true)
    const [isConfirmPassShow, setConfirmPassShow] = React.useState(true)
    const [FeatureData, setFeatureData] = React.useState([])
    const [Name, setName] = React.useState('')
    const [Email, setEmail] = React.useState('')
    const [Password, setPassword] = React.useState('')
    const [ConfirmPassword, setConfirmPassword] = React.useState('')
    const [PasswordError, setPasswordError] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [RoleAll, setRoleAll] = React.useState(false)
    const [RoleChecked, setRoleChecked] = React.useState<any>([])
    const [Rerender, setRerender] = React.useState<any>('')

    const GetRoles = async () => {
        try {
            const result = await HTTPGetRoles({ token })
            setRoleData(result.data.data)
        } catch (error) {
             
        }
    }

    const GetPermissions = async () => {
        try {
            const result = await HTTPGetPermissions({ token })
            let permissionList: any = []
            for (var prop in result.data.data) {
                if (result.data.data.hasOwnProperty(prop)) {
                    permissionList.push({ title: prop, data: result.data.data[prop] })
                }
            }
            setFeatureData(permissionList)
        } catch (error) {
             
        }
    }

    const renderRoles = (values: any) => {
        if (values.length === 0) {
            return <span style={{ color: '#a7a5a6' }}>Pilih Role</span>;
        } else {
            const result: any = RoleData.filter((value: any) => value.id === Role)
            return <span style={{ color: '#000' }}>{result[0].name}</span>;
        }
    }

    const onChangeAllRoles = () => {
        if (RoleAll === false) {
            let arrChild = []

            for (let i = 0; i < FeatureData.length; i++) {
                const item: any = FeatureData[i];
                for (let j = 0; j < item.data.length; j++) {
                    const value: any = item.data[j];
                    arrChild.push(value.code)
                }
            }
            setRoleChecked(arrChild)
            setRoleAll(true)
        } else {
            setRoleChecked([])
            setRoleAll(false)
        }
    }

    const onChangeRoles = (item: any) => {
        if (RoleChecked.includes(item.code)) {
            let arr = RoleChecked
            arr = arr.filter((e: any) => e !== item.code)
            setRoleChecked(arr)
        } else {
            let newArr = [...RoleChecked, item.code]
            setRoleChecked(newArr)
        }
        setRerender(item.code)
    }

    const ActionUsers = async () => {
        setLoading(true)
        setPasswordError(false)
        try {
            if (type === 'edit') {
                await HTTPUpdateUser({
                    token,
                    name: Name,
                    email: Email,
                    role_id: Role,
                    permissions: RoleChecked,
                    user_id: AdminRedux.id,
                })
                setLoading(true)
                toast.success('Berhasil mengubah Admin!')
                navigate(-1)
            } else {
                if (Password !== ConfirmPassword) {
                    setPasswordError(true)
                    setLoading(false)
                    toast.error('Konfirmasi Password tidak sesuai')
                } else {
                    await HTTPAddUser({
                        name: Name,
                        email: Email,
                        password: Password,
                        confirm_password: ConfirmPassword,
                        role_id: Role,
                        permissions: RoleChecked,
                        token
                    })
                    setLoading(true)
                    toast.success('Berhasil menambahkan Admin!')
                    navigate(-1)
                }
            }
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi kesalahan ketika menyimpan data')
             
        }
    }

    React.useEffect(() => {
        async function APIList() {
            await GetRoles()
            await GetPermissions()
            if (type === 'edit') {
                setName(AdminRedux.name)
                setEmail(AdminRedux.email)
                setRole(AdminRedux.role.id)
                setRoleChecked(AdminRedux.permissions)
            }
        }

        APIList().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="40" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Akun Admin</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pengguna / Admin</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={'100%'}>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} marginBottom={3}>
                                    <Button
                                        variant={'outlined'}
                                        sx={{ borderColor: Colors.secondary }}
                                        color={'secondary'}
                                        onClick={() => navigate(-1)}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: Colors.secondaryDark }}>arrow_back</Icon>}
                                    >
                                        <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                                    </Button>
                                    <Typography variant="body1" fontWeight={'600'} color={Colors.secondaryDark}>{type === 'add' ? 'FORM TAMBAH ADMIN' : 'FORM UBAH ADMIN'}</Typography>
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Nama:</Typography>
                                    <TextField
                                        type="text"
                                        placeholder="Nama Anda"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={Name}
                                        onChange={(text) => setName(text.target.value)}
                                    />
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Email:</Typography>
                                    <TextField
                                        type="text"
                                        placeholder="Email Anda"
                                        size="small"
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={Email}
                                        onChange={(text) => setEmail(text.target.value)}
                                    />
                                </Stack>
                                {
                                    type === 'edit' ? null :
                                        <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                            <Typography variant="body1" color={Colors.secondaryDark}>Password:</Typography>
                                            <TextField
                                                type={isPassShow ? 'password' : 'text'}
                                                error={PasswordError}
                                                placeholder="Password Anda"
                                                size="small"
                                                sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                                value={Password}
                                                onChange={(text) => setPassword(text.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => setPassShow(!isPassShow)}>
                                                            <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                                        </IconButton>
                                                    ),
                                                    sx: { borderRadius: 1 }
                                                }}
                                            />
                                        </Stack>
                                }
                                {
                                    type === 'edit' ? null :
                                        <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                            <Typography variant="body1" color={Colors.secondaryDark}>Konfirmasi Password:</Typography>
                                            <TextField
                                                type={isConfirmPassShow ? 'password' : 'text'}
                                                error={PasswordError}
                                                placeholder="Konfirmasi Password"
                                                size="small"
                                                sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                                value={ConfirmPassword}
                                                onChange={(text) => setConfirmPassword(text.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => setConfirmPassShow(!isConfirmPassShow)}>
                                                            <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>{isConfirmPassShow ? 'visibility' : 'visibility_off'}</Icon>
                                                        </IconButton>
                                                    ),
                                                    sx: { borderRadius: 1 }
                                                }}
                                            />
                                        </Stack>
                                }
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Role:</Typography>
                                    <Select
                                        displayEmpty
                                        size="small"
                                        MenuProps={{
                                            style: { maxHeight: 500 }
                                        }}
                                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                                        inputProps={{ sx: { borderRadius: 1 } }}
                                        value={Role}
                                        onChange={(e) => setRole(e.target.value)}
                                        renderValue={renderRoles}
                                    >
                                        {
                                            RoleData.map((item: { id: string; name: string }) => (
                                                <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Stack>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'} marginBottom={5}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Fitur:</Typography>
                                    <Stack direction={"column"} gap={3} width={isMobile ? '100%' : "70%"}>
                                        <FormControlLabel
                                            label="Pilih Semua"
                                            control={
                                                <Checkbox
                                                    value="all"
                                                    onClick={onChangeAllRoles}
                                                    checked={RoleAll}
                                                    color="secondary"
                                                />
                                            }
                                        />
                                        <Grid container spacing={isMobile ? 1 : 3}>
                                            {
                                                FeatureData.map((item: any, index: number) => (
                                                    <Grid item md={4} xs={item.title === 'Variants' ? 12 : 6} key={index}>
                                                        <Stack direction={'column'} gap={0}>
                                                            <Typography variant={'body1'}><b>{item.title}</b></Typography>
                                                            {
                                                                item.data.map((value: any, index: number) => (
                                                                    <FormControlLabel
                                                                        label={value.desc}
                                                                        control={
                                                                            <Checkbox
                                                                                value={value.code}
                                                                                checked={RoleChecked.includes(value.code)}
                                                                                color="secondary"
                                                                                onClick={() => onChangeRoles(value)}
                                                                            />
                                                                        }
                                                                        key={index}
                                                                    />
                                                                ))
                                                            }
                                                        </Stack>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Stack>
                                </Stack>
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Button
                                        color={"secondary"}
                                        variant={'contained'}
                                        disabled={isLoading}
                                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                                        startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                                        onClick={ActionUsers}
                                    >Simpan</Button>
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default UserAdminFormScreen;