import { AxiosNormal } from "../utils/interceptors";
const url = "shop_addresses";

export function HTTPGetShopAddresses(param: { token: string, page: number }): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await AxiosNormal().post(`${url}/get?page=${param.page}`, {
                token: param.token,
            });
            return resolve(response);
        } catch (error: any) {
            return reject(error);
        }
    });
}

export function HTTPAddShopAddress(param: {
    token: string;
    province_id: string;
    city_id: string;
    subdistrict_id: string;
    address: string;
    name: string;
    is_primary: number;
}): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await AxiosNormal().post(`${url}`, {
                token: param.token,
                province_id: param.province_id,
                city_id: param.city_id,
                subdistrict_id: param.subdistrict_id,
                address: param.address,
                name: param.name,
                is_primary: param.is_primary,
            });
            return resolve(response);
        } catch (error: any) {
            return reject(error);
        }
    });
}

export function HTTPUpdateShopAddress(param: {
    token: string;
    province_id: string;
    city_id: string;
    subdistrict_id: string;
    address: string;
    name: string;
    is_primary: number;
    address_id: string;
}): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await AxiosNormal().post(`${url}/update/${param.address_id}`, {
                token: param.token,
                province_id: param.province_id,
                city_id: param.city_id,
                subdistrict_id: param.subdistrict_id,
                address: param.address,
                name: param.name,
                is_primary: param.is_primary,
            });
            return resolve(response);
        } catch (error: any) {
            return reject(error);
        }
    });
}

export function HTTPDeleteShopAddress(param: {
    address_id: string;
    token: string;
}): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await AxiosNormal().post(
                `${url}/delete/${param.address_id}`,
                {
                    token: param.token,
                }
            );
            return resolve(response);
        } catch (error: any) {
            return reject(error);
        }
    });
}
