import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { Images } from '../../assets/images';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ShopeeSync = (param: { isOpen: boolean, onClose: any, onConfirm: any }) => {
    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={1}>
                    <div style={{ width: '100%' }}>
                        <Icon fontSize={"medium"} sx={{ color: Colors.info }}>info</Icon>
                    </div>
                    <Typography variant="body1" fontWeight={'600'} width={"90%"} textAlign={'center'}>Yakin Ingin Sinkronasi Data Produk Dengan Shopee?</Typography>
                    <Typography variant="body2" width={"90%"} textAlign={'center'}>Beberapa Produk akan kehilangan kontennya seperti Gambar/Video</Typography>
                    <Divider sx={{ width: '100%', marginY: 2 }}></Divider>
                    <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                        <Button
                            color={"warning"}
                            variant={'outlined'}
                            sx={{ borderColor: '#f0582d', width: '60%' }}
                            onClick={param.onConfirm}
                            startIcon={<img src={Images.shopeeLogo} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />}
                        >YAKIN</Button>
                        <Button
                            color={"primary"}
                            variant={'contained'}
                            sx={{width: '30%'}}
                            onClick={param.onClose}
                        >Batal</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}