import { AxiosNormal } from "../utils/interceptors";
const url = "roles";

export function HTTPGetRoles(param: { token: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/get`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetRolesByID(param: {
  role_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/${param.role_id}`, {
        token: param.token,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddRoles(param: {
  token: string;
  name: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}`, {
        token: param.token,
        name: param.name,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
