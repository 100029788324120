import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress, Toolbar } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { isMobile } from 'react-device-detect';
import ReactQuill from 'react-quill';
import { ImagePicker } from '../utils/imagePicker';
import { HTTPAddSlider, HTTPDeleteSlider, HTTPEditSlider } from '../../apis/slider';
import secureLocalStorage from 'react-secure-storage';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BannerForm = (param: { isOpen: boolean, onClose: any, type: string, getData: any, item: any, bannerType: number }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)
    const [Title, setTitle] = React.useState('')
    const [ImageResult, setImageResult] = React.useState('')
    const [Content, setContent] = React.useState('')

    const onClose = () => {
        setImageResult('')
        setTitle('')
        setContent('')
        param.onClose()
    }

    const ActionBanner = async () => {
        setLoading(true)
        try {
            const data = {
                content_en: Content,
                content_id: Content,
                is_highlighted: true,
                thumbnail: ImageResult,
                title_en: Title,
                title_id: Title,
                type: param.bannerType,
                token,
            }

            if (param.type === 'add') {
                await HTTPAddSlider(data)
                toast.success('Berhasil menambahkan Banner')
            } else {
                await HTTPEditSlider({ ...data, banner_id: param.item.id })
                toast.success('Berhasil mengubah Banner')
            }
            setLoading(false)
            onClose()
            param.getData()
        } catch (error) {
            toast.error('Terjadi Kesalahan')
            setLoading(false)
             
        }
    }

    React.useEffect(() => {
        if (param.isOpen === true && param.type === 'edit') {
            setImageResult(param.item.thumbnail)
            setTitle(param.item.title_id)
            setContent(param.item.content_id)
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            scroll={'body'}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>
                        {param.type === 'add' ?
                            (param.bannerType === 0 ? 'Tambah Banner Beranda' : 'Tambah Banner Login')
                            :
                            (param.bannerType === 0 ? 'Edit Banner Login' : 'Edit Banner Login')
                        }
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} gap={3} marginY={5}>
                    <Typography variant="body1" color={Colors.secondary}>Nama Banner:</Typography>
                    <TextField
                        type="text"
                        placeholder="Nama Banner"
                        size="small"
                        sx={{ bgcolor: "white", width: '70%' }}
                        value={Title}
                        onChange={(text) => setTitle(text.target.value)}
                        InputProps={{
                            sx: { borderRadius: 1 },
                            endAdornment: <Typography variant={"body2"} color={Colors.secondaryDark}>{Title.length}/50</Typography>
                        }}
                        inputProps={{ maxLength: 50 }}
                    />
                </Stack>
                <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} gap={3} marginBottom={5}>
                    <Typography variant="body1" color={Colors.secondary}>Gambar Banner:</Typography>
                    <div style={{ width: '70%' }}>
                        <ImagePicker onResult={(value: any) => setImageResult(value)} initValue={ImageResult} />
                    </div>
                </Stack>
                <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} gap={3} marginBottom={5}>
                    <Typography variant="body1" color={Colors.secondary}>Deskripsi Banner:</Typography>
                    <ReactQuill
                        value={Content}
                        theme={"snow"}
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ["bold", "italic", "underline", "strike", "blockquote"],
                                [
                                    { list: "ordered" },
                                    { list: "bullet" },
                                    { indent: "-1" },
                                    { indent: "+1" },
                                ],
                                ["link", "clean", "video"],
                            ],
                        }}
                        formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                            "video"
                        ]}
                        onChange={(value) => setContent(value)}
                        style={{ height: isMobile ? undefined : "18em", width: '70%' }}
                    />
                </Stack>
                <Toolbar />
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        color={"secondary"}
                        variant={'contained'}
                        sx={{ backgroundColor: Colors.primary, width: '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                        onClick={ActionBanner}
                    >Simpan</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const BannerDelete = (param: { isOpen: boolean, onClose: any, item: any, getData: any, bannerType: number }) => {
    const token = secureLocalStorage.getItem('token') as string
    const [isLoading, setLoading] = React.useState(false)

    const DeleteBanner = async () => {
        setLoading(true)
        try {
            await HTTPDeleteSlider({ banner_id: param.item.id, type: param.bannerType, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2">Anda yakin untuk menghapus data ini?</Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            disabled={isLoading}
                            sx={{ backgroundColor: Colors.primary }}
                            onClick={DeleteBanner}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    'Yakin'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}