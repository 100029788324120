import { Button, Icon, Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, Tabs, Tab } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { BannerDelete, BannerForm } from '../../components/promotions/bannerModal';
import { toast } from 'react-toastify';
import { HTTPGetSliders } from '../../apis/slider';
import secureLocalStorage from 'react-secure-storage';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { Images } from '../../assets/images';

const PromotionBannerScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [isBannerForm, setBannerForm] = React.useState(false)
    const [isBannerDelete, setBannerDelete] = React.useState(false)
    const [ModalType, setModalType] = React.useState('add')
    const [menuActive, setMenuActive] = React.useState(0)
    const [BannerData, setBannerData] = React.useState([])
    const [isLoading, setLoading] = React.useState(true)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const onFormOpen = (val: string, item: any) => {
        setSelectedItem(item)
        if (val === 'delete') {
            setBannerDelete(true)
        } else {
            setModalType(val)
            setBannerForm(true)
        }
    }

    const GetBanners = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetSliders({ token, type: menuActive, page })
            setBannerData(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Terjadi Kesalahan')
             
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetBanners(value)
    }

    React.useEffect(() => {
        async function Initial() {
            await GetBanners(1)
        }

        Initial().then().catch()
    }, [menuActive])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="30" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Banner Promosi</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pusat Promosi / Banner Promosi</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Button onClick={() => onFormOpen('add', {})} variant={'contained'} color={'secondary'} sx={{ backgroundColor: Colors.primary }} startIcon={<Icon sx={{ color: '#fff' }} fontSize={'small'}>add</Icon>}>
                                        <span style={{ color: '#fff' }}>Tambah Banner {menuActive === 0 ? 'Beranda' : 'Login'}</span>
                                    </Button>
                                    <Stack direction={"row"} gap={1} alignItems="center">
                                        <Typography variant={'h6'}>Total</Typography>
                                        <Typography variant={'h6'} color={Colors.primary}>{TotalData}</Typography>
                                    </Stack>
                                </Stack>
                                <Tabs
                                    textColor={'secondary'}
                                    indicatorColor={'secondary'}
                                    value={menuActive}
                                    onChange={(e, value) => setMenuActive(value)}
                                >
                                    <Tab label="Banner Beranda" />
                                    <Tab label="Banner Login" />
                                </Tabs>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                BannerData.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Promosi terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap', width: '30%' }} align="left">Nama Banner</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Gambar</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {BannerData.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="center"><Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>check_box_outline_blank</Icon></TableCell>
                                                                        <TableCell align="left">{item.title_id}</TableCell>
                                                                        <TableCell align="center">
                                                                            <img
                                                                                src={item.thumbnail}
                                                                                style={{ width: '40%', aspectRatio: 16 / 9, objectFit: 'cover' }}
                                                                                alt={item.title_id}
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src = Images.noImage;
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                                <IconButton onClick={() => onFormOpen('edit', item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onFormOpen('delete', item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination
                                        page={CurrentPage}
                                        count={TotalPage}
                                        onChange={onChangePage}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <BannerForm
                isOpen={isBannerForm}
                onClose={() => setBannerForm(false)}
                type={ModalType}
                getData={() => GetBanners(CurrentPage)}
                item={SelectedItem}
                bannerType={menuActive}
            />
            <BannerDelete
                isOpen={isBannerDelete}
                onClose={() => setBannerDelete(false)}
                getData={() => GetBanners(CurrentPage)}
                item={SelectedItem}
                bannerType={menuActive}
            />
        </div>
    )
}

export default PromotionBannerScreen;