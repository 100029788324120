import * as React from 'react';
import { Dialog, DialogContent, Slide, Stack, Typography, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPDeleteUser } from '../../apis/users';
import secureLocalStorage from 'react-secure-storage';
import { HTTPBanCustomer, HTTPDeleteCustomer } from '../../apis/customers';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const UserAdminDelete = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string

    const DeleteAdmin = async () => {
        setLoading(true)
        try {
            await HTTPDeleteUser({ user_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan ketika menghapus Admin')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2" textAlign="center">Anda yakin untuk menghapus data <b>{param.item.name}</b>?</Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            sx={{ backgroundColor: Colors.primary }}
                            onClick={DeleteAdmin}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    'Yakin'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const UserCustomerDelete = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string

    const DeleteCustomer = async () => {
        setLoading(true)
        try {
            await HTTPDeleteCustomer({ customer_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan ketika menghapus Customer')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2" textAlign='center'>Anda yakin untuk menghapus customer <b>{param.item.name}?</b></Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            sx={{ backgroundColor: Colors.primary }}
                            disabled={isLoading}
                            onClick={DeleteCustomer}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    'Yakin'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const UserCustomerBan = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string

    const BanCustomer = async () => {
        setLoading(true)
        try {
            await HTTPBanCustomer({ customer_id: param.item.id, token })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan ketika ban Customer')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2" textAlign='center'>Anda yakin ingin BAN akun <b>{param.item.name}?</b></Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            sx={{ backgroundColor: Colors.primary }}
                            disabled={isLoading}
                            onClick={BanCustomer}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    :
                                    'Yakin'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}