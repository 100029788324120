import { AxiosNormal } from "../utils/interceptors";
const url = "faqs";

export function HTTPGetFAQs(param: {
  token: string;
  page: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/get?page=${param.page}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddFAQ(param: {
  token: string;
  answer_id: string;
  answer_en: string;
  question_id: string;
  question_en: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(`${url}/store`, {
        token: param.token,
        answer_id: param.answer_id,
        answer_en: param.answer_en,
        question_id: param.question_id,
        question_en: param.question_en,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUpdateFAQ(param: {
  token: string;
  faq_id: string;
  answer_id: string;
  answer_en: string;
  question_id: string;
  question_en: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/update/${param.faq_id}`,
        {
          token: param.token,
          answer_id: param.answer_id,
          answer_en: param.answer_en,
          question_id: param.question_id,
          question_en: param.question_en,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteFAQ(param: {
  faq_id: string;
  token: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${url}/delete/${param.faq_id}`,
        {
          token: param.token,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
