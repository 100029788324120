import { Avatar, AvatarGroup, Divider, Icon, Stack, Toolbar } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { BarLineChart } from '../../components/charts/barChart';
import { isMobile } from 'react-device-detect';

const TaskListScreen = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="10" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>TASK LIST</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tasks / Task List</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'flex-start' : 'space-between'} width={'100%'} gap={isMobile ? 0 : 3}>
                            <Stack direction={'column'} width={isMobile ? '100%' : '68%'} gap={3}>
                                <div style={{ borderRadius: 10, padding: 20, backgroundColor: '#fff', width: '100%' }}>
                                    <Stack divider={<Divider />} direction={'column'} gap={2} width={'100%'} sx={{ overflowX: 'scroll' }}>
                                        <span style={{ color: Colors.secondaryDark, fontWeight: '600' }}>Example</span>
                                        {
                                            [1, 2, 3].map((item) => (
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item} width={isMobile ? '120vw' : '100%'}>
                                                    <Stack direction={'row'} alignItems={'center'} gap={3}>
                                                        <Icon fontSize='small' sx={{ color: Colors.secondary }}>check_box_outline_blank</Icon>
                                                        <span style={{ color: Colors.secondaryDark, fontWeight: '500' }}>Create a Blog Dashboard UI</span>
                                                    </Stack>
                                                    <AvatarGroup max={3}>
                                                        <Avatar alt="Remy Sharp" src={require("../../assets/images/users/avatar-1.jpg")} />
                                                        <Avatar alt="Travis Howard" src={require("../../assets/images/users/avatar-2.jpg")} />
                                                        <Avatar alt="Cindy Baker" src={require("../../assets/images/users/avatar-3.jpg")} />
                                                        <Avatar alt="Agnes Walker" src={require("../../assets/images/users/avatar-4.jpg")} />
                                                        <Avatar alt="Trevor Henderson" src={require("../../assets/images/users/avatar-5.jpg")} />
                                                    </AvatarGroup>
                                                    <small style={{
                                                        backgroundColor: Colors.secondary + 50,
                                                        borderRadius: 5,
                                                        padding: 3,
                                                        color: Colors.secondary
                                                    }}>Waiting</small>
                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                </div>
                                <div style={{ borderRadius: 10, padding: 20, backgroundColor: '#fff', width: '100%' }}>
                                    <Stack divider={<Divider />} direction={'column'} gap={2} width={'100%'} sx={{ overflowX: 'scroll' }}>
                                        <span style={{ color: Colors.secondaryDark, fontWeight: '600' }}>In Progress</span>
                                        {
                                            [1, 2, 3].map((item) => (
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item} width={isMobile ? '120vw' : '100%'}>
                                                    <Stack direction={'row'} alignItems={'center'} gap={3}>
                                                        <Icon fontSize='small' sx={{ color: Colors.secondary }}>check_box_outline_blank</Icon>
                                                        <span style={{ color: Colors.secondaryDark, fontWeight: '500' }}>Brand Logo Design</span>
                                                    </Stack>
                                                    <AvatarGroup max={3}>
                                                        <Avatar alt="Remy Sharp" src={require("../../assets/images/users/avatar-1.jpg")} />
                                                        <Avatar alt="Travis Howard" src={require("../../assets/images/users/avatar-2.jpg")} />
                                                        <Avatar alt="Cindy Baker" src={require("../../assets/images/users/avatar-3.jpg")} />
                                                        <Avatar alt="Agnes Walker" src={require("../../assets/images/users/avatar-4.jpg")} />
                                                        <Avatar alt="Trevor Henderson" src={require("../../assets/images/users/avatar-5.jpg")} />
                                                    </AvatarGroup>
                                                    <small style={{
                                                        backgroundColor: Colors.warning + 50,
                                                        borderRadius: 5,
                                                        padding: 3,
                                                        color: Colors.warning
                                                    }}>Pending</small>
                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                </div>
                                <div style={{ borderRadius: 10, padding: 20, backgroundColor: '#fff', width: '100%' }}>
                                    <Stack divider={<Divider />} direction={'column'} gap={2} width={'100%'} sx={{ overflowX: 'scroll' }}>
                                        <span style={{ color: Colors.secondaryDark, fontWeight: '600' }}>Completed</span>
                                        {
                                            [1, 2, 3].map((item) => (
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item} width={isMobile ? '120vw' : '100%'}>
                                                    <Stack direction={'row'} alignItems={'center'} gap={3}>
                                                        <Icon fontSize='small' sx={{ color: Colors.secondary }}>check_box_outline_blank</Icon>
                                                        <span style={{ color: Colors.secondaryDark, fontWeight: '500' }}>Redesign - Landing Page</span>
                                                    </Stack>
                                                    <AvatarGroup max={3}>
                                                        <Avatar alt="Remy Sharp" src={require("../../assets/images/users/avatar-1.jpg")} />
                                                        <Avatar alt="Travis Howard" src={require("../../assets/images/users/avatar-2.jpg")} />
                                                        <Avatar alt="Cindy Baker" src={require("../../assets/images/users/avatar-3.jpg")} />
                                                        <Avatar alt="Agnes Walker" src={require("../../assets/images/users/avatar-4.jpg")} />
                                                        <Avatar alt="Trevor Henderson" src={require("../../assets/images/users/avatar-5.jpg")} />
                                                    </AvatarGroup>
                                                    <small style={{
                                                        backgroundColor: Colors.success + 50,
                                                        borderRadius: 5,
                                                        padding: 3,
                                                        color: Colors.success
                                                    }}>Completed</small>
                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                </div>
                                <div style={{ borderRadius: 10, padding: 20, backgroundColor: '#fff', width: '100%' }}>
                                    <Stack divider={<Divider />} direction={'column'} gap={2} width={'100%'} sx={{ overflowX: 'scroll' }}>
                                        <span style={{ color: Colors.secondaryDark, fontWeight: '600' }}>Recent Task</span>
                                        {
                                            [1, 2, 3].map((item) => (
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item} width={isMobile ? '120vw' : '100%'}>
                                                    <Stack direction={'row'} alignItems={'center'} gap={3}>
                                                        <Icon fontSize='small' sx={{ color: Colors.secondary }}>check_box_outline_blank</Icon>
                                                        <span style={{ color: Colors.secondaryDark, fontWeight: '500' }}>Create a Web Template UI</span>
                                                    </Stack>
                                                    <AvatarGroup max={3}>
                                                        <Avatar alt="Remy Sharp" src={require("../../assets/images/users/avatar-1.jpg")} />
                                                        <Avatar alt="Travis Howard" src={require("../../assets/images/users/avatar-2.jpg")} />
                                                        <Avatar alt="Cindy Baker" src={require("../../assets/images/users/avatar-3.jpg")} />
                                                        <Avatar alt="Agnes Walker" src={require("../../assets/images/users/avatar-4.jpg")} />
                                                        <Avatar alt="Trevor Henderson" src={require("../../assets/images/users/avatar-5.jpg")} />
                                                    </AvatarGroup>
                                                    <small style={{
                                                        backgroundColor: Colors.primary + 50,
                                                        borderRadius: 5,
                                                        padding: 3,
                                                        color: Colors.primary
                                                    }}>On Progress</small>
                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                </div>
                            </Stack>
                            <Stack direction={'column'} gap={3} width={isMobile ? '100%' : '30%'}>
                                <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                                    <span style={{ color: Colors.secondary, fontWeight: '600' }}>Tasks</span>
                                    <BarLineChart />
                                </div>
                                <div style={{ borderRadius: 10, padding: 20, backgroundColor: '#fff', width: '100%' }}>
                                    <Stack divider={<Divider />} direction={'column'} gap={2} width={'100%'} sx={{ overflowX: 'scroll' }}>
                                        <span style={{ color: Colors.secondaryDark, fontWeight: '600' }}>Recent Task</span>
                                        {
                                            [1, 2, 3].map((item) => (
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={item} width={isMobile ? '100vw' : '100%'}>
                                                    <small style={{ color: Colors.secondaryDark, fontWeight: '500' }}>Create a Web Template UI</small>
                                                    <AvatarGroup max={3}>
                                                        <Avatar alt="Remy Sharp" src={require("../../assets/images/users/avatar-1.jpg")} />
                                                        <Avatar alt="Travis Howard" src={require("../../assets/images/users/avatar-2.jpg")} />
                                                        <Avatar alt="Cindy Baker" src={require("../../assets/images/users/avatar-3.jpg")} />
                                                        <Avatar alt="Agnes Walker" src={require("../../assets/images/users/avatar-4.jpg")} />
                                                        <Avatar alt="Trevor Henderson" src={require("../../assets/images/users/avatar-5.jpg")} />
                                                    </AvatarGroup>
                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                </div>
                            </Stack>
                        </Stack>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default TaskListScreen;