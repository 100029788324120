import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide, Stack, Typography, IconButton, Icon, TextField, Button, Divider, CircularProgress } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { isMobile } from 'react-device-detect';
import { CENTER, Colors } from '../../utils/colors';
import { toast } from 'react-toastify';
import { HTTPAddBrand, HTTPDeleteBrand, HTTPEditBrand } from '../../apis/brands';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BrandForm = (param: { isOpen: boolean, onClose: any, type: string, getData: any, item: any }) => {
    const [isLoading, setLoading] = React.useState(false)
    const [BrandName, setBrandName] = React.useState('')

    const onClose = () => {
        setBrandName('')
        param.onClose()
    }

    const ActionBrand = async () => {
        setLoading(true)
        try {
            if (param.type === 'add') {
                await HTTPAddBrand({ name: BrandName })
                toast.success('Berhasil Menambah Brand!')
            } else {
                await HTTPEditBrand({ brand_id: param.item.id, name: BrandName })
                toast.success('Berhasil Mengubah Brand!')
            }
            setLoading(false)
            onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    React.useEffect(() => {
        if (param.isOpen === true && param.type === 'edit') {
            setBrandName(param.item.name)
        }
    }, [param.isOpen])

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{ sx: { width: '100%', maxWidth: isMobile ? '100vw' : '50vw' } }}
        >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight={'600'}>{param.type === 'add' ? 'Tambah Brand' : 'Edit Brand'}</Typography>
                    <IconButton onClick={onClose}>
                        <Icon fontSize={'medium'} sx={{ color: Colors.secondaryDark }}>close</Icon>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider></Divider>
                <Stack direction={isMobile ? 'column' : "row"} alignItems={isMobile ? 'flex-start' : 'center'} gap={isMobile ? 1 : 3} marginY={isMobile ? 3 : 5}>
                    <Typography variant="body1" color={Colors.secondary}>Nama Brand:</Typography>
                    <TextField
                        type="text"
                        placeholder="Nama Brand"
                        size="small"
                        sx={{ bgcolor: "white", width: isMobile ? '100%' : '70%' }}
                        InputProps={{ sx: { borderRadius: 1 } }}
                        value={BrandName}
                        onChange={(text) => setBrandName(text.target.value)}
                        onKeyDown={(e) => { if (e.keyCode === 13) ActionBrand() }}
                    />
                </Stack>
                <div style={{ width: '100%', ...CENTER }}>
                    <Button
                        onClick={ActionBrand}
                        color={"secondary"}
                        variant={'contained'}
                        disabled={isLoading}
                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                        startIcon={isLoading ?
                            <CircularProgress color="secondary" size={20} />
                            : <Icon fontSize={'small'}>save</Icon>}
                    >SIMPAN</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const BrandDelete = (param: { isOpen: boolean, onClose: any, item: any, getData: any }) => {
    const [isLoading, setLoading] = React.useState(false)

    const DeleteBrand = async () => {
        setLoading(true)
        try {
            await HTTPDeleteBrand({ brand_id: param.item.id })
            setLoading(false)
            param.onClose()
            param.getData()
        } catch (error) {
            setLoading(false)
             
            toast.error('Terjadi Kesalahan')
        }
    }

    return (
        <Dialog
            open={param.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={param.onClose}
        >
            <DialogContent>
                <Stack direction={"column"} alignItems={'center'} gap={2}>
                    <Typography variant="h6" fontWeight={'600'}>Perhatian!</Typography>
                    <Typography variant="body2">Anda yakin untuk menghapus data ini?</Typography>
                    <Divider sx={{ width: '100%' }}></Divider>
                    <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            color={"secondary"}
                            variant={'contained'}
                            sx={{ backgroundColor: Colors.primary }}
                            onClick={DeleteBrand}
                        >
                            {
                                isLoading ?
                                    <CircularProgress color="secondary" size={20} />
                                    : 'YAKIN'
                            }
                        </Button>
                        <Button
                            color={"error"}
                            variant={'text'}
                            onClick={param.onClose}
                        >Tidak</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}