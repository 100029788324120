import { AxiosNormal } from "../utils/interceptors";
const url_home = "sliders/home";
const url_auth = "sliders/auth";

export function HTTPGetSliders(param: {
  token: string;
  type: number;
  page: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${param.type === 0 ? url_home : url_auth}/get?page=${param.page}`,
        { token: param.token }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPGetSliderHighlight(param: {
  banner_id: string;
  type: number;
  token: string;
  is_highlighted: boolean;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${param.type === 0 ? url_home : url_auth}/highlight/${
          param.banner_id
        }`,
        {
          token: param.token,
          is_highlighted: param.is_highlighted,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPAddSlider(param: {
  token: string;
  title_id: string;
  title_en: string;
  content_id: string;
  content_en: string;
  thumbnail: string;
  is_highlighted: boolean;
  type: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${param.type === 0 ? url_home : url_auth}/store`,
        {
          token: param.token,
          title_id: param.title_id,
          title_en: param.title_en,
          content_id: param.content_id,
          content_en: param.content_id,
          thumbnail: param.thumbnail,
          is_highlighted: param.is_highlighted,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPEditSlider(param: {
  banner_id: string;
  token: string;
  title_id: string;
  title_en: string;
  content_id: string;
  content_en: string;
  thumbnail: string;
  is_highlighted: boolean;
  type: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${param.type === 0 ? url_home : url_auth}/update/${param.banner_id}`,
        {
          token: param.token,
          title_id: param.title_id,
          title_en: param.title_en,
          content_id: param.content_id,
          content_en: param.content_en,
          thumbnail: param.thumbnail,
          is_highlighted: param.is_highlighted,
          type: param.type,
        }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPDeleteSlider(param: {
  banner_id: string;
  token: string;
  type: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosNormal().post(
        `${param.type === 0 ? url_home : url_auth}/delete/${param.banner_id}`,
        { token: param.token }
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
