import { Button, ButtonProps, Icon, Pagination, Stack, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, CircularProgress } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { UserCustomerBan, UserCustomerDelete } from '../../components/users/userModal';
import { useDispatch } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { HTTPGetCustomers, HTTPUnBanCustomer } from '../../apis/customers';
import { SkeletonListLoader } from '../../components/loader/skeleton';
import { setCustomerTempData } from '../../stores/data/customer';
import { toast } from 'react-toastify';

const PrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#fff',
    backgroundColor: Colors.primary,
    padding: '10px 20px',
    '&:hover': {
        backgroundColor: '#9c27b0',
    },
}));

const UserCustomerScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [init, setInit] = React.useState(false)
    const token = secureLocalStorage.getItem('token') as string
    const [isUserDelete, setUserDelete] = React.useState(false)
    const [isUserBan, setUserBan] = React.useState(false)
    const [CustomerList, setCustomerList] = React.useState([])
    const [isLoading, setLoading] = React.useState(true)
    const [isBanLoading, setBanLoading] = React.useState(false)
    const [SelectedItem, setSelectedItem] = React.useState({})
    const [CurrentPage, setCurrentPage] = React.useState(1)
    const [TotalData, setTotalData] = React.useState(0)
    const [TotalPage, setTotalPage] = React.useState(1)

    const GetCustomers = async (page: number) => {
        setLoading(true)
        try {
            const result = await HTTPGetCustomers({ token, page })
            setCustomerList(result.data.data)
            setTotalPage(result.data.meta.pagination.last_page)
            setTotalData(result.data.meta.pagination.total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
             
        }
    }

    const onChangePage = async (e: any, value: number) => {
        setCurrentPage(value)
        window.scrollTo(0, 0)
        await GetCustomers(value)
    }

    React.useEffect(() => {
        async function APIList() {
            await GetCustomers(1)
        }

        APIList().then().catch()
    }, [init])

    const onUpdate = (value: any) => {
        dispatch(setCustomerTempData({ data: value }))
        navigate('/user/customer/edit')
    }

    const onBanDelete = async (value: any, type: string) => {
        setSelectedItem(value)
        if (type === 'delete') {
            setUserDelete(true)
        } else if (type === 'ban') {
            setUserBan(true)
        } else {
            setBanLoading(true)
            try {
                await HTTPUnBanCustomer({ customer_id: value.id, token })
                setBanLoading(false)
                toast.success('Berhasil membuka BAN')
                await GetCustomers(CurrentPage)
            } catch (error) {
                toast.success('Terjadi Kesalahan')
            }
        }
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="40" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Akun Pelanggan</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Pengguna / Pelanggan</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <PrimaryButton onClick={() => navigate('/user/customer/add')} startIcon={<Icon fontSize={'small'}>add</Icon>}>
                                        <span style={{ color: '#fff' }}>{isMobile ? 'Tambah' : 'Tambah Akun Pelanggan'}</span>
                                    </PrimaryButton>
                                    <Stack direction={"row"} gap={1} alignItems="center">
                                        <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                        <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>{TotalData}</Typography>
                                    </Stack>
                                </Stack>
                                {
                                    isLoading ?
                                        <SkeletonListLoader />
                                        :
                                        <>
                                            {
                                                CustomerList.length === 0 ?
                                                    <Stack direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'} height={"100%"}>
                                                        <Typography variant="body1" color={Colors.secondaryDark}>TIDAK ADA DATA</Typography>
                                                        <Typography variant="body1" color={Colors.secondary}>Silahkan tambah Pelanggan terlebih dahulu</Typography>
                                                    </Stack>
                                                    :
                                                    <TableContainer>
                                                        <Table sx={{ width: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Username</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Email</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Status</TableCell>
                                                                    <TableCell sx={{ fontWeight: '700', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {CustomerList.map((item: any, index: number) => (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{item.name}</TableCell>
                                                                        <TableCell align="center">{item.email}</TableCell>
                                                                        <TableCell align="center">{item.is_active ? 'Aktif' : 'Tidak Aktif'}</TableCell>
                                                                        <TableCell align="center">
                                                                            <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'}>
                                                                                <IconButton onClick={() => onUpdate(item)}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.warning }}>edit</Icon>
                                                                                </IconButton>
                                                                                <IconButton onClick={() => onBanDelete(item, 'delete')}>
                                                                                    <Icon fontSize={'medium'} sx={{ color: Colors.error }}>delete</Icon>
                                                                                </IconButton>
                                                                                {
                                                                                    item.is_banned ?
                                                                                        <Button
                                                                                            startIcon={isBanLoading ?
                                                                                                <CircularProgress color="success" size={20} />
                                                                                                :
                                                                                                <Icon fontSize={'small'}>check_circle_outline</Icon>
                                                                                            }
                                                                                            color={'success'}
                                                                                            variant={"contained"}
                                                                                            disabled={isBanLoading}
                                                                                            onClick={() => onBanDelete(item, 'unban')}
                                                                                        >Buka</Button>
                                                                                        :
                                                                                        <Button
                                                                                            startIcon={<Icon fontSize={'small'}>not_interested</Icon>}
                                                                                            color={'error'}
                                                                                            variant={"contained"}
                                                                                            onClick={() => onBanDelete(item, 'ban')}
                                                                                        >Ban</Button>
                                                                                }
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </>
                                }
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Pagination
                                        page={CurrentPage}
                                        count={TotalPage}
                                        onChange={onChangePage}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <UserCustomerDelete
                isOpen={isUserDelete}
                onClose={() => setUserDelete(false)}
                item={SelectedItem}
                getData={() => GetCustomers(CurrentPage)}
            />
            <UserCustomerBan
                isOpen={isUserBan}
                onClose={() => setUserBan(false)}
                item={SelectedItem}
                getData={() => GetCustomers(CurrentPage)}
            />
        </div>
    )
}

export default UserCustomerScreen;