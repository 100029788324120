import { Button, Icon, Stack, Toolbar, Typography, TextField, CircularProgress, IconButton } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';
import "react-quill/dist/quill.snow.css";
import ReactQuill from 'react-quill';
import { HTTPAddFAQ, HTTPUpdateFAQ } from '../../apis/faq';

const AboutFAQFormScreen = () => {
    const { type } = useParams()
    const navigate = useNavigate()
    const FAQRedux = useSelector((state: RootState) => state.faq_temp_data.data)
    const token = secureLocalStorage.getItem('token') as string

    const [init, setInit] = React.useState(false)
    const [Question, setQuestion] = React.useState('')
    const [Answer, setAnswer] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)

    const ActionFAQ = async () => {
        setLoading(true)
        try {
            if (type === 'edit') {
                await HTTPUpdateFAQ({
                    token,
                    faq_id: FAQRedux.id,
                    question_id: Question,
                    question_en: Question,
                    answer_en: Answer,
                    answer_id: Answer,
                })
                setLoading(false)
                toast.success('Berhasil mengubah Customer')
                navigate(-1)
            } else {
                await HTTPAddFAQ({
                    token,
                    question_id: Question,
                    question_en: Question,
                    answer_id: Answer,
                    answer_en: Answer,
                })
                setLoading(false)
                toast.success('Berhasil menambahkan Customer')
                navigate(-1)
            }
        } catch (error) {
            toast.error('Terjadi Kesalahan menyimpan Data')
            setLoading(false)
             
        }
    }

    React.useEffect(() => {
        function Initial() {
            if (type === 'edit') {
                setQuestion(FAQRedux.question_id)
                setAnswer(FAQRedux.answer_id)
            }
        }
        Initial()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {
                        isMobile ?
                            null
                            :
                            <div style={{ width: '20%' }}>
                                <SideBar nodeId="50" />
                            </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Frequently Ask Question</h2>
                            {
                                isMobile ?
                                    null
                                    :
                                    <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Tentang Angelo / FAQ</h4>
                            }
                        </Stack>
                        {
                            isMobile ?
                                null
                                :
                                <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'} width={'100%'}>
                                <Stack direction={isMobile ? 'column' : 'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'}>
                                    <Button
                                        variant={'outlined'}
                                        sx={{ borderColor: Colors.secondary }}
                                        color={'secondary'}
                                        onClick={() => navigate(-1)}
                                        startIcon={<Icon fontSize={'small'} sx={{ color: Colors.secondaryDark }}>arrow_back</Icon>}
                                    >
                                        <span style={{ color: Colors.secondaryDark }}>Kembali</span>
                                    </Button>
                                    <Typography variant="body1" fontWeight={'600'} color={Colors.secondaryDark}>{type === 'add' ? 'FORM TAMBAH FAQ' : 'FORM UBAH FAQ'}</Typography>
                                </Stack>
                                <Typography variant="body1" fontWeight={'600'} color={'#000'}>Pengaturan FAQ</Typography>
                                <Stack direction={'column'} gap={2} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Pertanyaan:</Typography>
                                    <TextField
                                        type="text"
                                        placeholder="Apa itu angeloskin?"
                                        size="small"
                                        sx={{ bgcolor: "white", width: '100%' }}
                                        InputProps={{ sx: { borderRadius: 1 } }}
                                        value={Question}
                                        onChange={(text) => setQuestion(text.target.value)}
                                    />
                                </Stack>
                                <Stack direction={'column'} gap={2} width={'100%'}>
                                    <Typography variant="body1" color={Colors.secondaryDark}>Jawaban:</Typography>
                                    <ReactQuill
                                        value={Answer}
                                        theme={"snow"}
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, false] }],
                                                ["bold", "italic", "underline", "strike", "blockquote"],
                                                [
                                                    { list: "ordered" },
                                                    { list: "bullet" },
                                                    { indent: "-1" },
                                                    { indent: "+1" },
                                                ],
                                                ["link", "clean", "video"],
                                            ],
                                        }}
                                        formats={[
                                            "header",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                            "list",
                                            "bullet",
                                            "indent",
                                            "link",
                                            "video"
                                        ]}
                                        onChange={(value) => setAnswer(value)}
                                        style={{ height: isMobile ? undefined : "18em" }}
                                    />
                                </Stack>
                                <br />
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Button
                                        onClick={ActionFAQ}
                                        color={"secondary"}
                                        variant={'contained'}
                                        disabled={isLoading}
                                        sx={{ backgroundColor: Colors.primary, width: isMobile ? '70%' : '30%' }}
                                        startIcon={isLoading ? <CircularProgress color="secondary" size={20} /> : <Icon fontSize={'small'}>save</Icon>}
                                    >Simpan</Button>
                                </div>
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default AboutFAQFormScreen;