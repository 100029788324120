import {
  Button,
  Icon,
  Stack,
  Toolbar,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import React from "react";
import { CENTER, Colors } from "../../../utils/colors";
import { isMobile } from "react-device-detect";
import { useDropzone } from "react-dropzone";
import { HTTPUploadFile } from "../../../apis/utils";
import { toast } from "react-toastify";
import { HTTPGetAllCategories } from "../../../apis/categories";
import moment from "moment";
import ReactPlayer from "react-player";

export const ProductInfo = (param: {
  initialData: any;
  finalData: any;
  type: string;
}) => {
  const [init, setInit] = React.useState(false);
  const [isImageLoading, setImageLoading] = React.useState(false);
  const [isVideoLoading, setVideoLoading] = React.useState(false);
  const [ImageList, setImageList] = React.useState<any[]>([]);
  const [Video, setVideo] = React.useState("");
  const [Name, setName] = React.useState("");
  const [Category, setCategory] = React.useState("");
  const [CategoryList, setCategoryList] = React.useState([]);
  const [Description, setDescription] = React.useState("");
  const [LatestUpdate, setLatestUpdate] = React.useState("");

  const InputData = (e: any, type: string) => {
    const text = e.target.value;
    if (type === "name") {
      setName(text);
    } else if (type === "category") {
      setCategory(text);
    } else if (type === "description") {
      setDescription(text);
    } else {
      setLatestUpdate(`InputData ${type} ${moment().format("x")}`);
    }
    setLatestUpdate(`InputData ${type} ${moment().format("x")}`);
  };

  const DeleteImage = (value: any) => {
    const result = ImageList.filter((item) => item !== value);
    setImageList(result);
    setLatestUpdate("DeleteImage");
  };

  const GetCategories = async () => {
    try {
      const result = await HTTPGetAllCategories();
      setCategoryList(result.data.data);
    } catch (error) {
      toast.error("Tidak mendapatkan data Kategori!");
    }
  };

  const onImageUpdate = async (file: any) => {
    setImageLoading(true);
    try {
      const Forms: any = new FormData();
      Forms.append("file", file);
      Forms.append("type", "product_pic");
      const result = await HTTPUploadFile({ form: Forms });
      setImageList([...ImageList, result.data.data]);
      setImageLoading(false);
      setLatestUpdate("AddImage");
    } catch (error) {
      setImageLoading(false);
      toast.error("Terjadi kesalahan saat mengunggah foto!");
    }
  };

  const SetImage = async (file: any) => {
    if (file[0].size > 3145728) {
      toast.error("Ukuran file harus dibawah 3MB");
    } else {
      await onImageUpdate(file[0]);
    }
  };

  const { getRootProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: SetImage,
    multiple: false,
  });

  const InitialData = () => {
    const data = param.initialData;
    setImageList(data.images);
    setVideo(data.videos);
    setName(data.name);
    setCategory(data.category);
    setDescription(data.description);
    setLatestUpdate(`Intial Data ${moment().format("x")}`);
  };

  React.useEffect(() => {
    async function Initial() {
      await GetCategories();
      if (param.type === "edit") {
        InitialData();
      }
    }

    Initial().then().catch();
  }, [init]);

  const renderCategory = (values: any) => {
    if (values.length === 0) {
      return <span style={{ color: "#a7a5a6" }}>Pilih Kategori</span>;
    } else {
      const result: any = CategoryList.filter(
        (value: any) => value.id === Category
      );
      return <span style={{ color: "#000" }}>{result[0].name}</span>;
    }
  };

  const AddVideo = async (file: any) => {
    setVideoLoading(true);
    if (file.length !== 0) {
      try {
        const Forms: any = new FormData();
        Forms.append("file", file);
        Forms.append("type", "product_vid");
        const result = await HTTPUploadFile({ form: Forms });
        setVideo(result.data.data);
        setVideoLoading(false);
      } catch (error) {
        setVideoLoading(false);
        toast.error(
          "Terjadi kesalahan saat mengunggah foto! Format yang di terima adalah JPEG / PNG"
        );
      }
    }
    setLatestUpdate(`AddVideo ${moment().format("x")}`);
  };

  const InputUpdates = () => {
    let newImages = ImageList.map((item, index) => {
      return {
        path: item,
        type: "pic",
        is_main: index === 0,
      };
    });
    if (Video.length !== 0) {
      newImages.push({ path: Video, type: "vid", is_main: false });
    }
    const data = {
      name_id: Name,
      name_en: Name,
      product_category_id: Category,
      description_id: Description,
      description_en: Description,
      short_desc_id: Description.substring(0, 100),
      short_desc_en: Description.substring(0, 100),
      images: [...newImages],
    };
    param.finalData(data);
  };

  React.useEffect(() => {
    setTimeout(() => {
      InputUpdates();
    }, 1000);
  }, [LatestUpdate]);

  return (
    <Stack
      direction={"column"}
      gap={3}
      alignItems={"flex-start"}
      width={"100%"}
    >
      <Typography variant="body1" fontWeight={"600"} color={"#000"}>
        Informasi Produk:
      </Typography>
      <Stack direction={"row"} gap={2} width={"100%"}>
        <Typography width={"20%"} variant="body1" color={Colors.secondaryDark}>
          *Foto Produk:
        </Typography>
        <Stack direction={"column"} gap={2} width={"80%"}>
          <Typography width={"20%"} variant="body2" color={"#000"}>
            *Foto 1:1
          </Typography>
          <Stack
            direction={"row"}
            gap={2}
            width={"100%"}
            flexWrap={"wrap"}
            alignItems={"center"}
          >
            {ImageList.map((item, index) => (
              <div style={{ position: "relative", width: "15%" }} key={index}>
                <img
                  src={item}
                  style={{
                    aspectRatio: 1,
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: 10,
                    border: `1px solid ${Colors.primary}`,
                    position: "relative",
                  }}
                  alt={`image ${index + 1}.png`}
                />
                <IconButton
                  onClick={() => DeleteImage(item)}
                  sx={{
                    backgroundColor: "#00000050",
                    position: "absolute",
                    top: 3,
                    right: 3,
                  }}
                >
                  <Icon sx={{ color: "#fff" }} fontSize={"small"}>
                    close
                  </Icon>
                </IconButton>
              </div>
            ))}
            {ImageList.length >= 9 ? null : (
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{
                  ...CENTER,
                  border: `1px solid ${Colors.primary}`,
                  width: "15%",
                  borderRadius: 10,
                  cursor: "pointer",
                  position: "relative",
                  aspectRatio: 1,
                }}
              >
                <Stack alignItems={"center"} gap={1}>
                  <Icon fontSize={"large"} sx={{ color: Colors.primary }}>
                    add_photo_alternate
                  </Icon>
                  <Typography
                    variant="body2"
                    color={Colors.primary}
                    whiteSpace={"pre-line"}
                    textAlign={"center"}
                  >{`Tambahkan\nFoto (${ImageList.length}/9)`}</Typography>
                </Stack>
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={2} width={"100%"}>
        <Typography width={"20%"} variant="body1" color={Colors.secondaryDark}>
          Video Produk:
        </Typography>
        {Video.length === 0 ? (
          <Stack direction={"row"} gap={2} width={"80%"} alignItems={"center"}>
            <Box
              component={"label"}
              style={{
                ...CENTER,
                border: `1px solid ${Colors.primary}`,
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                position: "relative",
                aspectRatio: 1,
              }}
            >
              <Stack alignItems={"center"} gap={1}>
                {isVideoLoading ? (
                  <CircularProgress color="secondary" size={40} />
                ) : (
                  <Icon fontSize={"large"} sx={{ color: Colors.primary }}>
                    video_call
                  </Icon>
                )}
                <Typography
                  variant="body2"
                  color={Colors.primary}
                  whiteSpace={"pre-line"}
                  textAlign={"center"}
                >{`Tambahkan Video`}</Typography>
              </Stack>
              <input
                type="file"
                hidden
                accept="video/*"
                onChange={({ target }: any) => AddVideo(target.files[0])}
              />
            </Box>
            <ul>
              <li>
                <Typography variant="body2" color={Colors.secondaryDark}>
                  Ukuran : Maks. 30MB dan resolusi maks. 1280 X 1280 px
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color={Colors.secondaryDark}>
                  Durasi : 10 - 60 detik
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color={Colors.secondaryDark}>
                  Format : MP4
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color={Colors.secondaryDark}>
                  Catatan : Kamu dapat menampilkan produk saat video sedang
                  diproses. Video akan muncul setelah berhasil diproses.
                </Typography>
              </li>
            </ul>
          </Stack>
        ) : (
          <div style={{ width: "80%" }}>
            <video
              src={Video}
              controls
              style={{ width: "40%", aspectRatio: 4 / 3, objectFit: "contain" }}
            ></video>
          </div>
        )}
      </Stack>
      <Stack direction={"row"} gap={2} width={"100%"} alignItems={"center"}>
        <Typography width={"20%"} variant="body1" color={Colors.secondaryDark}>
          *Nama Produk:
        </Typography>
        <TextField
          type="text"
          placeholder="Nama Produk"
          size="small"
          sx={{ bgcolor: "white", width: "80%" }}
          value={Name}
          onChange={(text) => InputData(text, "name")}
          inputProps={{ maxLength: 255 }}
          InputProps={{
            sx: { borderRadius: 1 },
            endAdornment: (
              <Typography variant={"body2"} color={Colors.secondary}>
                {Name.length}/255
              </Typography>
            ),
          }}
        />
      </Stack>
      <Stack direction={"row"} gap={2} width={"100%"} alignItems={"center"}>
        <Typography width={"20%"} variant="body1" color={Colors.secondaryDark}>
          *Kategori:
        </Typography>
        <Select
          displayEmpty
          size="small"
          MenuProps={{
            style: { maxHeight: 500 },
          }}
          sx={{ bgcolor: "white", width: "80%" }}
          inputProps={{ sx: { borderRadius: 1 } }}
          value={Category}
          onChange={(e) => InputData(e, "category")}
          renderValue={renderCategory}
        >
          {CategoryList.map((item: any, index: number) => (
            <MenuItem value={item.id} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack direction={"row"} gap={2} width={"100%"}>
        <Typography width={"20%"} variant="body1" color={Colors.secondaryDark}>
          Deskripsi Produk:
        </Typography>
        <TextField
          type="text"
          placeholder="Deskripsi"
          size="small"
          sx={{ bgcolor: "white", width: "80%" }}
          InputProps={{ sx: { borderRadius: 1 } }}
          rows={5}
          multiline
          value={Description}
          onChange={(text) => InputData(text, "description")}
        />
      </Stack>
    </Stack>
  );
};
