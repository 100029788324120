import { Stack, Toolbar, Typography, IconButton, Icon, Button, Tab, Tabs } from '@mui/material';
import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import SideBar from '../../components/navigation/sideBar';
import { CENTER, Colors } from '../../utils/colors';
import { isMobile } from 'react-device-detect';
import AddressTable from '../../components/footer/addressTable';
import OperationalTime from '../../components/footer/operationalTime';
import ResellerContact from '../../components/footer/resellerContact';
import { AddressForm } from '../../components/footer/addressModal';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { HTTPGetAddresses } from '../../apis/address';

const FooterScreen = () => {
    const token = secureLocalStorage.getItem('token') as string
    const [init, setInit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [menuActive, setMenuActive] = React.useState(0)
    const [AddressModal, setAddressModal] = React.useState(false)
    const [AddressData, setAddressData] = React.useState([])

    const GetAddresses = async () => {
        try {
            const result = await HTTPGetAddresses({ token })
            setAddressData(result.data.data)
        } catch (error) {
             
            setLoading(false)
            toast.error('Terjadi Kesalahan!')
        }
    }

    const LoadAddress = async () => {
        setLoading(true)
        await GetAddresses()
        setLoading(false)
    }

    React.useEffect(() => {
        async function Initial() {
            if (menuActive === 0) {
                await LoadAddress()
            }
        }

        Initial().then().catch()
    }, [init])

    return (
        <div style={{ width: '100%', backgroundColor: '#f2f5f7' }}>
            <NavigationBar />
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={"row"} justifyContent={"space-between"} style={{ width: '95%', position: 'absolute', top: isMobile ? '70px' : '120px' }}>
                    {isMobile ? null :
                        <div style={{ width: '20%' }}>
                            <SideBar nodeId="60" />
                        </div>
                    }
                    <Stack direction={'column'} sx={{ width: isMobile ? '100%' : '78%', paddingTop: 2 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={"flex-end"} marginBottom={isMobile ? 3 : 0} paddingLeft={2}>
                            <h2 style={{ margin: 0, color: '#fff', fontWeight: '500' }}>Pengaturan Footer</h2>
                            {isMobile ? null :
                                <h4 style={{ margin: 0, color: '#ffffff80', fontWeight: '400' }}>Footer</h4>
                            }
                        </Stack>
                        {isMobile ? null :
                            <Toolbar></Toolbar>
                        }
                        <div style={{ width: '100%', borderRadius: 10, padding: 20, backgroundColor: '#fff' }}>
                            <Stack direction={'column'} gap={4} alignItems={'flex-start'}>
                                {
                                    menuActive === 0 ?
                                        <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} gap={isMobile ? 1 : 2} justifyContent={isMobile ? 'flex-start' : 'space-between'} width={"100%"}>
                                            <Stack direction={"row"} gap={2} alignItems="center">
                                                <Button
                                                    onClick={() => setAddressModal(true)}
                                                    variant={'contained'}
                                                    color={"secondary"}
                                                    sx={{ backgroundColor: Colors.primary, padding: '10px 20px', }}
                                                    startIcon={<Icon fontSize={'small'} sx={{ color: '#fff' }}>add</Icon>}
                                                >{isMobile ? 'Tambah' : 'Tambah Alamat'}</Button>
                                            </Stack>
                                            <Stack direction={"row"} gap={isMobile ? 1 : 2} alignItems="center">
                                                <Stack direction={"row"} gap={1} alignItems="center">
                                                    <Typography variant={isMobile ? 'body1' : 'h6'}>Total</Typography>
                                                    <Typography variant={isMobile ? 'body1' : 'h6'} color={Colors.primary}>20</Typography>
                                                </Stack>
                                                <IconButton>
                                                    <Icon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: Colors.secondaryDark }}>delete</Icon>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        :
                                        null
                                }
                                <Tabs
                                    textColor={'secondary'}
                                    indicatorColor={'secondary'}
                                    value={menuActive}
                                    onChange={(e, value) => setMenuActive(value)}
                                >
                                    <Tab label="Alamat" />
                                    <Tab label="Jam Operasional" />
                                    <Tab label="Kontak Reseller" />
                                </Tabs>
                                {
                                    menuActive === 0 ?
                                        <AddressTable
                                            data={AddressData}
                                            getData={GetAddresses}
                                            loadData={LoadAddress}
                                            isLoading={isLoading}
                                        />
                                        :
                                        menuActive === 1 ?
                                            <OperationalTime />
                                            :
                                            <ResellerContact />
                                }
                            </Stack>
                        </div>
                        <Toolbar />
                    </Stack>
                </Stack>
            </div>
            <AddressForm
                isOpen={AddressModal}
                onClose={() => setAddressModal(false)}
                type={'add'}
                getData={LoadAddress}
                item={{}}
            />
        </div>
    )
}

export default FooterScreen;