import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const ArticleTempSlice = createSlice({
  name: "Article_Temp_Data",
  initialState: {
    data: {} as any,
  },
  reducers: {
    setArticleTempData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setArticleTempData } = ArticleTempSlice.actions;
export const ArticleTempData = ArticleTempSlice.reducer;
